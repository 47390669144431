import React from 'react'

import { formatPrice, useTranslation } from '../../../utils'

export default function BasePrice({ price, product }) {
  const { t } = useTranslation()
  const basePrice = (price / product.baseprice_coefficient).toFixed(2)
  const suffix = product.baseprice_suffix
  return <>{t('BASE_PRICE_FORMAT')(formatPrice(basePrice), suffix)}</>
}
