import React from 'react'
import PropTypes from 'prop-types'

const TrustedShopsWidget = ({ widgetId, isFullWidth }) => (
  <div className={!isFullWidth && 'container'}>
    <etrusted-widget data-etrusted-widget-id={widgetId}></etrusted-widget>
  </div>
)

TrustedShopsWidget.propTypes = {
  widgetId: PropTypes.string.isRequired,
  isFullWidth: PropTypes.string,
}

export default TrustedShopsWidget
