import PropTypes from 'prop-types'
import React from 'react'

import Item from './Item'

export default function ImageTextColumns({
  layout,
  titleColor,
  titleFont,
  titleLevel,
  bgColor,
  align,
  rows,
}) {
  return (
    <section
      className={`image-text-columns image-text-columns--${layout} container mb-4`}
    >
      {rows.slice(0, 4).map((row, rowIndex) => (
        <Item
          row={row}
          key={rowIndex}
          titleFont={titleFont}
          titleColor={titleColor}
          bgColor={bgColor}
          align={align}
          titleLevel={titleLevel}
        />
      ))}
    </section>
  )
}

ImageTextColumns.propTypes = {
  layout: PropTypes.string,
  titleColor: PropTypes.string,
  titleFont: PropTypes.string,
  titleLevel: PropTypes.string,
  bgColor: PropTypes.string,
  align: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.object),
}

ImageTextColumns.defaultProps = {
  layout: 'left-right',
  titleColor: 'primary',
  titleFont: 'font-family-OpenSans',
  titleLevel: 'h2',
  bgColor: 'bg-light',
  align: 'center',
  rows: [],
}

export { default as imageTextColumnsVariants } from './variants'
