import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'

import { useConfiguration, useLazyLoading } from '../../../utils'

function Image({
  srcDesktop = '',
  srcMobile = '',
  alt = 'image',
  title = null,
  className = null,
  srcFull = '',
  disableLazyLoading = false,
  ...rest
}) {
  const [hasError, setHasError] = useState(false)
  const pictureRef = useRef(null)
  const { getImageLink } = useConfiguration()

  let srcDesktopFull = srcDesktop ? getImageLink({ source: srcDesktop }) : ''
  let srcMobileFull = srcMobile ? getImageLink({ source: srcMobile }) : ''
  let fallbackSrc = '/assets/images/placeholder.png' // By default placeholder image URL will be in fallback

  if (!hasError) {
    fallbackSrc = srcFull

    if (srcDesktopFull && !srcMobileFull && !srcFull) {
      fallbackSrc = srcDesktopFull // if only desktop image has been uploaded from Makaira dashboard
    } else if (srcMobileFull && !srcDesktopFull && !srcFull) {
      fallbackSrc = srcMobileFull // if only mobile image has been uploaded from Makaira dashboard
    } else if (srcMobileFull && srcDesktopFull) {
      fallbackSrc = srcDesktopFull // if mobile and desktop images have been uploaded from Makaira dashboard so select desktop as fallback
    }
  }

  useLazyLoading({
    ref: disableLazyLoading ? {} : pictureRef,
    dependency: [srcDesktop, srcMobile],
  })

  const getAttr = (attr, value) => {
    if (disableLazyLoading) {
      return {
        [attr]: value,
      }
    } else {
      return {
        [`data-${attr}`]: value,
      }
    }
  }

  const handleError = () => {
    setHasError(true)
  }

  // If there is no any url for image then don't render this component
  if (!srcFull && !srcDesktop && !srcMobile) {
    return null
  }

  return (
    <picture
      ref={disableLazyLoading ? null : pictureRef}
      className={className}
      title={title}
    >
      {srcDesktop && srcMobile && (
        <>
          <source
            media="(min-width: 768px)"
            {...getAttr('srcset', srcDesktopFull)}
          />
          <source
            media="(max-width: 767px)"
            {...getAttr('srcset', srcMobileFull)}
          />
        </>
      )}

      <img
        {...getAttr('src', fallbackSrc)}
        alt={alt}
        onError={handleError}
        {...rest}
      />
    </picture>
  )
}

Image.propTypes = {
  srcDesktop: PropTypes.string,
  srcMobile: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  srcFull: PropTypes.string,
  disableLazyLoading: PropTypes.bool,
}

export default Image
export { default as imageVariants } from './variants.js'
