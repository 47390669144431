import React, { Fragment, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useTranslation } from '../../../../utils'

import { SearchBar, SearchWall } from '../Search'
import { useIcons } from '../IconProvider'
import HeaderAction from './HeaderAction'

function SearchButton({
  showLabel,
  searchBar,
  showAsPopup = false,
  layout = 'bar',
}) {
  const { t } = useTranslation()
  const getIcon = useIcons()
  const router = useRouter()
  const [isSearchActive, setSearchActive] = useState(false)

  const onClick = () => {
    setSearchActive(!isSearchActive)
  }

  const portalNode =
    showAsPopup && typeof document !== 'undefined'
      ? document.querySelector('#modal-root')
      : null

  const containerClass = classNames({
    'header__searchbar-button': !showAsPopup,
    'header__searchbar-button--active': isSearchActive,
  })

  const searchbarClass = classNames({
    'portal__searchbar-form': showAsPopup && layout === 'bar',
    'portal__searchbar-form--active':
      showAsPopup && isSearchActive && layout === 'bar',
    'header__searchbar-form': !showAsPopup && layout === 'bar',
    'header__searchwall-form': layout === 'wall',
  })

  let Container = Fragment
  const containerProps = {}
  if (!showAsPopup) {
    Container = 'div'
    containerProps.className = containerClass
  }

  let SearchElement
  const searchProps = {
    className: searchbarClass,
    portalNode,
    escapeAction: onClick,
    afterSubmit: onClick,
    showBackButton: showAsPopup,
    isSearchActive,
    ...searchBar,
  }

  useEffect(() => {
    const hideSearch = () => setSearchActive(false)

    router.events.on('routeChangeComplete', hideSearch)

    return () => {
      router.events.off('routeChangeComplete', hideSearch)
    }
  }, [])

  if (layout === 'bar') {
    SearchElement = SearchBar
    if (showAsPopup) {
      searchProps.showOverlay = true
    }
  } else if (layout === 'wall') {
    SearchElement = SearchWall
  } else {
    console.error(`Invalid searchbar layout "${layout}"`)
    return null
  }

  return (
    <Container {...containerProps}>
      <HeaderAction
        symbol={getIcon('actionSearch')}
        label={t('HEADER_SEARCH')}
        showLabel={showLabel}
        onClick={onClick}
      />
      <SearchElement {...searchProps} />
    </Container>
  )
}

SearchButton.propTypes = {
  showLabel: PropTypes.bool,
  searchBar: PropTypes.object,
  showAsPopup: PropTypes.bool,
  layout: PropTypes.string,
}

SearchButton.defaultProps = {
  showLabel: false,
  searchBar: {},
  showAsPopup: false,
  layout: 'bar',
}

export default SearchButton
