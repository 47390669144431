import React from 'react'

import { useCustomer, useFeatureFlags, useTranslation } from '../../../../utils'

import { useIcons } from '../IconProvider'
import { DropdownMenu, DropdownItem } from './DropdownMenu'
import PortalWrapper from './PortalWrapper'
import { useMenuListener } from './utils'

function AccountDropdown() {
  const [isVisible] = useMenuListener('account')
  const { fullName, isAboGroup } = useCustomer()
  const { isFeatureEnabled } = useFeatureFlags()
  const { t } = useTranslation()
  const getIcon = useIcons()

  return (
    <PortalWrapper query=".header__action-account">
      <DropdownMenu isVisible={isVisible} label={t('HEADER_ACCOUNT_AREA')}>
        <DropdownItem
          separator="bottom"
          startIcon={getIcon('actionAccountLoggedIn')}
          className="font-weight-bold"
          label={fullName}
          href="/customer/account"
        />
        <DropdownItem
          endIcon="cf-next"
          label={t('ACCOUNT_MENU_INFORMATION')}
          href="/customer/account/edit"
        />
        <DropdownItem
          endIcon="cf-next"
          label={t('ACCOUNT_MENU_ORDERS')}
          href="/sales/order/history"
        />
        {isFeatureEnabled('abo') && isAboGroup && (
          <DropdownItem
            endIcon="cf-next"
            label={t('ACCOUNT_MENU_MY_INTERVALS')}
            href="/cartUpdate/abodelivery/view"
          />
        )}
        <DropdownItem
          endIcon="cf-next"
          label={t('ACCOUNT_MENU_CONTACT')}
          href="/customer/account/contact"
        />
        <DropdownItem
          separator="top"
          startIcon={getIcon('accountMenuCart')}
          label={t('ACCOUNT_MENU_TO_CART')}
          href="/checkout/cart"
        />
        <DropdownItem
          startIcon={getIcon('accountMenuLogout')}
          label={t('ACCOUNT_MENU_LOGOUT')}
          href="/customer/account/logout"
        />
      </DropdownMenu>
    </PortalWrapper>
  )
}

export default AccountDropdown
