import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SubmenuColumn from './SubmenuColumn'

function Submenu({ items, isExpanded, onItemClick, onItemBlur }) {
  const submenuClasses = classNames('header__navbar_submenu', {
    'header__navbar_submenu--active': isExpanded,
  })
  return (
    <ul className={submenuClasses}>
      {items.map((item, index) => (
        <SubmenuColumn
          key={index}
          onItemClick={onItemClick}
          onItemBlur={onItemBlur}
          {...item}
        />
      ))}
    </ul>
  )
}

Submenu.propTypes = {
  items: PropTypes.array,
  isExpanded: PropTypes.bool,
  onItemClick: PropTypes.func,
  onItemBlur: PropTypes.func,
}

Submenu.defaultProps = {
  items: [],
  isExpanded: false,
  onItemClick: null,
  onItemBlur: null,
}

export default Submenu
