import React from 'react'

import { Image, Text, ProductRating } from '../..'
import ProductPrices from '../../Alphapet/PriceInfo/ProductPrices'
import ProductPriceHint from '../../Alphapet/PriceInfo/ProductPriceHint'
import { ListPagePricingProvider } from '../../../utils'
import ProductActions from './ProductActions'

export default function ProductTile(props) {
  const {
    productData,
    reviewUrl = '',
    ratingSummary = null,
    trackingClass = '',
    priceFlag = true,
    redirectToReviewUrl = false,
    trackingIdentifier,
  } = props

  const {
    manufacturer_name = '',
    produkt_linie = '',
    name = '',
    config_variable_a = '',
    small_image = '',
    url = '',
    sku = '',
  } = productData

  //productDataForTracking is used in GTM to push the product data from clicked recommendation product to Google Analytics
  const productDataForTracking =
    manufacturer_name +
    ' - ' +
    produkt_linie +
    ' - ' +
    name +
    ' - ' +
    config_variable_a
  return (
    <article className="product-placement-item p-3 border mx-sm-2 text-center">
      <Image
        className="product-placement-item__image"
        srcFull={small_image}
        alt="image"
      />

      <Text
        weight="semi-bold"
        className="d-block w-100 text-truncate product-name"
        element="span"
        text={manufacturer_name + ' ' + produkt_linie}
      />

      <Text
        className={'d-block w-100 text-truncate' + (!priceFlag ? ' mb-3' : '')}
        element="span"
        text={name}
      />

      {priceFlag && (
        <ListPagePricingProvider productData={productData}>
          <ProductPrices
            productData={productData}
            classSpecialPrice="font-size-m font-weight-semi-bold"
            classRegularPrice="d-inline-block ml-2"
            classUnitPrice="font-size-xxs d-block"
          />
        </ListPagePricingProvider>
      )}

      {priceFlag && <ProductPriceHint className="mb-3 font-size-xxs" />}

      <ProductActions
        url={url}
        productDataForTracking={productDataForTracking}
        reviewUrl={reviewUrl}
        redirectToReviewUrl={redirectToReviewUrl}
        actionClass={'btn btn-primary w-100 ' + trackingClass}
        sku={sku}
        trackingIdentifier={trackingIdentifier}
      />

      {ratingSummary && ratingSummary.count > 0 ? (
        <ProductRating
          productData={productData}
          ratingClass="mt-2"
          ratingSummary={ratingSummary}
        />
      ) : (
        <div className="product-ratings mt-2" />
      )}
    </article>
  )
}
