/**
 * Get the numeric value of a price string.
 * Limitations: Does not support thousands-separators.
 *
 * @param {any} priceStr - The string to format
 * @returns {number} - The number portion when given a string, the number itself when given a number, or zero for other types.
 */
export default function getNumericPrice(priceStr) {
  if (typeof priceStr === 'number') {
    return priceStr
  } else if (priceStr && typeof priceStr === 'string') {
    return parseFloat(
      priceStr
        // Replace decimal separator
        .replace(',', '.')
        // Remove everything except numbers, points and minuses
        .replace(/[^\d.-]/g, '')
    )
  }
  return 0
}
