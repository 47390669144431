import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import InfoCard from './InfoCard'

function ThreeColumnInfoCards({
  infoCards: items = [],
  isFullWidthEnabled = false,
  lastItemIsFullWidth = false, // By default the first item will be full-width
  fullWidthClassName = null,
  isFullWidth = false,
}) {
  const containerClasses = classNames('mb-4', {
    container: !isFullWidth,
  })
  const rowClasses = classNames('row', {
    'mx-0': isFullWidth,
  })

  return (
    <section className={containerClasses}>
      <div className={rowClasses}>
        {items.map((item, index) => {
          const isFullWidthItem =
            (index === 0 && !lastItemIsFullWidth) ||
            (index === items.length - 1 && lastItemIsFullWidth)
          const cssClassName = classNames(
            'col col-12 col-lg-4 pb-4 text-center',
            fullWidthClassName,
            {
              'pb-lg-0': isFullWidthItem,
              'col-md-6 pb-md-0': !isFullWidthItem,
            }
          )
          return (
            <div className={cssClassName} key={index}>
              <InfoCard
                isFullWidth={isFullWidthItem && isFullWidthEnabled}
                {...item}
              />
            </div>
          )
        })}
      </div>
    </section>
  )
}

ThreeColumnInfoCards.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  isFullWidthEnabled: PropTypes.bool,
  lastItemIsFullWidth: PropTypes.bool,
  fullWidthClassName: PropTypes.string,
  isFullWidth: PropTypes.bool,
}

export default ThreeColumnInfoCards
export { default as threeColumnInfoCardsVariants } from './variants.js'
