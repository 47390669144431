import React from 'react'
import PropTypes from 'prop-types'
import allLanguages from '../../../config/allLanguages'
import { useTranslation } from '../../../utils'

export default function LanguageSelector({ className }) {
  const { language, setLanguage } = useTranslation()
  const handleChange = ({ target }) => {
    const lang =
      allLanguages.find((l) => l.value === target.value) || allLanguages[0]
    setLanguage(lang.value)
  }
  return (
    <select
      id="storefront_language"
      onChange={handleChange}
      defaultValue={language}
      className={className}
    >
      {allLanguages.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  )
}

LanguageSelector.propTypes = {
  className: PropTypes.string,
}

LanguageSelector.defaultProps = {
  className: '',
}
