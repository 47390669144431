import React from 'react'
import { useCarouselSettings } from '../../../utils'
import Carousel from '../Carousel'

const responsive = {
  desktop: {
    breakpoint: { max: Number.MAX_VALUE, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1023.98, min: 768 },
    items: 2,
  },
  mobileS: {
    breakpoint: { max: 767.98, min: 160 },
    items: 1,
  },
}

export default function ConditionalCarousel({ cardsLength, children }) {
  const carouselSettings = useCarouselSettings('universal-cards')

  if (cardsLength > 3) {
    return (
      <Carousel responsive={responsive} className="p-2" {...carouselSettings}>
        {children}
      </Carousel>
    )
  } else {
    return <div className="row p-2">{children}</div>
  }
}
