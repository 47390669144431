import React from 'react'
import dynamic from 'next/dynamic'

// Import common components normally
import {
  ProductPlacement,
  ProductPlacementExtended,
  TeaserHero,
  ImageTextOverlay,
  RichContent,
  TextEditor,
  CustomText,
  TwoColumnsImageText,
  ColumnBasedCards,
  CardCarousel,
  BrandCarousel,
  SubCategory,
  Headline,
  Separator,
  LineSpacer,
  CTAButtons,
  ThreeColumnInfoCards,
  ExpandableBox,
  ProductListBanner,
  TwoColumnCardWithBG,
  ErrorBoundary,
  Newsletter,
  DataLayer,
  NoComponent,
  TextWithBg,
  ImageGrid,
  UniversalCards,
  CopyCouponCards,
  ImageTextColumns,
  ProductOffers,
  VideoComponent,
  TrustedShopsWidget,
  // Rarely used, but small
  ChatchampWizard,
  Storefinder, // Small wrapper component that loads a larger bundle on demand
} from '../..'

// Import larger and rarely used components on demand
const Leadform = dynamic(() => import('../Leadform'))
const SocialFeeds = dynamic(() => import('../SocialFeeds'))
const PrivacyPolicyButton = dynamic(() => import('../PrivacyPolicyButton'))
const Faqs = dynamic(() => import('../Faqs'))
const WelcomeBlock = dynamic(() => import('../WelcomeBlock'))
const FilterWizard = dynamic(() => import('../FilterWizard'))
const ChatchampButton = dynamic(() => import('../ChatchampButton'))
const LandbotWidget = dynamic(() => import('../LandbotWidget'))
const ProductRatingsPage = dynamic(() => import('../ProductRatingsPage'))
const KnoCommerceSurvey = dynamic(() => import('../KnoCommerceSurvey'))

export default function ContentElements({ elements = [] }) {
  if (elements.length === 0) return null

  // Declare your additional content patterns here
  const components = {
    'product-placement': ProductPlacement,
    'product-placement-extended': ProductPlacementExtended,
    'teaser-hero': TeaserHero,
    'banner-with-text': ImageTextOverlay,
    'rich-content': RichContent,
    'text-editor': TextEditor,
    'custom-text': CustomText,
    '2columns-image-text': TwoColumnsImageText,
    'column-based-cards': ColumnBasedCards,
    'card-carousel': CardCarousel,
    'brand-carousel': BrandCarousel,
    'sub-category': SubCategory,
    headline: Headline,
    separator: Separator,
    'line-spacer': LineSpacer,
    newsletter: Newsletter,
    'video-component': VideoComponent,
    'cta-buttons': CTAButtons,
    '3columns-info-cards': ThreeColumnInfoCards,
    'expandable-box': ExpandableBox,
    'product-list-banner': ProductListBanner,
    'product-ratings-page': ProductRatingsPage,
    '2column-cards-with-bg': TwoColumnCardWithBG,
    'gtm-data-layer': DataLayer,
    faqs: Faqs,
    'landbot-widget': LandbotWidget,
    'welcome-block': WelcomeBlock,
    'filter-wizard': FilterWizard,
    'chatchamp-button': ChatchampButton,
    'chatchamp-wizard': ChatchampWizard,
    storefinder: Storefinder,
    'privacy-policy-button': PrivacyPolicyButton,
    'copy-coupon-cards': CopyCouponCards,
    'text-with-bg': TextWithBg,
    'image-grid': ImageGrid,
    'universal-cards': UniversalCards,
    'image-text-columns': ImageTextColumns,
    'social-feeds': SocialFeeds,
    'product-offers': ProductOffers,
    leadform: Leadform,
    'trusted-shops-widget': TrustedShopsWidget,
    'kno-commerce-survey': KnoCommerceSurvey,
  }

  return (
    <>
      {elements.map((entry) => {
        const Component = components[entry.component]

        if (!Component) {
          return <NoComponent key={entry.id} name={entry.component} />
        }

        return (
          <ErrorBoundary key={entry.id}>
            <Component {...entry.properties.content} />
          </ErrorBoundary>
        )
      })}
    </>
  )
}
