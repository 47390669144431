import * as Sentry from '@sentry/node'

/**
 * Log frontend errors.
 *
 * @param {Object|Error} data
 * @param {string} data.message - A required message
 * @param {string} action - An identifying tag
 * @returns {Promise<void>}
 */
export default async function logError(data, action) {
  if (process.env.NODE_ENV === 'production') {
    try {
      const { message, ...rest } = data
      const dataEntries = Object.entries(rest)

      Sentry.captureMessage(message, {
        level: 'error',
        tags: {
          environment: 'production',
          action,
          ...rest,
        },
      })

      await fetch(
        `${process.env.MAGENTO_DOMAIN}log-endpoint?action=${encodeURIComponent(
          action
        )}&message=${encodeURIComponent(message)}${dataEntries
          .map(([k, v]) => `&${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
          .join('')}`
      )
    } catch (error) {
      console.error(error)
    }
  } else {
    console.error(data)
  }
}
