import { pushDataLayer } from './dataLayer'

/**
 * It could be, that there is no shipping address for a customer yet.
 * But if we have an email, we want fire this event without postcode.
 *
 * @param {string} hashedEmail Sha256 encrypted customer email
 * @param {string} [postcode] shipping address postcode
 */
function trackLogin({ mailSha256, mailSha512, postcode = '' }) {
  if (mailSha256) {
    const tagManagerDataLayerArgs = {
      dataLayer: {
        event: 'login',
        mailSha256,
        mailSha512,
        postcode,
      },
    }
    pushDataLayer(tagManagerDataLayerArgs)
  }
}

export default trackLogin
