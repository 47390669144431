import React, { useContext, useEffect, useState } from 'react'

import { Button } from '../../index'
import { BundleContext } from '../ProductBundleOptions/BundleProvider'
import { useFeatureFlags, useMediaResize, useTranslation } from '../../../utils'
import { AddToCartButton, PreorderButton, VariantButton } from './Buttons'

function ProductActionButton(props) {
  const bundleContext = useContext(BundleContext)
  const { isFeatureEnabled } = useFeatureFlags()
  const { breakpoints, initialDevice } = useMediaResize()
  const { t } = useTranslation()
  const [isMobile, setMobile] = useState(
    ['mobile', 'tablet'].includes(initialDevice)
  )

  const {
    /**** Operational data ****/
    // Required for product-ID, bundle validation, preorder-url
    productData,

    // Required for addToCart function
    qty = 1,

    /**** Appearance (optional) ****/
    // Applies to the button, not its container!
    className = null,

    /**** Behaviour (optional) ****/
    // When set to 'false' (default), shows the QuickviewPopup if "VariantButton" is shown.
    // Set this to 'true' if you always want the "AddToCartButton" to render, no matter what.
    // Warning: clicking on "AddToCartButton" for a non-configured bundle product causes a
    //   validation error!
    disableVariants = false,

    // When set to 'false' (default)', shows the "PreviewButton", if product is unavailable.
    // Set this to 'true' if you just want to show "Out of stock", without any action.
    disablePreorder = false,

    /**** Button text overrides (optional) ****/
    textAddToCart = null,
    textVariant = null,
    textPreorder = null,
    textOutOfStock = null,
  } = props

  useEffect(() => {
    setMobile(breakpoints.downLG)
  }, [breakpoints.downLG])

  const isPreorderEnabled =
    !disablePreorder && isFeatureEnabled('product.preorder')

  // Required for configurable bundle products
  let selectedOptions =
    bundleContext && bundleContext.selectedOptions
      ? bundleContext.selectedOptions
      : null

  let button = (
    <AddToCartButton
      className={className}
      text={textAddToCart}
      productData={productData}
      qty={qty}
      selectedOptions={selectedOptions}
      {...props}
    />
  )

  if (productData.availability === true) {
    if (!disableVariants || (!disableVariants && isMobile)) {
      button = (
        <VariantButton
          className={className}
          text={textVariant}
          productData={productData}
          selectedOptions={selectedOptions}
          variant={isMobile ? 'outline-primary' : 'primary'}
        />
      )
    }
  } else if (isPreorderEnabled) {
    button = (
      <PreorderButton
        className={className}
        productData={productData}
        text={textPreorder}
      />
    )
  } else {
    // fallback, when preorder is disabled
    button = (
      <Button
        className={className}
        disabled
        onClick={(e) => e.preventDefault()}
      >
        {textOutOfStock ? textOutOfStock : t('OUT_OF_STOCK')}
      </Button>
    )
  }

  return <div className="product-item__actions">{button}</div>
}

export default ProductActionButton
export { default as productActionButtonVariants } from './variants'
