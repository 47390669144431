import React from 'react'
import { MediaResize } from '../../../utils/Alphapet/useMediaResize'
import MobileView from './MobileView'
import DesktopView from './DesktopView'

function SubCategory(props) {
  return (
    <>
      <MediaResize down="LG">
        <MobileView {...props} />
      </MediaResize>
      <MediaResize up="LG">
        <DesktopView {...props} />
      </MediaResize>
    </>
  )
}

export default SubCategory
export { default as subCategoryVariants } from './variants.js'
