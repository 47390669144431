import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Item from './Item'
import ConditionalCarousel from './ConditionalCarousel'

export default function UniversalCards({
  hasBorder,
  hasShadow,
  hasRoundedCorners,
  headlineColor,
  headlineLevel,
  openInNewTab,
  imageOrder,
  iconOrder,
  headlineOrder,
  mainTextOrder,
  cards,
}) {
  const columnClasses = classNames('d-flex', {
    'col-12': cards.length === 1,
    'col-12 col-md-6 mb-3 mb-md-0': cards.length === 2,
    'col-12 col-md-4 mb-3 mb-md-0': cards.length === 3,
    'px-3 h-100': cards.length > 3,
  })

  return (
    <section className="universal-cards container mb-4">
      <ConditionalCarousel cardsLength={cards.length}>
        {cards.slice(0, 6).map((card, cardIndex) => (
          <div className={columnClasses} key={cardIndex}>
            <Item
              card={card}
              hasBorder={hasBorder}
              hasShadow={hasShadow}
              hasRoundedCorners={hasRoundedCorners}
              headlineColor={headlineColor}
              headlineLevel={headlineLevel}
              openInNewTab={openInNewTab}
              imageOrder={imageOrder}
              iconOrder={iconOrder}
              headlineOrder={headlineOrder}
              mainTextOrder={mainTextOrder}
              cardIndex={cardIndex}
            />
          </div>
        ))}
      </ConditionalCarousel>
    </section>
  )
}

UniversalCards.propTypes = {
  hasBorder: PropTypes.bool,
  hasShadow: PropTypes.bool,
  hasRoundedCorners: PropTypes.bool,
  headlineColor: PropTypes.string,
  headlineLevel: PropTypes.string,
  openInNewTab: PropTypes.bool,
  imageOrder: PropTypes.string,
  iconOrder: PropTypes.string,
  headlineOrder: PropTypes.string,
  mainTextOrder: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.object),
}

UniversalCards.defaultProps = {
  hasBorder: true,
  hasShadow: true,
  hasRoundedCorners: true,
  headlineColor: 'primary',
  headlineLevel: 'h3',
  openInNewTab: false,
  imageOrder: '',
  iconOrder: '',
  headlineOrder: '',
  mainTextOrder: '',
  cards: [],
}
export { default as universalCardsVariants } from './variants.js'
