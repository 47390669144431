import React from 'react'
import Carousel from '../Carousel'
import { useCarouselSettings } from '../../../utils'
import classNames from 'classnames'

const responsive = {
  desktop: {
    breakpoint: { max: Number.MAX_VALUE, min: 1200 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1199.98, min: 1024 },
    items: 4,
  },
  mobileM: {
    breakpoint: { max: 1023.98, min: 568 },
    items: 2,
  },
  mobileS: {
    breakpoint: { max: 567.98, min: 160 },
    items: 1,
  },
}

export default function ConditionalCarousel({
  columnSize,
  children,
  isFullWidth,
}) {
  const carouselSettings = useCarouselSettings('column-based-cards')
  const containerClasses = classNames('row', {
    'mx-0': isFullWidth,
  })

  if (columnSize === '6') {
    return (
      <Carousel
        responsive={responsive}
        partialVisible
        renderDotsOutside={true}
        isFullWidth={isFullWidth}
        {...carouselSettings}
      >
        {children}
      </Carousel>
    )
  } else {
    return <div className={containerClasses}>{children}</div>
  }
}
