import TagManager from 'react-gtm-module'

function pushDataLayer(dataLayer) {
  if (window.userTrackingEnabled) {
    TagManager.dataLayer(dataLayer)
  }
}

function clearGA4Data() {
  pushDataLayer({
    dataLayer: {
      ga4: {},
      _clear: true,
    },
  })
}

export { pushDataLayer, clearGA4Data }
