import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from '../../../../utils'

import { dispatchShowNavigationMenu } from '../Menu'
import { useIcons } from '../IconProvider'
import HeaderAction from './HeaderAction'

function HamburgerButton({ showLabel, owns }) {
  const { t } = useTranslation()
  const getIcon = useIcons()

  return (
    <HeaderAction
      symbol={getIcon('actionHamburger')}
      label={t('HEADER_MENU_BUTTON')}
      showLabel={showLabel}
      onClick={dispatchShowNavigationMenu}
      owns={owns}
    />
  )
}

HamburgerButton.propsType = {
  showLabel: PropTypes.bool,
  owns: PropTypes.string,
}

HamburgerButton.defaultProps = {
  showLabel: true,
  owns: null,
}

export default HamburgerButton
