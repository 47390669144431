import React from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Icon, Link } from '../../../index'

import SearchBar from './SearchBar'

function SearchWall({
  className,
  links,
  portalNode,
  isSearchActive,
  escapeAction,
  ...searchBar
}) {
  const containerClasses = classNames('header__searchwall', className, {
    'header__searchwall--active': isSearchActive,
  })

  const content = (
    <div className={containerClasses}>
      <SearchBar
        isSearchActive={isSearchActive}
        escapeAction={escapeAction}
        showCloseButton={true}
        showOverlay={true}
        {...searchBar}
      />

      {links?.length > 0 && (
        <ul className="header__searchwall-links">
          {links.map(({ label, url, icon }, index) => (
            <li key={index} className="header__searchwall-links-item">
              <Link href={url} onClick={escapeAction}>
                {icon && <Icon symbol={icon} />}
                {label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  )

  if (portalNode) {
    return createPortal(content, portalNode)
  }
  return content
}

SearchWall.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  portalNode: PropTypes.any,
  isSearchActive: PropTypes.bool,
  escapeAction: PropTypes.func,
  searchBar: PropTypes.object,
}

SearchWall.defaultProps = {
  className: null,
  links: [],
  portalNode: null,
  isSearchActive: false,
  escapeAction: null,
  searchBar: {},
}

export default SearchWall
