import React, { useContext } from 'react'
import classNames from 'classnames'

import {
  PricingContext,
  PriceTypes,
  formatPrice,
  useTranslation,
} from '../../../utils'

import BasePrice from './BasePrice'

export default function ProductPrices({
  classContainer,
  classSpecialPrice,
  classRegularPrice,
  classUnitPrice,
  showBasePrice = true,
  showDisplayPrice = true,
  showAdditionalInfo = true,
  showStrikePrice = true,
  productData,
}) {
  const {
    pricingContext: { priceType, specialPrice: actualPrice, regularPrice },
  } = useContext(PricingContext)
  const { t } = useTranslation()

  const additionalPriceInformation =
    priceType === PriceTypes.PRICE_COUPON ? (
      <p className="mb-0 font-size-xs">{t('PRICE_WITH_DISCOUNT')}</p>
    ) : null

  const specialPriceClass = classNames(classSpecialPrice, {
    ['text-primary']:
      priceType === PriceTypes.PRICE_ABO ||
      priceType === PriceTypes.PRICE_COUPON,
  })

  let strikePrice = null
  if (actualPrice !== 0 && actualPrice < regularPrice) {
    strikePrice = (
      <del className={classRegularPrice}>{formatPrice(regularPrice)}</del>
    )
  }
  const displayPrice = formatPrice(actualPrice)

  return (
    <div className={classContainer}>
      {showAdditionalInfo && additionalPriceInformation}
      {showDisplayPrice && (
        <span className={specialPriceClass}>{displayPrice}</span>
      )}
      {showStrikePrice && strikePrice}
      {showBasePrice && (
        <span className={classUnitPrice}>
          <BasePrice product={productData} price={actualPrice} />
        </span>
      )}
    </div>
  )
}
