import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ConditionalLink, Image } from '../../index'

import Overlay from './Overlay'

function ImageTextOverlay({ isFullWidth, overlay, image }) {
  const imageSourceMobile = image.imageMobile
  const imageSourceDesktop = image.imageDesktop
  const wrapperClass = classNames('image-text-overlay mb-4', {
    container: !isFullWidth,
  })

  return (
    <section className={wrapperClass}>
      <ConditionalLink
        href={image.link}
        className="image-text-overlay__image-wrapper"
      >
        <Image
          srcDesktop={imageSourceDesktop}
          srcMobile={imageSourceMobile}
          alt={image.altText}
          className="image-text-overlay__image"
        />
      </ConditionalLink>
      <Overlay {...overlay} />
    </section>
  )
}

ImageTextOverlay.propTypes = {
  isFullWidth: PropTypes.bool,
  image: PropTypes.object,
  overlay: PropTypes.object,
}

ImageTextOverlay.defaultProps = {
  isFullWidth: false,
  image: {},
  overlay: {},
}

export default ImageTextOverlay
export { default as imageTextOverlayVariants } from './variants.js'
