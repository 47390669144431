/**
 * Get the product price as string which the customer will pay.
 *
 * @param {object} prices - The string to format
 * @returns {string} - product price as string
 */
export default function getProductPrice(prices) {
  const priceData = prices
  const price = priceData.special > 0 ? priceData.special : priceData.regular
  return price
}
