import DOMPurify from 'isomorphic-dompurify'

const setupDefaultConfig = () => {
  DOMPurify.setConfig({
    // Needed to allow style tags as first node, see https://github.com/cure53/DOMPurify/issues/591
    FORCE_BODY: true,
  })
}
// Invoke initially
setupDefaultConfig()

/**
 * Sanitize arbitrary HTML strings.
 *
 * @param {string} html - The potentially dirty input HTML
 * @param {boolean} [forceStrictMode=false] - Enforce sanitazation even when deactivated in the '.env' file
 * @param {Object} [overrideConfig=null] - Provide an alternative sanitazation config
 * @returns {string} - The cleaned up output HTML
 */
export default function sanitizeHtml(
  html,
  forceStrictMode = false,
  overrideConfig = null
) {
  if (process.env.RAWHTML_STRICT_MODE === 'true' || forceStrictMode) {
    if (process.env.NODE_ENV !== 'production') {
      DOMPurify.addHook('afterSanitizeElements', afterSanitizeElements)
    }
    let ret
    if (overrideConfig) {
      // Config needs to be reset before the override argument takes effect
      DOMPurify.clearConfig()
      ret = DOMPurify.sanitize(html, overrideConfig)
      setupDefaultConfig()
    } else {
      ret = DOMPurify.sanitize(html)
    }
    if (process.env.NODE_ENV !== 'production') {
      DOMPurify.removeAllHooks()
    }
    return ret
  } else {
    return html
  }
}

const afterSanitizeElements = function () {
  this.removed.forEach((r) => {
    if (r.attribute) {
      const { name, value } = r.attribute
      console.warn(`Removed unsafe attribute: "${name}"="${value}"`)
    } else if (r.element) {
      const { nodeName } = r.element
      if (nodeName !== 'REMOVE') {
        console.warn(`Removed unsafe element: "${nodeName}"`)
      }
    }
  })
}
