export default function scrollToElement({
  selector = '',
  offset = 0,
  behavior = 'auto',
}) {
  if (typeof window !== 'undefined') {
    const element = document.querySelector(selector)
    if (element) {
      const elementPos = element.getBoundingClientRect().top
      const absoluteElementTop = elementPos + window.pageYOffset
      try {
        window.scrollTo({
          top: absoluteElementTop - offset,
          behavior,
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
