import React, { Component, useContext } from 'react'

import { withBackendApi } from '../BackendApiProvider'

const CustomerContext = React.createContext(null)

function useCustomer() {
  return useContext(CustomerContext)
}

function withCustomerContext(WrappedComponent) {
  return function WithCustomerContext(props) {
    const ctx = useCustomer()
    return <WrappedComponent customerContext={ctx} {...props} />
  }
}

/**
 * Trigger a user data update request.
 */
function dispatchUpdateCustomerInfo() {
  const evt = new CustomEvent('customerinfo:update')
  window.dispatchEvent(evt)
}

class CustomerProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAvailable: false, // Has an initial request been made?
      isLoggedIn: false,
      fullName: null,
      postcode: null,
      mailSha256: null,
      mailSha512: null,
      isAboGroup: false,
    }
  }

  componentDidMount() {
    this.updateCustomerInfo()
    // Start watching for update requests
    window.addEventListener('customerinfo:update', this.updateCustomerInfo)
  }

  componentWillUnmount() {
    // Stop watching for update requests
    window.removeEventListener('customerinfo:update', this.updateCustomerInfo)
  }

  updateCustomerInfo = async () => {
    const { backendApi } = this.props
    const wasLoggedIn = this.state.isLoggedIn

    backendApi
      .getUserData()
      .then((response) => {
        const { fullName, postcode, mailSha256, mailSha512, isAboGroup } =
          response || {}
        const isLoggedIn = !!fullName?.trim()

        this.setState({
          isAvailable: true,
          isLoggedIn,
          fullName,
          postcode,
          mailSha256,
          mailSha512,
          isAboGroup,
        })
        if (isLoggedIn !== wasLoggedIn) {
          window.dispatchEvent(new CustomEvent('customerinfo:login:changed'))
        }
      })
      .catch(console.error)
  }

  render() {
    return (
      <CustomerContext.Provider value={this.state}>
        {this.props.children}
      </CustomerContext.Provider>
    )
  }
}

export default withBackendApi(CustomerProvider)
export {
  CustomerContext,
  dispatchUpdateCustomerInfo,
  useCustomer,
  withCustomerContext,
}
