import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default function Separator({ style, width, color }) {
  const containerClasses = classNames('content-separator', {
    container: width === 'container' || width === 'half-width',
  })
  const hrClasses = classNames('my-3', {
    'content-separator--width-50': width === 'half-width',
  })
  if (style === 'circle-line') {
    return (
      <div className={containerClasses}>
        <div className="content-separator--circle-line my-3 mx-auto">
          <hr
            className="line my-0"
            style={{
              borderTop: `1px solid var(--${color})`,
            }}
          />
          <span
            className="circle"
            style={{
              border: `1px solid var(--${color})`,
            }}
          />
          <br />
        </div>
      </div>
    )
  } else {
    return (
      <div className={containerClasses}>
        <hr
          className={hrClasses}
          style={{ borderTop: `2px ${style} var(--${color})` }}
        />
      </div>
    )
  }
}

Separator.propTypes = {
  style: PropTypes.oneOf(['circle-line', 'dotted', 'solid']),
  width: PropTypes.oneOf(['full-width', 'container', 'half-width']),
  color: PropTypes.oneOf(['primary', 'gray-3', 'logo-color']),
}

Separator.defaultProps = {
  style: 'circle-line',
  width: 'container',
  color: 'primary',
}

export { default as separatorVariants } from './variants'
