import React from 'react'
import { PropTypes } from 'prop-types'

const Badge = ({ badge }) => {
  if (!badge) {
    return null
  }

  return (
    <div className="product-placement-extended__item-badge product-placement-extended__item-badge--top-left font-size-sm">
      <span>{badge}</span>
    </div>
  )
}

Badge.propTypes = {
  badge: PropTypes.string,
}

Badge.defaultProps = {
  badge: '',
}

export default Badge
