import React from 'react'
import PropTypes from 'prop-types'

import {
  AccountButton,
  CartButton,
  HamburgerButton,
  SearchButton,
  StoreButton,
} from '../../Actions'
import {
  AccountFlyout,
  AccountNavigationFlyout,
  NavigationFlyout,
} from '../../Menu'
import { SearchBar } from '../../Search'
import ActionContainer from '../ActionContainer'
import Logo from '../Logo'

function Tablet({ actions, logo, menuData, searchBar }) {
  const { showLabels, showAccount, showCart, showStorefinder, storefinderUrl } =
    actions

  const version = 'tablet'
  const isLabelVisible = showLabels.includes(version)
  const isAccountVisible = showAccount.includes(version)

  return (
    <>
      <Logo className="col-3" {...logo} />
      <div className="col-9 pr-0 d-flex">
        <ActionContainer className="justify-content-end w-100 h-100">
          {searchBar.isPermanent ? (
            <SearchBar
              className="flex-grow position-relative mr-3"
              {...searchBar}
              showCloseButton={false}
            />
          ) : (
            <SearchButton
              showLabel={isLabelVisible}
              searchBar={searchBar}
              showAsPopup={true}
            />
          )}
          {showStorefinder.includes(version) && storefinderUrl && (
            <StoreButton showLabel={isLabelVisible} href={storefinderUrl} />
          )}
          {isAccountVisible && (
            <AccountButton showLabel={isLabelVisible} owns="account-menu" />
          )}
          <CartButton
            showLabel={isLabelVisible}
            showAsBubble={!showCart.includes(version)}
          />

          <HamburgerButton showLabel={isLabelVisible} owns="navigation-menu" />
        </ActionContainer>
      </div>

      {isAccountVisible ? (
        <>
          <AccountFlyout id="account-menu" />
          <NavigationFlyout id="navigation-menu" menuData={menuData} />
        </>
      ) : (
        <AccountNavigationFlyout id="navigation-menu" menuData={menuData} />
      )}
    </>
  )
}

Tablet.protoTypes = {
  actions: PropTypes.shape({
    showLabels: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    showAccount: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    showCart: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    showStorefinder: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    storefinderUrl: PropTypes.string,
  }),
  logo: PropTypes.object,
  menuData: PropTypes.array,
  searchBar: PropTypes.object,
}

Tablet.defaultProps = {
  actions: {
    showLabels: [],
    showAccount: [],
    showCart: [],
    showStorefinder: [],
    storefinderUrl: [],
  },
  logo: {},
  menuData: [],
  searchBar: {},
}

export default Tablet
