import React from 'react'

export default function LoadingSpinner(props) {
  const { className } = props
  const spinnerClassName =
    className || 'loading-spinner d-flex justify-content-center'
  return (
    <div className={spinnerClassName}>
      <span className="spinner-animation" />
    </div>
  )
}
