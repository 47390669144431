import React, { Component } from 'react'
import cookie from 'cookie'

import {
  dispatchHideOverlayEvent,
  dispatchShowOverlayEvent,
  TranslationComponent,
} from '../../../../utils'
import { withSnippet } from '../../../../utils/providers'
import { Button, PreorderPopup } from '../../../index'
import { AddToCartButton } from './index'

class PreorderButton extends Component {
  constructor() {
    super()
    this.state = {
      customerCareEnabled: false,
      preOrderPopupVisible: false,
    }
  }

  componentDidMount() {
    const parsed = cookie.parse(document.cookie)
    if ('aboUser' in parsed) {
      this.setState({
        customerCareEnabled: true,
      })
    }
  }

  showPreOrder = (e) => {
    e.preventDefault()
    dispatchShowOverlayEvent()
    this.setState({
      preOrderPopupVisible: true,
    })
  }

  hidePreOrder = () => {
    this.setState({
      preOrderPopupVisible: false,
    })
    dispatchHideOverlayEvent()
  }

  renderNormal() {
    const {
      text: buttonText,
      className,
      productData,
      getSnippetContent,
    } = this.props
    const { openPopupButtonLabel } = getSnippetContent('preorder_popup') || {}
    const { preOrderPopupVisible } = this.state
    return (
      <>
        <Button
          variant="secondary"
          className={className}
          onClick={this.showPreOrder}
          data-testid="btn-preorder"
        >
          <TranslationComponent
            text={buttonText || openPopupButtonLabel || 'PRODUCT_TILE_PREORDER'}
          />
        </Button>
        {preOrderPopupVisible && (
          <PreorderPopup
            hidePopup={this.hidePreOrder}
            productData={productData}
          />
        )}
      </>
    )
  }

  renderCustomerCare() {
    return (
      <AddToCartButton
        trackingData={{ origin: 'customer-care' }}
        variant="danger"
        {...this.props}
      />
    )
  }

  render() {
    let ret = null
    if (this.state.customerCareEnabled === true) {
      ret = this.renderCustomerCare()
    } else {
      ret = this.renderNormal()
    }
    return ret
  }
}

export default withSnippet(PreorderButton)
