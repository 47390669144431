import { RequestBuilder, fetchFromMakaira, getDefaultLanguage } from '../..'

export default async function fetchSnippetData({
  ids = [],
  language = getDefaultLanguage(),
}) {
  const builder = new RequestBuilder()
  const constraints = builder.getConstraints({ language })

  const body = {
    snippetIds: ids,
    constraints,
  }

  const page = await fetchFromMakaira({ body, isSnippet: true })

  return page
}
