import React from 'react'
import classNames from 'classnames'

export default function Overlay(props) {
  const { isVisible = false, heading = '', text = '', button = {} } = props
  const buttonClasses = classNames({
    ['btn btn-primary']: button.isSolidButton,
    ['link-button']: !button.isSolidButton,
  })

  if (!isVisible) return null

  return (
    <div className="hero-teaser__overlay">
      {heading && (
        <span className="hero-teaser__overlay-heading">{heading}</span>
      )}

      {text && <span className="hero-teaser__overlay-text">{text}</span>}

      {button.isVisible && (
        <a
          href={button.link}
          className={'hero-teaser__button ' + buttonClasses}
        >
          {button.text}
        </a>
      )}
    </div>
  )
}
