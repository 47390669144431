import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

function useMenuListener(listenerTargets) {
  const [isVisible, setVisible] = useState(false)
  const router = useRouter()

  useEffect(() => {
    const targets = Array.isArray(listenerTargets)
      ? listenerTargets
      : [listenerTargets]

    const showMenu = () => setVisible(true)
    const hideMenu = () => setVisible(false)

    targets.map((target) => {
      document.addEventListener(`menu:${target}:show`, showMenu)
      document.addEventListener(`menu:${target}:hide`, hideMenu)
    })
    router?.events.on('routeChangeStart', hideMenu)

    return () => {
      router?.events.off('routeChangeStart', hideMenu)
      targets.map((target) => {
        document.removeEventListener(`menu:${target}:show`, showMenu)
        document.removeEventListener(`menu:${target}:hide`, hideMenu)
      })
    }
  }, [listenerTargets])

  return [isVisible, setVisible]
}

export default useMenuListener
