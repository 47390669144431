import { Component } from 'react'
import GlobalDataContext from '../../../utils/core/GlobalDataProvider'
import Cookies from 'universal-cookie'
import * as Sentry from '@sentry/browser'

export default class VisitorTracking extends Component {
  // Global Context:
  static contextType = GlobalDataContext

  cleared = false
  error = false

  constructor(props) {
    super(props)
  }

  cookie_get(paramName) {
    return this.context?.allCookies !== undefined
      ? this.context?.allCookies[paramName]
      : null
  }

  cookie_set(paramName, parameterValue, options) {
    const cookies = new Cookies()
    options = options || {}
    options.path = '/'
    cookies.set(paramName, parameterValue, options)
  }

  cookie_remove(paramName) {
    const cookies = new Cookies()
    const options = { path: '/' }
    cookies.remove(paramName, options)
  }

  object_equal(a, b) {
    var ak = Object.keys(a)
    var bk = Object.keys(b)

    if (ak.length != bk.length) {
      return false
    }

    for (var i = 0; i < ak.length; ++i) {
      var k = ak[i]
      if (a[k] !== b[k]) {
        return false
      }
    }

    return true
  }

  create_event() {
    // Truncate to seconds
    var timestamp = Math.ceil(new Date().getTime() / 1000)
    var ref = document.referrer || ''
    var my_address = window.location.protocol + '//' + window.location.hostname
    if (ref.indexOf(my_address) === 0) {
      ref = ''
    }
    var orig_params = this.context?.params || {}
    var params = {}
    for (var key in orig_params) {
      // Save parameters with standard prefix (utm_) and custom prefix (apv_)
      var m = key.match(/^(?:utm_|apv_)(.*)/)
      if (m) {
        params[m[1]] = orig_params[key]
      }
      if (key == 'wkz' || key == 'from') {
        params[key] = orig_params[key]
      }
      if (key == 'sc_src') {
        m = /^email_(\d+)/.exec(orig_params[key])
        if (m) {
          params[key] = m[1]
        }
      }
      m = key.match(/^(gclid|msclkid|fbclid)$/)
      if (m) {
        params[key] = 1
      }
    }
    return { t: timestamp, r: ref.substring(0, 50), p: params }
  }

  load_data_from_string(data) {
    var parsed_data = null
    if (data) {
      // First character denotes version of data format
      if (data[0] == '1') {
        parsed_data = JSON.parse(data.substr(1))
      } else {
        // Invalid data format
      }
    }
    return parsed_data
  }

  update_history() {
    if (this.cleared) {
      // If we've just cleared the history, we don't start again immediately.
      return
    }

    var data = null
    try {
      data = this.cookie_get('vh')
    } catch (e) {
      // Delete the cookie which was probably generated with the old library.
      this.cookie_remove('vh')
      this.error = e
    }
    try {
      data = this.load_data_from_string(data) || []
    } catch (e) {
      console.error(e)
      data = null
      this.error = e
    }
    data = data || []

    var souce_event = this.create_event()
    if (data.length) {
      var last_event = data[data.length - 1]
      // Don't add the same event twice within 24 hours
      if (
        last_event.r == souce_event.r &&
        this.object_equal(last_event.p, souce_event.p) &&
        souce_event.t - last_event.t < 86400
      ) {
        return
      }
      // If the current event doesn't contain details and the last (proper) event
      // was less than one hour ago, we ignore it.
      if (
        !souce_event.r &&
        this.object_equal(souce_event.p, {}) &&
        souce_event.t - last_event.t < 3600
      ) {
        return
      }
      // Ensure that we have at most 10 entries.
      // Before we would exceed this limit, we remove a randomly chosen entry,
      // but not the first one.
      if (data.length >= 10) {
        var index = Math.ceil(Math.random() * (data.length - 1))
        data.splice(index, 1)
      }
    }
    data.push(souce_event)

    this.cookie_set('vh', '1' + JSON.stringify(data), { maxAge: 30 * 86400 })
  }

  update_history_safely() {
    try {
      this.update_history()
    } catch (e) {
      this.error = e
    }
    if (this.error) {
      if (
        Sentry &&
        Sentry.captureException &&
        process.env.NODE_ENV === 'production'
      ) {
        Sentry.captureException(this.error)
      } else {
        console.error(this.error)
      }
    }
  }

  clear_history() {
    if (this.cookie_get('vh')) {
      this.cookie_remove('vh')
    }
    if (this.cookie_get('vhd')) {
      this.cookie_remove('vhd')
    }
    this.cleared = true
  }

  componentDidMount() {
    const routerName = this.context?.routerName
    switch (routerName) {
      case '/checkout/shopify/success':
      case '/checkout/onepage/success':
      case '/checkouttester/index/success':
        this.clear_history()
        break
    }

    this.update_history_safely()
  }

  render() {
    return null
  }
}
