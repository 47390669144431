import React from 'react'
import * as Yup from 'yup'

import { InlineButton } from '../../../index'
import { useBackendApi, useTranslation } from '../../../../utils/providers'

import FormBox from '../FormBox'
import { LoginPopupTypes } from '../LoginPopupTypes'

export default function ForgotPasswordForm({ setMode }) {
  const { t } = useTranslation()
  const backendApi = useBackendApi()

  const handleSubmit = async ({ body }) => await backendApi.forgotPassword(body)

  const createFormData = () => ({
    email: {
      name: 'email',
      schema: Yup.string()
        .trim()
        .email(t('FORM_ERROR_WRONG_EMAIL'))
        .min(5, t('FORM_ERROR_WRONG_EMAIL'))
        .required(t('FORM_ERROR_REQ_EMAIL')),
      value: '',
      error: null,
    },
  })

  const createElements = (formData) => [
    {
      type: 'headline',
      label: t('LOGINBOX_FORGOT_PASSWORD'),
      className: 'mb-4',
    },
    {
      type: 'staticText',
      label: t('LOGINBOX_FORGOT_DESCRIPTION'),
    },
    {
      type: 'email',
      label: t('FORM_LABEL_EMAIL_ADDRESS'),
      className: 'mb-4',
      data: formData.email,
      autoFocus: true,
    },
    {
      type: 'errorMessage',
      className: 'mb-4',
    },
    {
      type: 'successMessage',
    },
    {
      type: 'submit',
      label: t('LOGINBOX_SUBMIT'),
      className: 'mb-4',
    },
    {
      type: 'staticText',
      label: t('LOGINBOX_BACK_TO_LOGIN')({
        Link: InlineButton,
        props: {
          className: 'text-primary',
          onClick: () => setMode(LoginPopupTypes.LOGIN),
        },
      }),
      className: 'info-box m-0 pt-3',
    },
  ]

  return (
    <FormBox
      className="forgot-password-box"
      onSubmit={handleSubmit}
      createFormData={createFormData}
      createElements={createElements}
      successMessage={t('LOGINBOX_PROCESSING')}
      setMode={setMode}
    />
  )
}
