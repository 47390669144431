import React, { useContext } from 'react'

const IconContext = React.createContext(null)

const useIcons = () => {
  return useContext(IconContext)
}

function IconProvider({ overrides, children }) {
  const defaults = {
    accountMenuCart: 'cf-cart-o',
    accountMenuLogin: 'cf-sign-in',
    accountMenuLogout: 'cf-sign-out',
    actionAccountLoggedIn: 'cf-user-checked',
    actionAccountLoggedOut: 'cf-user-o',
    actionCart: 'cf-cart-o',
    actionHamburger: 'cf-menu',
    actionSearch: 'cf-search-light',
    actionStorefinder: 'cf-find-store',
    searchBack: 'cf-prev',
    searchClear: 'cf-close-light',
    searchSubmit: 'cf-search-light',
  }

  const getIcon = (iconKey) => {
    return overrides?.[iconKey] || defaults[iconKey] || null
  }

  return <IconContext.Provider value={getIcon}>{children}</IconContext.Provider>
}

export default IconProvider
export { useIcons }
