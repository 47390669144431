import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function DropdownMenu({ className, label, isVisible, children }) {
  const [isHovering, setHovering] = useState(false)

  const ulClasses = classNames(
    'header__dropdown_menu',
    className,
    isVisible || isHovering ? 'd-block' : 'd-none'
  )
  // Fake overlay to deal with the hover effect when the header is sticky
  const overlayClasses = classNames('overlay', {
    'overlay-visible': isVisible || isHovering,
  })

  return (
    <>
      <ul
        aria-label={label}
        className={ulClasses}
        onClick={() => setHovering(false)}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        {children}
      </ul>
      <div className={overlayClasses} />
    </>
  )
}

DropdownMenu.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  children: PropTypes.any,
}

DropdownMenu.defaultProps = {
  className: null,
  isVisible: false,
  children: null,
}

export { DropdownMenu }
export { default as DropdownItem } from './DropdownItem'
