import React, { Component, useContext } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

const ShopifyBackend = dynamic(() => import('./ShopifyBackend'))
const MagentoBackend = dynamic(() => import('./MagentoBackend'))
const DummyBackend = dynamic(() => import('./DummyBackend'))

import {
  StatusData,
  CartData,
  AddToCartData,
  SetCouponData,
  SetCartItemQtyData,
  SetCartItemAboStatusData,
  SetCartDeliveryTimeData,
  ConfirmAccountStatusData,
  LoginData,
  UserData,
  RegisterData,
  ForgotPasswordData,
  NewPasswordData,
  CustomerAccountData,
  SetCustomerAccountData,
  OrderListData,
  OrderListRequestData,
  OrderDetailData,
  OrderDetailsRequestData,
  SuccessData,
  RegularDeliveryData,
  RegularDeliveryChangeData,
  AddressCorrectionData,
} from './Schema'

/**
 * Map of all API function names with their expected input/output schema.
 * Backend implementations must implement these functions. GET requests may not
 * need to provide a request schema.
 *
 * @example
 * {
 *   "functionName": {
 *     request: Schema,
 *     response: Schema,
 *   }
 * }
 *
 * @type {Object}
 */
const backendFunctions = {
  // User
  getUserData: {
    response: UserData,
  },
  newPassword: {
    request: NewPasswordData,
    response: StatusData,
  },
  forgotPassword: {
    request: ForgotPasswordData,
    response: StatusData,
  },
  registerUser: {
    request: RegisterData,
    response: StatusData,
  },
  loginUser: {
    request: LoginData,
    response: UserData,
  },
  logoutUser: {
    response: StatusData,
  },
  getCustomerAccountData: {
    response: CustomerAccountData,
  },
  setCustomerAccountData: {
    request: SetCustomerAccountData,
    response: CustomerAccountData,
  },
  confirmRegistration: {
    // Parameters will be different for every backend provider
    response: ConfirmAccountStatusData,
  },

  resetPassword: {
    // request data will be different for every backend provider
    response: StatusData,
  },

  // Cart
  getCartData: {
    response: CartData,
  },
  getPaymentAddressCorrection: {
    response: AddressCorrectionData,
  },
  addToCart: {
    request: AddToCartData,
    response: CartData,
  },
  setCartItemAboStatus: {
    request: SetCartItemAboStatusData,
    response: CartData,
  },
  setCartItemQty: {
    request: SetCartItemQtyData,
    response: CartData,
  },
  setCouponCode: {
    request: SetCouponData,
    response: CartData,
  },
  removeAboCoupon: {
    response: CartData,
  },
  setShippingOptions: {
    response: CartData,
  },
  setCartDeliveryTime: {
    request: SetCartDeliveryTimeData,
    response: CartData,
  },
  addReorderItems: {},

  // Order
  cancelOrder: {},
  preorderProduct: {},
  paymentReview: {},
  setPaymentAddressCorrection: {},
  getOrderDataForRatings: {},
  getOrderList: {
    request: OrderListRequestData,
    response: OrderListData,
  },
  getOrderDetails: {
    request: OrderDetailsRequestData,
    response: OrderDetailData,
  },
  getReorderProductList: {},
  getSubscriptionDetails: {
    response: RegularDeliveryData,
  },
  updateSubscriptionData: {
    request: RegularDeliveryChangeData,
  },

  // Success
  getSuccessData: {
    response: SuccessData,
  },

  // Newsletter
  submitNewsletterForm: {},
  confirmNewsletter: {},
  unsubscribeNewsletter: {},
  optoutNewsletter: {},
  submitLeadform: {},
}

/**
 * The context object.
 *
 * @type {React.Context<unknown>}
 */
const BackendApiContext = React.createContext()

/**
 * API provider that holds on to the current backend implementation and exposes its functions.
 *
 * @param {Object} props
 * @param {string} [props.backendApi] - Optional override for the backend to be used. Defaults to `process.env.SHOP_BACKEND`
 * @param {JSX.Element[]} [props.children] - Child elements
 * @returns {JSX.Element} - The context-provider component
 * @constructor
 */
class BackendApiProvider extends Component {
  constructor(props) {
    super(props)
  }

  getApiFunctions = (apiInstance) => {
    const { backendApi = process.env.SHOP_BACKEND } = this.props

    if (!this.apiFunctions) {
      // Map available API functions by their name and expose them to the outside
      const apiFunctions = {}
      Object.keys(backendFunctions).map((name) => {
        // Is the function defined in the backend class?
        const hasFunction = Object.prototype.hasOwnProperty.call(
          apiInstance,
          name
        )

        if (hasFunction && typeof apiInstance[name] === 'function') {
          const inputSchema = backendFunctions[name].request
          const outputSchema = backendFunctions[name].response
          // Add validation around the function
          apiFunctions[name] = async (...params) => {
            // Validate input
            if (process.env.NODE_ENV !== 'production') {
              console.log('REQUEST', name, params)
            }
            this.validateSchema(
              name,
              'input',
              inputSchema,
              params.length === 1 ? params[0] : params
            )

            // Call the function
            const response = await apiInstance[name](...params)

            // Validate output
            this.validateSchema(name, 'output', outputSchema, response)
            if (process.env.NODE_ENV !== 'production') {
              console.log('RESPONSE', name, response)
            }
            // Return
            return response
          }
        } else {
          apiFunctions[name] = () => {
            throw Error(
              `Endpoint "${name}" is not supported by backend "${backendApi}"`
            )
          }
        }
      })
      // Store it
      this.apiFunctions = apiFunctions
    }
    return this.apiFunctions
  }

  validateSchema = (endpoint, type, schema, data) => {
    if (process.env.NODE_ENV !== 'production') {
      try {
        const options = { abortEarly: false, strict: true }
        schema?.noUnknown().validateSync(data, options)
      } catch (error) {
        const msg = `Validation error: Invalid ${type} for endpoint "${endpoint}":`
        if (error.errors) {
          error.errors.map((e) => console.error(msg, e))
        } else {
          console.error(msg, error)
        }
      }
    }
  }

  render() {
    const { backendApi = process.env.SHOP_BACKEND, children } = this.props

    let Backend = null
    switch (backendApi) {
      case 'shopify':
        Backend = ShopifyBackend
        break
      case 'magento':
        Backend = MagentoBackend
        break
      case 'dummy':
      default:
        Backend = DummyBackend
        break
    }

    return (
      <Backend createApiFunctions={this.getApiFunctions}>{children}</Backend>
    )
  }
}

BackendApiProvider.propTypes = {
  /**
   * Optional override for the backend to be used. Defaults to `process.env.SHOP_BACKEND`
   *
   * @type {string}.
   */
  backendApi: PropTypes.string,
  /**
   * Child elements.
   *
   * @type {JSX.Element[]}
   */
  children: PropTypes.element,
}

/**
 * A hook that returns the current API context.
 *
 * @returns {*} - The context object
 */
function useBackendApi() {
  return useContext(BackendApiContext)
}

/**
 * A HOC that appends the current API instance to the props of a component.
 *
 * @param {JSX.Element} WrappedComponent - The component to enhance
 * @returns {function(*)} - The given component with an additional `backendApi` prop
 */
function withBackendApi(WrappedComponent) {
  return function WithBackendApi(props) {
    const ctx = useBackendApi()
    return <WrappedComponent backendApi={ctx} {...props} />
  }
}

export default BackendApiProvider
export { BackendApiContext, withBackendApi, useBackendApi }
