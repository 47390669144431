export default function formatPrice(price) {
  const obj = new Intl.NumberFormat(process.env.LOCALE, {
    style: 'currency',
    currency: process.env.CURRENCY,
  })

  if (isNaN(price) || typeof price === 'boolean') {
    return obj.format(0)
  }
  return obj.format(price)
}
