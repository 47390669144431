import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ErrorMessage from '../ErrorMessage'

export default function Input(props) {
  const {
    className,
    data,
    label,
    type = 'text',
    onChange,
    spellCheck = false,
    hidden,
    ...rest
  } = props
  const { name, value: propValue, error } = data
  const [isFocused, setFocused] = useState(false)
  const [inputValue, setInputValue] = useState(propValue)

  useEffect(() => {
    // Watch changed props
    setInputValue(propValue)
  }, [propValue])

  const handleChange = (evt) => {
    setInputValue(evt.target?.value)
    if (typeof onChange === 'function') {
      onChange(evt)
    }
  }

  const groupClasses = classNames(className, 'md-input-group', {
    error: !!error,
  })
  const labelClasses = classNames(null, {
    'float-above':
      !!inputValue || propValue || isFocused || 'placeholder' in rest,
  })
  const inputClasses = classNames('form-control', {
    email: type === 'email',
  })

  let inputMode = rest.inputMode || null
  if (!inputMode && type === 'number') {
    if (rest?.step?.toString().indexOf('.') > -1) {
      inputMode = 'decimal'
    } else {
      inputMode = 'numeric'
    }
  }

  return (
    <div className={groupClasses} hidden={hidden || null}>
      <input
        type={type}
        className={inputClasses}
        name={name}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={handleChange}
        value={inputValue || ''}
        spellCheck={spellCheck}
        inputMode={inputMode}
        {...rest}
      />

      {label && (
        <div className="label-box">
          <label className={labelClasses} htmlFor={name}>
            {label}
          </label>
        </div>
      )}

      <ErrorMessage
        // Make sure to not obstruct native spinner buttons
        className={type === 'number' ? 'mr-4' : null}
        message={error}
      />
    </div>
  )
}

Input.propTypes = {
  /**
   * A CSS class added to the container element (optional).
   */
  className: PropTypes.string,

  /**
   * The data object which identifies the input by name, holds the initial
   * value, and an error message (can be a component).
   *
   * See also '../Form/index.js'
   */
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    error: PropTypes.any,
  }).isRequired,

  /**
   * A label text to be rendered (optional, can be a component).
   */
  label: PropTypes.any,

  /**
   * Callback that is called by 'Form.onChange()' when the value of the input
   * element changed. See 'data' prop, and '../Form/index.js'.
   *
   * @param {string} name - The 'name' attribute of the input element
   * @param {string} value - The changed input value
   * @param {any} error - An error message in case validation fails
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Set the input type (optional, defaults to 'text').
   */
  type: PropTypes.oneOf(['text', 'email', 'number', 'hidden']),

  /**
   * Set the 'spellcheck' attribute (optional, defaults to 'false').
   */
  spellCheck: PropTypes.bool,

  /**
   * Set the 'hidden' attribute (optional, defaults to 'false').
   *
   * Note: This is not the same as setting input[type=hidden]!
   */
  hidden: PropTypes.bool,

  /**
   * Set the 'inputmode' attribute (optional).
   *
   * Note: When the input type is 'number' this value is set to either
   * 'numeric' or 'decimal' (depending on the 'step' prop). This can be
   * overridden by explicitly setting the 'inputMode' prop.
   */
  inputMode: PropTypes.string,
}
