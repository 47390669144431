import React, { Component } from 'react'
import classNames from 'classnames'

import {
  dispatchHideOverlayEvent,
  dispatchShowOverlayEvent,
  TranslationComponent,
} from '../../../../utils'
import { Button, QuickviewPopup } from '../../../index'

export default class VariantButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      popupVisible: false,
    }
  }

  showQuickview = (e) => {
    e.preventDefault()
    dispatchShowOverlayEvent()
    this.setState({
      popupVisible: true,
    })
  }

  hideQuickview = () => {
    this.setState({
      popupVisible: false,
    })
    dispatchHideOverlayEvent()
  }

  render() {
    const { productData, className = null, variant, text } = this.props
    const btnClass = classNames({
      [className]: !!className,
    })
    const btnText = text || (
      <TranslationComponent text="PRODUCT_TILE_CHOSE_VARIANT" />
    )

    return (
      <>
        <Button
          className={btnClass}
          variant={variant}
          onClick={this.showQuickview}
          data-testid="btn-choose-variant"
        >
          {btnText}
        </Button>
        {this.state.popupVisible && (
          <QuickviewPopup
            hidePopup={this.hideQuickview}
            product={productData}
          />
        )}
      </>
    )
  }
}
