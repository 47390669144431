import { useEffect } from 'react'
import PropTypes from 'prop-types'
import TagManager from 'react-gtm-module'

/**
 * A placeable GTM-dataLayer component.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function DataLayer(props) {
  const { dataLayerProps } = props

  // Make sure we're calling GTM only when needed
  useEffect(() => {
    // Make sure we're not adding empty data
    const layerObj = {}
    dataLayerProps?.map((item) => {
      const { name, value } = item
      if (name !== '' && value !== '') {
        if (name in layerObj) {
          console.warn(
            `Overwriting duplicate property key "${name}" with "${value}" (was: "${layerObj[name]}")`
          )
        }
        layerObj[name] = value
      } else {
        console.warn(
          `Not adding empty dataLayer property "${name}" (value: "${value}")`
        )
      }
    })

    // Fire
    if (Object.keys(layerObj).length > 0) {
      TagManager.dataLayer({
        dataLayer: layerObj,
      })
    }
  }, [dataLayerProps])

  // No need to return anything
  return null
}

DataLayer.propTypes = {
  dataLayerProps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
}
