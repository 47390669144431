import React, { Fragment } from 'react'
import { useTranslation } from './index'

export default function TranslationComponent(props) {
  const { t } = useTranslation()
  const { element = null, text, args, ...rest } = props
  const Element = element ? element : null
  const translation = t(text)
  const output =
    typeof args !== 'undefined' && typeof translation === 'function'
      ? translation(args)
      : translation || text
  if (Element) {
    return (
      <Element key={text} {...rest}>
        {output}
      </Element>
    )
  }
  // Not passing {...rest} as fragments don't support attributes.
  // See: https://reactjs.org/docs/fragments.html#keyed-fragments
  return <Fragment key={text}>{output}</Fragment>
}
