import React from 'react'
import PropTypes from 'prop-types'

import { Link } from '../..'

function ConditionalLink(props) {
  const { href = '', fallbackElement = 'div', children, ...rest } = props

  if (href && href != '') {
    return (
      <Link href={href} {...rest}>
        {children}
      </Link>
    )
  }

  if (fallbackElement.toLowerCase() == 'fragment') {
    return <>{children}</>
  }

  const Element = fallbackElement

  return <Element {...rest}>{children}</Element>
}

ConditionalLink.propTypes = {
  href: PropTypes.string,
  fallbackElement: PropTypes.string,
  children: PropTypes.node,
}

export default ConditionalLink
