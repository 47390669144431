import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  dispatchOverlayClickedEvent,
  useOutsideClick,
  useTranslation,
} from '../../../../../utils'
import {
  Icon,
  Link,
  MarkdownAwareSpan,
  sanitizeMarkdown,
} from '../../../../index'

function FlyoutItem({
  label,
  level = 1,
  items,
  href,
  onClick,
  startIcon,
  endIcon,
  showOverview = true,
  className,
  variant,
}) {
  const [isExpanded, setExpanded] = useState(false)
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const actionRef = useRef(null)

  useOutsideClick(containerRef, () => {
    if (isExpanded) {
      setExpanded(false)
    }
  })

  if (items?.length && !endIcon) {
    endIcon = isExpanded ? 'cf-minus-light' : 'cf-plus-light'
  }

  let ActionElement
  let actionProps = {
    className: 'header__flyout_action cursor-pointer',
  }
  if (typeof onClick === 'function') {
    ActionElement = 'button'
    actionProps.onClick = (evt) => {
      dispatchOverlayClickedEvent()
      setTimeout(() => onClick(evt), 10) // Allow the menu to close first
    }
  } else if (items?.length) {
    ActionElement = 'button'
    actionProps.onClick = () => {
      if (!isExpanded) {
        setTimeout(
          () => actionRef.current?.scrollIntoView({ block: 'nearest' }),
          10
        )
      }
      setExpanded(!isExpanded)
    }
  } else {
    ActionElement = Link
    actionProps.href = href
  }

  const liClasses = classNames('header__flyout_item', className, {
    [`header__flyout_item--lvl0${level}`]: level > 0,
    'header__flyout_item--active': isExpanded,
    [`header__flyout_item--${variant}`]: !!variant,
  })

  return (
    <li className={liClasses} ref={containerRef}>
      <ActionElement {...actionProps}>
        <div
          ref={actionRef}
          className="w-100 d-flex justify-content-between align-items-center pl-2"
        >
          {startIcon && <Icon symbol={startIcon} />}
          <MarkdownAwareSpan>{label}</MarkdownAwareSpan>
          {endIcon && <Icon symbol={endIcon} />}
        </div>
      </ActionElement>
      {items?.length > 0 && isExpanded && (
        <ul className="header__flyout_list">
          {showOverview && (
            <FlyoutItem
              level={level + 1}
              label={t('NAVBAR_OVERVIEW')(sanitizeMarkdown(label))}
              href={href}
              variant="secondary"
            />
          )}
          {items.map((item, index) => (
            <FlyoutItem key={index} level={level + 1} {...item} />
          ))}
        </ul>
      )}
    </li>
  )
}

FlyoutItem.propTypes = {
  label: PropTypes.string,
  level: PropTypes.number,
  items: PropTypes.array, // FlyoutItem props
  href: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  onClick: PropTypes.func,
  startIcon: PropTypes.string,
  endIcon: PropTypes.string,
  showOverview: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
}

FlyoutItem.defaultProps = {
  label: null,
  level: 1,
  items: [],
  href: null,
  onClick: null,
  startIcon: null,
  endIcon: null,
  showOverview: true,
  className: null,
  variant: 'primary',
}

export default FlyoutItem
