import React from 'react'
import classNames from 'classnames'

import { useConfiguration } from '../../../utils'

export default function TwoColumnCardWithBG(props) {
  const { isFullWidth = false, cards } = props
  const { getImageLink } = useConfiguration()
  if (!cards || !cards.length) {
    return null
  }
  const containerClasses = classNames('two-column-card-with-bg', {
    container: !isFullWidth,
  })
  const rowClasses = classNames('row pt-4 pt-md-5', {
    'mx-0': isFullWidth,
  })
  return (
    <section className={containerClasses}>
      <div className={rowClasses}>
        {cards.map((item, index) => {
          const { image, title, text, buttonText, buttonIcon, link } = item
          const imageLink = getImageLink({ source: image })
          const iconClass = classNames('font-size-m ml-2', buttonIcon)
          const LinkElement = link ? 'a' : 'div'
          return (
            <div key={index} className="col col-12 col-md-6 pb-4 pb-md-0">
              <div
                className="image-container"
                style={
                  imageLink ? { backgroundImage: `url("${imageLink}")` } : null
                }
              >
                <div className="image-effect">
                  <div className="desc-container">
                    {title && (
                      <span className="d-block mb-3 font-size-xl font-weight-semi-bold text-uppercase">
                        {title}
                      </span>
                    )}
                    {text && <p className="mb-2">{text}</p>}
                    {buttonText && (
                      <LinkElement
                        href={link}
                        className="btn btn-primary d-block d-lg-inline-block px-4"
                      >
                        <span>{buttonText}</span>
                        {buttonIcon && <i className={iconClass} />}
                      </LinkElement>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export { default as twoColumnCardWithBGVariants } from './variants'
