import React from 'react'
import PropTypes from 'prop-types'

import {
  AccountButton,
  CartButton,
  SearchButton,
  StoreButton,
} from '../../Actions'
import { AccountDropdown /*, NavigationBar*/ } from '../../Menu'
import { SearchBar } from '../../Search'
import Logo from '../Logo'
import ActionContainer from '../ActionContainer'

function Desktop({ actions, logo /*, menuData*/, searchBar }) {
  const { showLabels, showStorefinder, storefinderUrl } = actions
  const { isPermanent } = searchBar

  const version = 'desktop'
  const isLabelVisible = showLabels.includes(version)
  const isStoreFinderVisible = showStorefinder.includes(version)

  return (
    <>
      <ActionContainer className="col-4 justify-content-start">
        {!isPermanent && isStoreFinderVisible && storefinderUrl && (
          <StoreButton showLabel={isLabelVisible} href={storefinderUrl} />
        )}
        {isPermanent ? (
          <SearchBar
            className="flex-grow position-relative mr-3"
            {...searchBar}
          />
        ) : (
          <SearchButton showLabel={isLabelVisible} searchBar={searchBar} />
        )}
      </ActionContainer>
      <Logo className="col-4 text-center" {...logo} />
      <ActionContainer className="col-4 justify-content-end">
        {isPermanent && isStoreFinderVisible && storefinderUrl && (
          <StoreButton showLabel={isLabelVisible} href={storefinderUrl} />
        )}
        <AccountButton
          className="header__action-account--dropdown"
          showLabel={isLabelVisible}
          menuStyle="dropdown"
        />
        <CartButton showLabel={isLabelVisible} showAsBubble={false} />
      </ActionContainer>

      {/* The navigation bar for desktop is rendered in Header/index.js to
       * avoid using portals here, which causes flickering during initial
       * rendering.
       * TODO: The layout component should be responsible for deciding
       * what kind of menu is to be displayed.
       */}
      {/*<NavigationBar menuData={menuData} />*/}
      <AccountDropdown />
    </>
  )
}

Desktop.protoTypes = {
  actions: PropTypes.shape({
    showLabels: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    showStorefinder: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    storefinderUrl: PropTypes.string,
  }),
  logo: PropTypes.object,
  //menuData: PropTypes.array,
  searchBar: PropTypes.object,
}

Desktop.defaultProps = {
  actions: {
    showLabels: [],
    showStorefinder: [],
    storefinderUrl: [],
  },
  logo: {},
  //menuData: [],
  searchBar: {},
}

export default Desktop
