import React, { useContext } from 'react'
import classNames from 'classnames'

import { Link } from '../..'
import {
  formatPrice,
  PricingContext,
  shouldOpenInNewTab,
  useGlobalVariables,
  useTranslation,
} from '../../../utils'

const ProductPriceHintWithPrice = ({
  className,
  openInNewTab,
  productData,
}) => {
  const { t } = useTranslation()
  const [shippingUrl] = useGlobalVariables(['shippingUrl'])
  const {
    pricingContext: { specialPrice },
  } = useContext(PricingContext) || {}

  const basePrice = (specialPrice / productData.baseprice_coefficient).toFixed(
    2
  )
  const suffix = productData.baseprice_suffix

  const priceHintClass = classNames('text-dark additional-info', className)

  return (
    <div className={priceHintClass}>
      {t('PRICE_HINT_WITH_PRICE')({
        Component: Link,
        url: shippingUrl,
        basePrice: formatPrice(basePrice),
        suffix,
        ...shouldOpenInNewTab(openInNewTab),
      })}
    </div>
  )
}

export default ProductPriceHintWithPrice
