import React from 'react'

import Desktop from './Desktop'
import Tablet from './Tablet'
import Mobile from './Mobile'

function Layout({ version, ...rest }) {
  return (
    <div className="row" key={`left-${version}`}>
      {version === 'mobile' && <Mobile key="mobile" {...rest} />}
      {version === 'tablet' && <Tablet key="tablet" {...rest} />}
      {version === 'desktop' && <Desktop key="desktop" {...rest} />}
    </div>
  )
}

export default Layout
