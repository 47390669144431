export default function isFixedBundle(productData) {
  let fixedBundle = false
  if (
    productData.product_type === 'bundle' &&
    'bundle_data_only' in productData
  ) {
    fixedBundle = productData.is_fixed_bundle === true
  }
  return fixedBundle
}
