import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  dispatchOverlayClickedEvent,
  useOutsideClick,
} from '../../../../../utils'
import { Icon, Link } from '../../../../index'

function FlyoutHeading({
  label,
  sub,
  level = 1,
  className,
  actionClasses,
  href,
  onClick,
  startIcon,
  endIcon,
  children,
}) {
  const [isExpanded, setExpanded] = useState(false)
  const containerRef = useRef(null)
  const actionRef = useRef(null)

  useOutsideClick(containerRef, () => {
    if (isExpanded) {
      setExpanded(false)
    }
  })

  const hasChildren = Array.isArray(children) && children.length > 0

  const actionProps = {}
  let ActionElement = 'div'
  actionClasses = classNames(actionClasses, 'header__flyout_action')

  if (!endIcon && endIcon !== null) {
    endIcon = isExpanded ? 'cf-minus-light' : 'cf-plus-light'
  }

  if (typeof onClick === 'function') {
    ActionElement = 'button'
    actionProps.onClick = (evt) => {
      dispatchOverlayClickedEvent()
      setTimeout(() => onClick(evt), 10) // Allow the menu to close first
    }
    actionClasses = classNames(actionClasses, 'cursor-pointer')
  } else if (hasChildren) {
    ActionElement = 'button'
    actionProps.onClick = () => {
      if (!isExpanded) {
        setTimeout(
          () => actionRef.current?.scrollIntoView({ block: 'nearest' }),
          10
        )
      }
      setExpanded(!isExpanded)
    }
    actionClasses = classNames(actionClasses, 'cursor-pointer')
  } else if (href) {
    ActionElement = Link
    actionProps.href = href
    actionClasses = classNames(actionClasses, 'cursor-pointer')
  }
  actionProps.className = actionClasses

  const liClasses = classNames('header__flyout_heading', className, {
    [`header__flyout_heading--lvl0${level}`]: level > 0,
    'header__flyout_heading--active': isExpanded,
  })

  return (
    <>
      <li className={liClasses} ref={containerRef}>
        <ActionElement {...actionProps}>
          <div ref={actionRef} className="w-100 d-flex align-items-center pl-2">
            {startIcon && <Icon symbol={startIcon} />}
            <div>
              <strong className="d-block">{label}</strong>
              {sub && <span className="d-block">{sub}</span>}
            </div>
            {endIcon && <Icon symbol={endIcon} />}
          </div>
        </ActionElement>
      </li>
      {hasChildren && isExpanded && children}
    </>
  )
}

FlyoutHeading.propTypes = {
  label: PropTypes.string,
  sub: PropTypes.string,
  className: PropTypes.string,
  actionClasses: PropTypes.string,
  href: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  onClick: PropTypes.func,
  startIcon: PropTypes.string,
  endIcon: PropTypes.string,
  children: PropTypes.any,
}

FlyoutHeading.defaultProps = {
  label: null,
  sub: null,
  level: 1,
  className: null,
  actionClasses: null,
  href: null,
  onClick: null,
  startIcon: null,
  children: null,
}

export default FlyoutHeading
