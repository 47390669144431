import React from 'react'
import ConditionalLink from '../../core/ConditionalLink'
import classNames from 'classnames'

function CustomText(props) {
  const {
    link = '',
    size = '',
    weight = '',
    align = '',
    color = '',
    underline = false,
    className = '',
    text = 'Sample Text',
    icon = '',
    iconPosition = '',
  } = props

  const textContainerClasses = classNames(
    className,
    'd-flex align-items-center',
    {
      'justify-content-center': align === 'center',
      'justify-content-end': align === 'right',
      'justify-content-start': align === 'left',
      [`font-size-${size}`]: size,
      [`font-weight-${weight}`]: weight,
      [`text-${align}`]: align,
      [`text-${color}`]: color,
      ['text-underline']: underline,
    }
  )

  return (
    <div className="container mb-4">
      <ConditionalLink
        href={link}
        fallbackElement="span"
        className={textContainerClasses}
      >
        {icon && iconPosition === 'before-text' && (
          <i className={icon + ' mr-2'} />
        )}
        {text}
        {icon && iconPosition === 'after-text' && (
          <i className={icon + ' ml-2'} />
        )}
      </ConditionalLink>
    </div>
  )
}

export default CustomText
export { default as customTextVariants } from './variants.js'
