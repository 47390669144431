import React from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'

import { useTranslation } from '../../../utils/providers'

const CustomDot = ({ active, className, onClick, index }) => {
  const { t } = useTranslation()
  const dotClassNames = classNames(
    'carousel__dot border-0 cursor-pointer',
    `carousel__dot--${active ? 'active' : 'inactive'}`,
    className
  )

  return (
    <li>
      <button
        type="button"
        className={dotClassNames}
        onClick={onClick}
        aria-label={t('SLIDE_N')(index + 1)}
      />
    </li>
  )
}

CustomDot.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

CustomDot.defaultProps = {
  active: false,
  className: null,
  onClick: null,
}

export default CustomDot
