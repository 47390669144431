import React from 'react'
import PropTypes from 'prop-types'

import { Link, MarkdownAwareSpan } from '../../../../index'

function SubmenuColumn({ label, href, onItemBlur, onItemClick, items }) {
  return (
    <li className="col-2">
      <Link
        className="header__navbar_submenu_heading"
        href={href}
        onBlur={onItemBlur}
        onClick={onItemClick}
      >
        <MarkdownAwareSpan>{label}</MarkdownAwareSpan>
      </Link>
      {items?.length > 0 && (
        <ul>
          {items.map((item, index) => (
            <li key={index}>
              <Link
                href={item.href}
                className="header__navbar_submenu_link"
                onBlur={onItemBlur}
                onClick={onItemClick}
              >
                <MarkdownAwareSpan>{item.label}</MarkdownAwareSpan>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}

SubmenuColumn.propTypes = {
  label: PropTypes.string,
  href: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  onItemBlur: PropTypes.func,
  onItemClick: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      href: PropTypes.string,
    })
  ),
}

SubmenuColumn.defaultProps = {
  label: null,
  href: null,
  onItemBlur: null,
  onItemClick: null,
  items: [],
}

export default SubmenuColumn
