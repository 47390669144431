import React, { Component } from 'react'
import classNames from 'classnames'

import { fetchSessionlyRatings, fetchSessionlyReviewUrls } from '../../../utils'
import {
  withCarouselSettings,
  withFeatureFlags,
} from '../../../utils/providers'

import Carousel from '../Carousel'
import ProductTile from './ProductTile'

class ProductPlacement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productRatings: {},
      productReviewUrls: null,
    }
    // Make the layout accessible from the outside
    this.responsiveLayout = {
      desktop: {
        breakpoint: { max: Number.MAX_VALUE, min: 1200 },
        items: 4,
      },
      tablet: {
        breakpoint: { max: 1199.98, min: 1024 },
        items: 3,
      },
      mobileM: {
        breakpoint: { max: 1023.98, min: 568 },
        items: 2,
      },
      mobileS: {
        breakpoint: { max: 567.98, min: 160 },
        items: 1,
      },
    }
  }

  componentDidMount() {
    const productIds = []
    const productSkus = []
    this.props.products?.map((product) => {
      productIds.push(product.id)
      productSkus.push(product.sku)
    })
    this.getRatings(productIds)
    this.getReviewUrls(productSkus)
  }

  /**
   * Get ratings for products.
   *
   * @param {number[]} productIds - A list of product-IDs
   * @returns {Promise<void>}
   */
  getRatings(productIds) {
    const { isFeatureEnabled } = this.props
    if (isFeatureEnabled('product.ratings')) {
      fetchSessionlyRatings(productIds)
        .then((productRatings) => {
          this.setState({ productRatings })
        })
        .catch(console.error)
    }
  }

  /**
   * Get review URLs for products.
   *
   * @param {string[]} productSkus - A list of product-SKUs
   * @returns {Promise<void>}
   */
  getReviewUrls(productSkus) {
    const { isFeatureEnabled, redirectToReviewUrl } = this.props
    if (isFeatureEnabled('product.review') && redirectToReviewUrl) {
      fetchSessionlyReviewUrls(productSkus)
        .then((productReviewUrls) => {
          this.setState({ productReviewUrls })
        })
        .catch(console.error)
    }
  }

  render() {
    const {
      isFullWidth = false,
      products = [],
      priceFlag = true,
      renderButtonGroupOutside = false,
      customButtonGroup = null,
      trackingClass = '',
      redirectToReviewUrl = false,
      afterSlideChange,
      trackingIdentifier,
      carouselSettings,
    } = this.props
    const { productReviewUrls, productRatings } = this.state

    const containerClass = classNames('product-placement__list', {
      ['react-multi-carousel-center']: products.length < 4,
    })

    const productTileClass = classNames({
      ['justify-content-lg-center']: products.length === 1,
    })

    if (products.length === 0) {
      return null
    }

    const className = classNames('product-placement mb-4', {
      container: !isFullWidth,
    })

    return (
      <section className={className}>
        <div className={containerClass}>
          <Carousel
            responsive={this.responsiveLayout}
            className={productTileClass}
            customButtonGroup={customButtonGroup}
            renderButtonGroupOutside={renderButtonGroupOutside}
            afterChange={afterSlideChange}
            isFullWidth={isFullWidth}
            {...carouselSettings}
          >
            {products.map((product, key) => {
              const reviewUrl = productReviewUrls?.[product.sku] || ''
              const itemRating = productRatings?.[product.id] || null
              return (
                <ProductTile
                  reviewUrl={reviewUrl}
                  ratingSummary={itemRating}
                  priceFlag={priceFlag}
                  key={key}
                  trackingClass={trackingClass}
                  productData={product}
                  redirectToReviewUrl={redirectToReviewUrl}
                  data-selector={'ri-' + product.id}
                  sku={product.sku}
                  trackingIdentifier={trackingIdentifier}
                />
              )
            })}
          </Carousel>
        </div>
      </section>
    )
  }
}

export default withFeatureFlags(
  withCarouselSettings(ProductPlacement, 'product-placement')
)

export { default as productPlacementVariants } from './variants.js'
