import React from 'react'

import { useCustomer, useFeatureFlags, useTranslation } from '../../../../utils'
import { useIcons } from '../IconProvider'

import {
  FlyoutHeading,
  FlyoutItem,
  FlyoutMenu,
  FlyoutSection,
} from './FlyoutMenu'
import PortalWrapper from './PortalWrapper'
import { useMenuListener } from './utils'

function AccountFlyout({ id }) {
  const [isVisible, setVisible] = useMenuListener('account')
  const { isLoggedIn, fullName, isAboGroup } = useCustomer()
  const { isFeatureEnabled } = useFeatureFlags()
  const { t } = useTranslation()
  const getIcon = useIcons()

  if (!isLoggedIn) {
    return null
  }

  return (
    <PortalWrapper query="#modal-root">
      <FlyoutMenu id={id} isVisible={isVisible} setVisible={setVisible}>
        <FlyoutSection label={t('HEADER_ACCOUNT_AREA')}>
          <FlyoutHeading
            label={fullName}
            startIcon={getIcon('actionAccountLoggedIn')}
            actionClasses="justify-content-start"
            endIcon={null}
          />
          <FlyoutItem
            label={t('ACCOUNT_MENU_DASHBOARD')}
            href="/customer/account"
          />
          <FlyoutItem
            label={t('ACCOUNT_MENU_INFORMATION')}
            href="/customer/account/edit"
          />
          <FlyoutItem
            label={t('ACCOUNT_MENU_ORDERS')}
            href="/sales/order/history"
          />
          {isFeatureEnabled('abo') && isAboGroup && (
            <FlyoutItem
              label={t('ACCOUNT_MENU_MY_INTERVALS')}
              href="/cartUpdate/abodelivery/view"
            />
          )}
          <FlyoutItem
            label={t('ACCOUNT_MENU_CONTACT')}
            href="/customer/account/contact"
          />
          <FlyoutItem
            label={t('ACCOUNT_MENU_LOGOUT')}
            href="/customer/account/logout"
            endIcon={getIcon('accountMenuLogout')}
            className="border-0"
          />
        </FlyoutSection>
      </FlyoutMenu>
    </PortalWrapper>
  )
}

export default AccountFlyout
