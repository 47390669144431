import React, { forwardRef, useContext } from 'react'
import { useSnippetContent } from '../SnippetProvider'

const FeatureFlagContext = React.createContext()

function useFeatureFlags() {
  return useContext(FeatureFlagContext)
}

function withFeatureFlags(WrappedComponent) {
  return forwardRef(function WithFeatureFlags(props, ref) {
    const ctx = useFeatureFlags()
    return <WrappedComponent ref={ref} {...ctx} {...props} />
  })
}

/**
 * Provides means to check if a feature is enabled in Makaira.
 *
 * Every instance should have an essential snippet "feature_flags" that is
 * configured with the "config-flags" component. All features will resolve to
 * "disabled" if absent. A feature is considered "enabled" when its flag is
 * present in the snippet config.
 *
 * Note: This component depends on the {@link SnippetProvider}.
 *
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function FeatureFlagProvider({ children }) {
  const { flags = [] } = useSnippetContent('feature_flags') || {}

  if (!Array.isArray(flags)) {
    console.error('Feature Flags Snippet does not exist!')
  }
  const featureFlags =
    flags?.reduce?.((result, { flag, options }) => {
      result[flag] = options
      return result
    }, {}) || []

  const isFeatureEnabled = (featureKey) =>
    featureKey && featureFlags?.[featureKey] !== undefined

  if (typeof window !== 'undefined' && isFeatureEnabled('userTracking')) {
    window.userTrackingEnabled = true
  }

  return (
    <FeatureFlagContext.Provider value={{ featureFlags, isFeatureEnabled }}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export {
  FeatureFlagContext,
  FeatureFlagProvider,
  useFeatureFlags,
  withFeatureFlags,
}
