import React from 'react'

import { useTranslation } from '../../../../utils'

import NavBar from './NavBar'
//import PortalWrapper from './PortalWrapper'
import { normalizeMenuData } from './utils'

function NavigationBar({ menuData }) {
  const { language, t } = useTranslation()

  const primary = normalizeMenuData(
    menuData?.find((m) => m.text[language] === 'PRIMARY_NAVIGATION') || [],
    language
  )
  const secondary = normalizeMenuData(
    menuData?.find((m) => m.text[language] === 'SECONDARY_NAVIGATION') || [],
    language
  )

  return (
    <>
      {/* The navigation bar for desktop is rendered in Header/index.js to
       * avoid using portals here, which causes flickering during initial
       * rendering.
       * TODO: The layout component should be responsible for deciding
       * what kind of menu is to be displayed.
       */}
      {/*<PortalWrapper>*/}
      <NavBar
        label={t('HEADER_NAVIGATION')}
        primaryItems={primary}
        secondaryItems={secondary}
      />
      {/*</PortalWrapper>*/}
    </>
  )
}

export default NavigationBar
