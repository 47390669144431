import React from 'react'
import classNames from 'classnames'

import { Button, ConditionalLink, Image, RawHtml, Text } from '../../index'
import { truncateString } from '../../../utils'

function truncateText(image, str) {
  return image ? truncateString(str, 150) : str
}

export default function Card(props) {
  const {
    image = '',
    alt = '',
    link = '',
    btnText = '',
    heading = '',
    text = '',
    buttonClass = '',
    buttonVariant = '',
    alignmentClass = '',
  } = props

  const headingClass = classNames(alignmentClass, {
    ['mt-3']: heading,
  })
  const textClass = classNames(alignmentClass, {
    ['mt-3']: text && !heading,
  })
  const linkClass = classNames('text-center', {
    ['mb-3']: (heading || text) && !btnText,
    ['d-block']: link,
  })

  return (
    <>
      {image && (
        <ConditionalLink
          href={link}
          fallbackElement="fragment"
          className={linkClass}
        >
          <Image srcDesktop={image} alt={alt} className="text-center" />
        </ConditionalLink>
      )}
      {btnText && (
        <Button variant={buttonVariant} href={link} className={buttonClass}>
          {btnText}
        </Button>
      )}
      {heading && (
        <Text
          size="m"
          weight="semi-bold"
          element="span"
          className={headingClass}
          text={truncateText(image, heading)}
        />
      )}
      {text && (
        <RawHtml className={textClass} html={truncateText(image, text)} />
      )}
    </>
  )
}
