import * as Yup from 'yup'

const SuccessData = Yup.object().shape({
  status: Yup.string().required(),
  frontend: Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    orderNumber: Yup.string().required(),
    paymentMethod: Yup.string().required(),
    items: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(),
          product_id: Yup.string().required(),
          sku: Yup.string().required(),
          url: Yup.string().required(),
          name: Yup.string().required(),
          qty: Yup.number().required(),
          badge: Yup.string().required(),
          image: Yup.string().required(),
          manufacturer: Yup.string().required(),
          config_variable_a: Yup.string().required(),
          price: Yup.string().required(),
          strikePrice: Yup.string().required(),
          produkt_linie: Yup.string().required(),
          product_categories: Yup.array().of(Yup.string()),
        })
      )
      .required(),
    totals: Yup.array()
      .of(
        Yup.object().shape({
          code: Yup.string().required(),
          title: Yup.string().nullable(),
          value: Yup.string().required(),
        })
      )
      .required(),

    shippingAddress: Yup.string().required(),
    encryptionKey: Yup.string().required(),
    email: Yup.string().required(),
    canCancel: Yup.boolean().required(),
  }),
  trustedShops: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string().required(),
        value: Yup.string().required(),
      })
    )
    .required(),
  gtm: Yup.object().shape({
    event: Yup.string().required(),
    ecommerce: Yup.object().shape({
      purchase: Yup.object()
        .shape({
          actionField: Yup.object()
            .shape({
              id: Yup.string().required(),
              affiliation: Yup.string().optional(),
              revenue: Yup.number().required(),
              tax: Yup.number().required(),
              shipping: Yup.number().required(),
              shippingInclTax: Yup.number().required(),
              coupon: Yup.string().optional(),
              nettoTotalAmount: Yup.number().required(),
              customerType: Yup.string().required(),
              discount: Yup.number().optional(),
              grandTotal: Yup.number().required(),
              customerEmail: Yup.string().required(),
              firstName: Yup.string().required(),
              lastName: Yup.string().required(),
              customerAddress: Yup.object()
                .shape({
                  street: Yup.string().required(),
                  postcode: Yup.string().required(),
                  city: Yup.string().required(),
                  country: Yup.string().required(),
                })
                .required(),
              currencyCode: Yup.string().required(),
              subtotal: Yup.number().required(),
            })
            .required(),
        })
        .required(),

      products: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().required(),
            id: Yup.string().required(),
            sku: Yup.string().required(),
            price: Yup.number().required(),
            priceInclTax: Yup.number().required(),
            brand: Yup.string().required(),
            variant: Yup.string().required(),
            produkt_linie: Yup.string().required(),
            quantity: Yup.string().required(),
            categories: Yup.array().of(Yup.string()).optional(),
          })
        )
        .optional(),
    }),
  }),
})

export { SuccessData }
