import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import PopupNewsletter from './PopupNewsletter'

export default class PortalWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = { newsletterElements: [] }
  }

  componentDidMount() {
    document.body.addEventListener('magebird_loaded', this.addNewsletter)
  }
  componentWillUnmount() {
    document.body.removeEventListener('magebird_loaded', this.addNewsletter)
  }

  addNewsletter = () => {
    document.querySelectorAll('.react-newsletter').forEach((element) => {
      let newlyCalculatedArray = this.state.newsletterElements
      newlyCalculatedArray.push(element)
      this.setState({ newsletterElements: newlyCalculatedArray })
    })
  }

  render() {
    return (
      <>
        {this.state.newsletterElements.map((element) => {
          const html = <PopupNewsletter />
          return ReactDOM.createPortal(html, element)
        })}
      </>
    )
  }
}
