import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SlideDown from 'react-slidedown'

import { useMediaResize } from '../../../utils'
import { AlertBox, InstanceBanner, LoginPopup } from '../../index'

import Banners from './Banners'
import { LogoCenter, LogoLeft } from './Layout'
import IconProvider from './IconProvider'
import { NavigationBar } from './Menu'

function Header({ config, menuData, showInstanceBanner }) {
  const {
    breakpoints: { downLG, downMD, upMD },
    initialDevice,
  } = useMediaResize()
  const [version, setVersion] = useState(initialDevice)
  const [isCollapsed, setCollapsed] = useState(false)
  const [isReallyFullWidth, setReallyFullWidth] = useState(true)

  const {
    // Fallback values when props are incomplete
    layout = 'logoLeft',
    isFullWidth = true,
    isSticky = false,
    colorScheme = 'white',
    logo,
    searchBar = {},
    actions,
    iconOverrides,
    disableColorGradient,
  } = config

  const searchBarProps = { ...searchBar }
  searchBarProps.showSubmitButton =
    searchBar.showSubmitButton?.includes(version)

  // Collapse the header when scrolling down
  useEffect(() => {
    if (
      isSticky &&
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setCollapsed(!entry.isIntersecting)
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0,
        }
      )
      observer.observe(document.querySelector('#scroll_marker'))

      return () => {
        observer.disconnect()
      }
    }
  }, [isSticky, setCollapsed])

  // Save device version and full-width in a state to avoid SSR rendering issues.
  // This triggers an initial re-render - this is intentional, as SSR always
  // assumes a non-scrolled desktop version to be rendered.
  useEffect(() => {
    let version = 'desktop'
    if (downMD) {
      version = 'mobile'
    } else if (upMD && downLG) {
      version = 'tablet'
    }
    setVersion(version)
    setReallyFullWidth(
      isFullWidth || (isSticky && isCollapsed) || version !== 'desktop'
    )
    return () => {}
  }, [downLG, downMD, upMD, isCollapsed, isSticky, isFullWidth])

  let Layout
  switch (layout) {
    case 'logoCentered':
      Layout = LogoCenter
      break
    case 'logoLeft':
      Layout = LogoLeft
      break
  }

  if (!Layout) {
    return <AlertBox>Invalid layout.</AlertBox>
  }

  const wrapperClasses = classNames('header', {
    'header--sticky': isSticky,
  })
  const bannerClasses = classNames('header__banner', {
    [`header__banner--${colorScheme}`]: !!colorScheme,
    'container px-0': !isReallyFullWidth,
  })
  const sectionClasses = !isReallyFullWidth ? 'container px-0' : 'w-100'
  const contentClasses = classNames('header__content', {
    'header__layout-center': layout === 'logoCentered',
    'header__content--collapsed': isSticky && isCollapsed,
    'header__content--no-gradient': disableColorGradient,
    [`header__content--${colorScheme}`]: !!colorScheme,
  })
  const menuClasses = classNames('header__menu', {
    'container px-0': !isReallyFullWidth,
  })

  return (
    <IconProvider overrides={iconOverrides}>
      <div id="scroll_marker" />
      <header className={wrapperClasses}>
        {showInstanceBanner && <InstanceBanner />}
        <SlideDown
          closed={isSticky && isCollapsed}
          className={bannerClasses}
          transitionOnAppear={false}
        >
          <Banners colorScheme={colorScheme} />
        </SlideDown>
        <section className={sectionClasses}>
          <div className={contentClasses}>
            <div className="container">
              <Layout
                version={version}
                actions={actions}
                logo={logo}
                menuData={menuData}
                searchBar={searchBarProps}
                isSticky={isSticky}
                isCollapsed={isCollapsed}
                iconOverrides={iconOverrides}
              />
            </div>
          </div>
        </section>
        <div className={menuClasses}>
          {/* This is a workaround to avoid using portals which cause
           * flickering during initial rendering.
           * TODO: The layout component should be responsible for deciding
           * what kind of menu is to be displayed.
           */}
          {version === 'desktop' && <NavigationBar menuData={menuData} />}
        </div>
        <LoginPopup />
      </header>
    </IconProvider>
  )
}

Header.propTypes = {
  config: PropTypes.shape({
    layout: PropTypes.oneOf(['logoCentered', 'logoLeft']),
    isFullWidth: PropTypes.bool,
    isSticky: PropTypes.bool,
    colorScheme: PropTypes.oneOf(['primary', 'white']),
    logo: PropTypes.object,
    searchBar: PropTypes.object,
    actions: PropTypes.object,
    iconOverrides: PropTypes.object,
    disableColorGradient: PropTypes.bool,
  }),
  menuData: PropTypes.array,
  showInstanceBanner: PropTypes.bool,
}

Header.defaultProps = {
  config: {
    layout: 'logoLeft',
    isFullWidth: true,
    isSticky: false,
    colorScheme: 'white',
    logo: {},
    searchBar: {},
    actions: {},
    iconOverrides: {},
    disableColorGradient: false,
  },
  menuData: [],
  showInstanceBanner: false,
}

export default Header
export { default as headerVariants } from './variants.js'
