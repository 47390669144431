import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../Icon'
import Link from '../../../Link'

const SocialNetworks = ({ socialNetworks }) => (
  <div className="d-flex align-items-center justify-content-center">
    {socialNetworks
      .slice(0, Math.min(socialNetworks.length, 3))
      .map(({ title, icon, link }) => (
        <Link
          key={`${title}_${icon}`}
          className="topbar__icon"
          target="_blank"
          rel="noopener noreferrer"
          href={link}
          title={title}
        >
          <Icon symbol={icon} />
        </Link>
      ))}
  </div>
)

SocialNetworks.propTypes = {
  socialNetworks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
      link: PropTypes.string,
    })
  ),
}

SocialNetworks.defaultProps = {
  socialNetworks: [],
}

export default SocialNetworks
