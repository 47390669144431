import React from 'react'

import { Link } from '../../../../index'

export default function LinkItem(props) {
  const { url, title: name, description } = props.itemData
  const imageUrl = '/assets/images/tags_20.png'
  return (
    <li className="header__search-results-item">
      <Link className="search__results-item-link" href={url}>
        <img className="d-block" src={imageUrl} alt="search-result-img" />
        <div className="search__results-item-info">
          <div className="search__results-item-name">{name}</div>
          <div className="search__results-item-description">{description}</div>
        </div>
      </Link>
    </li>
  )
}
