import { useContext } from 'react'
import { SnippetContext } from '../..'

/**
 * Get the values of global variables.
 *
 * @param {string[]} [ids] - An optional list of variable names
 * @returns {Object[]|*[]} - The variable values for the given names, or the whole set of variables if no names are provided
 */
export default function useGlobalVariables(ids) {
  const ctx = useContext(SnippetContext)
  const snippetId = 'global_variables_container'

  if (!ctx) {
    return []
  }

  const { fetchSnippets, [snippetId]: snippet } = ctx
  fetchSnippets(snippetId)

  const vars = snippet?.[0]?.properties?.content?.globalVariables || []
  if (!ids) {
    return vars
  }
  return ids.map((id) => vars.find((v) => v.varName === id)?.varValue)
}
