import React from 'react'
import { PropTypes } from 'prop-types'
import { Text } from '../..'

const Badge = ({ badge }) => {
  if (!badge) {
    return null
  }

  return (
    <div className="product-offers__item-badge border-radius-lg py-1 px-2 bg-primary position-absolute">
      <Text
        color="white"
        align="center"
        weight="semi-bold"
        text={badge}
        className="white-space-nowrap"
      />
    </div>
  )
}

Badge.propTypes = {
  badge: PropTypes.string,
}

Badge.defaultProps = {
  badge: '',
}

export default Badge
