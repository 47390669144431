// Source: https://dev.to/9zemian5/sync-height-between-elements-in-react-1fg0

/**
 * Get an array of elements and get height of the largest and paste it for
 * everyone in array. if `targetElements` are present then set max height for
 * elements in `targetElements` and in their children, also moves last child
 * upper in max height. if `restore` enabled, then it restores styles of
 * target elements and their children
 *
 * @param elements
 * @param targetElements
 * @param restore
 */
export const syncRefHeight = (elements, targetElements, restore) => {
  if (elements) {
    let max = 0
    // find the element with highest clientHeight value
    elements.forEach((element) => {
      if (element?.clientHeight > max) {
        max = element.clientHeight
      }
    })

    // update height of all target elements in array
    if (targetElements) {
      targetElements.forEach((element) => {
        if (element) {
          const { childNodes } = element

          // Restore defoult height of description section
          if (restore) {
            element.style.height = 'auto'
            childNodes.forEach((child) => (child.style.height = 'auto'))
            element.lastElementChild.style.transform = 'none'

            // Set height for elements
          } else {
            const elementStyles =
              element.currentStyle || window.getComputedStyle(element)
            const padding =
              parseInt(elementStyles.paddingTop) +
              parseInt(elementStyles.paddingBottom) // padding of parent (description) element
            const maxHeight = max + padding * 2
            element.style.height = `${maxHeight}px` // set parent (descrition) max height from heighestelement in children elements
            childNodes.forEach(
              (child) => (child.style.height = `${maxHeight}px`)
            ) // set parents height for child coponents (actions and info)
            element.lastElementChild.style.transform = `translateY(-${maxHeight}px)` // moves last element (actions) upper
          }
        }
      })

      // update height of all elements in array
    } else {
      elements.forEach((element) => {
        if (element) {
          element.style.minHeight = `${max}px`
        }
      })
    }
  }
}
