import React from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'

import { ListPagePricingProvider, shouldOpenInNewTab } from '../../../utils'
import { Image, Text, ProductRating } from '../..'
import ProductPrices from '../PriceInfo/ProductPrices'
import ProductLink from '../Link/ProductLink'
import CopyCoupon from '../CopyCoupon'
import ProductPriceHintWithPrice from '../PriceInfo/ProductPriceHintWithPrice'
import RawHtml from '../RawHtml'
import ProductActions from './ProductActions'
import Badge from './Badge'

const ProductOffersCard = ({
  ratingSummary,
  productData,
  badgesFlag,
  manufacturerFlag,
  nameFlag,
  productSizeFlag,
  ratingFlag,
  productBenefitsFlag,
  priceFlag,
  couponCodeFlag,
  buttonFlag,
  buttonType,
  trackingIdentifier,
  openInNewTab,
  couponCode,
  badge,
  hasCodeBorderRadius,
  benefits,
  benefitRef,
  descriptionRef,
  descriptionChildRef,
  productCardClassNames,
  buttonClasses,
  imageClasses,
  descriptionClasses,
  descriptionInfoClasses,
  benefitsClasses,
  descriptionActionClasses,
  descriptionWrapperClasses,
  imageWrapperClasses,
  couponLabelAlignment,
  hoverColor,
}) => {
  const {
    manufacturer_name = '',
    produkt_linie = '',
    name = '',
    config_variable_a = '',
    small_image = '',
    images = [],
    url = [],
  } = productData

  //productDataForTracking is used in GTM to push the product data from clicked recommendation product to Google Analytics
  const productDataForTracking = `${manufacturer_name} - ${produkt_linie} - ${name} - ${config_variable_a}`
  const showDescription =
    manufacturerFlag ||
    nameFlag ||
    productSizeFlag ||
    ratingFlag ||
    productBenefitsFlag ||
    priceFlag ||
    couponCodeFlag ||
    buttonFlag ||
    buttonType === 'addToCart'
  const couponeCodeClasses = classNames(
    'w-100 product-offers__clickable',
    hasCodeBorderRadius && 'product-offers__code--rounded'
  )
  const overlayClasses = classNames(
    'product-offers__item-overlay h-100 w-100 position-absolute',
    `product-offers__item-overlay--${hoverColor}`
  )

  return (
    <ListPagePricingProvider productData={productData}>
      <div className={productCardClassNames}>
        {badgesFlag && <Badge badge={badge} />}

        <div className={overlayClasses} />

        <ProductLink
          href={url}
          className={imageWrapperClasses}
          {...shouldOpenInNewTab(openInNewTab)}
        >
          <Image
            className={imageClasses}
            srcFull={images[0] || small_image}
            alt={name}
          />
        </ProductLink>

        {showDescription && (
          <div className={descriptionWrapperClasses}>
            <div className={descriptionClasses} ref={descriptionRef}>
              <div
                ref={descriptionChildRef()}
                className={descriptionInfoClasses}
              >
                {(manufacturerFlag || buttonType === 'addToCart') && (
                  <Text
                    className="d-block w-100 text-truncate product-name font-size-m text-uppercase"
                    element="span"
                    text={`${manufacturer_name} ${produkt_linie}`}
                  />
                )}

                {(nameFlag || buttonType === 'addToCart') && (
                  <Text
                    weight="semi-bold"
                    className="d-block w-100 text-truncate font-size-m"
                    element="span"
                    text={name}
                  />
                )}

                {(productSizeFlag || buttonType === 'addToCart') && (
                  <Text
                    className="d-block w-100 text-truncate"
                    element="span"
                    text={config_variable_a}
                  />
                )}

                {(ratingFlag || buttonType === 'addToCart') &&
                  (ratingSummary?.count > 0 ? (
                    <ProductRating
                      productData={productData}
                      ratingClass="mb-1 product-offers__clickable"
                      ratingSummary={ratingSummary}
                      openInNewTab={openInNewTab}
                    />
                  ) : (
                    <div className="product-ratings mt-2" />
                  ))}

                {productBenefitsFlag && (
                  <RawHtml
                    element="div"
                    className={benefitsClasses}
                    html={benefits}
                    forceStrictMode
                    ref={benefitRef}
                  />
                )}
              </div>

              <div
                ref={descriptionChildRef(true)}
                className={descriptionActionClasses}
              >
                {(priceFlag || buttonType === 'addToCart') && (
                  <>
                    <ProductPrices
                      productData={productData}
                      classContainer="white-space-nowrap"
                      classSpecialPrice="text-primary font-size-xxl"
                      classRegularPrice="d-inline-block ml-2 text-gray-2 font-size-l"
                      showBasePrice={false}
                    />
                    <ProductPriceHintWithPrice
                      productData={productData}
                      openInNewTab={openInNewTab}
                      className="font-size-xs d-block mb-2 white-space-nowrap text-gray-2 text-truncate product-offers__price-hint product-offers__clickable"
                    />
                  </>
                )}

                {couponCodeFlag && couponCode.couponCode && (
                  <CopyCoupon
                    labelAlignment={couponLabelAlignment}
                    labelFontSize="m"
                    labelWeight="semi-bold"
                    className={couponeCodeClasses}
                    {...couponCode}
                  />
                )}

                {(buttonFlag || buttonType === 'addToCart') && (
                  <ProductActions
                    url={url}
                    productDataForTracking={productDataForTracking}
                    className={buttonClasses}
                    trackingIdentifier={trackingIdentifier}
                    buttonType={buttonType}
                    openInNewTab={openInNewTab}
                    productData={productData}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </ListPagePricingProvider>
  )
}

ProductOffersCard.propTypes = {
  ratingSummary: PropTypes.shape({
    rating: PropTypes.number,
    count: PropTypes.number,
  }),
  productData: PropTypes.shape({
    manufacturer_name: PropTypes.string,
    produkt_linie: PropTypes.string,
    name: PropTypes.string,
    config_variable_a: PropTypes.string,
    small_image: PropTypes.string,
    url: PropTypes.arrayOf(PropTypes.string),
  }),
  badgesFlag: PropTypes.bool,
  manufacturerFlag: PropTypes.bool,
  nameFlag: PropTypes.bool,
  productSizeFlag: PropTypes.bool,
  ratingFlag: PropTypes.bool,
  productBenefitsFlag: PropTypes.bool,
  priceFlag: PropTypes.bool,
  couponCodeFlag: PropTypes.bool,
  buttonFlag: PropTypes.bool,
  buttonType: PropTypes.oneOf(['addToCart', 'productPage']),
  trackingIdentifier: PropTypes.string,
  openInNewTab: PropTypes.bool,
  couponCode: PropTypes.shape({
    couponCode: PropTypes.string,
    couponCodeLabel: PropTypes.string,
  }),
  badge: PropTypes.string,
  hasCodeBorderRadius: PropTypes.bool,
  benefits: PropTypes.string,
  benefitRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  descriptionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  descriptionChildRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  productCardClassNames: PropTypes.string,
  buttonClasses: PropTypes.string,
  imageClasses: PropTypes.string,
  descriptionClasses: PropTypes.string,
  descriptionInfoClasses: PropTypes.string,
  benefitsClasses: PropTypes.string,
  descriptionActionClasses: PropTypes.string,
  descriptionWrapperClasses: PropTypes.string,
  imageWrapperClasses: PropTypes.string,
  couponLabelAlignment: PropTypes.oneOf(['center', 'left']),
  hoverColor: PropTypes.oneOf(['normal', 'light']),
}

ProductOffersCard.defaultProps = {
  ratingSummary: null,
  productData: {},
  badgesFlag: true,
  manufacturerFlag: true,
  nameFlag: true,
  productSizeFlag: true,
  ratingFlag: true,
  productBenefitsFlag: true,
  priceFlag: true,
  couponCodeFlag: true,
  buttonFlag: true,
  buttonType: 'productPage',
  trackingIdentifier: '',
  openInNewTab: false,
  couponCode: {},
  badge: '',
  hasCodeBorderRadius: true,
  benefits: '',
  benefitRef: null,
  descriptionRef: null,
  descriptionChildRef: null,
  productCardClassNames: null,
  buttonClasses: null,
  imageClasses: null,
  descriptionClasses: null,
  descriptionInfoClasses: null,
  benefitsClasses: null,
  descriptionActionClasses: null,
  descriptionWrapperClasses: null,
  imageWrapperClasses: null,
  couponLabelAlignment: null,
  hoverColor: 'normal',
}

export default ProductOffersCard
