import * as Yup from 'yup'

import { ProductData } from './product'
import { StatusData } from './status'

const PaymentMethod = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string(),
  url: Yup.string(),
})

const CartProduct = Yup.object().shape({
  id: Yup.string().required(),
  aboId: Yup.number().optional().nullable(), // ID used for toggling abo
  sku: Yup.string().required(),
  url: Yup.string().required(),
  name: Yup.string().required(),
  manufacturer: Yup.string().required(),
  image: Yup.string().optional().nullable(),
  badge: Yup.string(),
  config_variable_a: Yup.string(), // TODO: find a better name
  selectedOptions: Yup.string(), // HTML string in Magento - may be misleading
  price: Yup.string().required(),
  strikePrice: Yup.string().nullable(),
  finalPrice: Yup.number(),
  aboPrice: Yup.string().nullable(),
  subscriptionPercentage: Yup.number().nullable(),
  subscriptionSavings: Yup.number().nullable(),
})

const CartItem = Yup.object().shape({
  product: CartProduct.required(),
  qty: Yup.number().required(),
  maxQty: Yup.number(),
  outOfQty: Yup.boolean(),
  outOfStock: Yup.boolean(),
  isAboEnabled: Yup.boolean().optional(),
})

const ShippingOptions = Yup.object().shape({
  availableCountries: Yup.object().required(),
  currentCountryCode: Yup.string(),
  availableShippingSlots: Yup.array().of(Yup.string()),
  carrierCountryText: Yup.string(),
  dispatchCompanyText: Yup.string().nullable(),
})

const CartTotals = Yup.object().shape({
  code: Yup.string().required(),
  title: Yup.string().required(),
  value: Yup.string().required(),
})

const CartData = Yup.object()
  .shape({
    quoteIdHash: Yup.string().nullable().optional(),
    items: Yup.array().of(CartItem).required().min(0),
    totals: Yup.array().of(CartTotals).required().min(0),
    paymentMethods: Yup.array().of(PaymentMethod),
    reorderPaymentMethods: Yup.array().optional(),
    shippingOptions: ShippingOptions.optional(),
    deliveryTime: Yup.string().oneOf(['default', 'later']),
    couponCode: Yup.string().nullable(),
    canCheckout: Yup.boolean().required(),
    isAboActive: Yup.boolean().optional(),
    aboInterval: Yup.number().nullable().optional(),
    paypalShortcutId: Yup.string().nullable().optional(),
  })
  .concat(StatusData)

const AddToCartData = Yup.object().shape({
  productData: ProductData,
  qty: Yup.number(),
  selectedOptions: Yup.object().nullable().optional(),
  isAboEnabled: Yup.boolean().optional(),
  couponCode: Yup.string().nullable().optional(),
})

const SetCartItemQtyData = Yup.object().shape({
  cartProduct: CartProduct,
  qty: Yup.number(),
})

const SetCouponData = Yup.object().shape({
  couponCode: Yup.string().defined(),
})

const SetCartDeliveryTimeData = Yup.object().shape({
  deliveryTime: Yup.string().oneOf(['default', 'later']),
})

const SetCartItemAboStatusData = Yup.object().shape({
  cartProduct: CartProduct,
  isAboEnabled: Yup.boolean(),
})

export {
  ShippingOptions,
  PaymentMethod,
  CartTotals,
  CartProduct,
  CartItem,
  CartData,
  AddToCartData,
  SetCouponData,
  SetCartItemQtyData,
  SetCartItemAboStatusData,
  SetCartDeliveryTimeData,
}
