import React from 'react'
import PropTypes from 'prop-types'
import MultiCarousel from 'react-multi-carousel'
import classNames from 'classnames'

import { useMediaResize } from '../../../utils'

import CustomDot from './CustomDot'
import CustomArrow from './CustomArrow'

const Carousel = ({
  children,
  dotListClass,
  arrowsClass,
  dotsClass,
  arrowColor,
  buttonsType,
  isFullWidth,
  renderDotsOutside,
  responsive,
  ...props
}) => {
  // Re-render when the window size changes
  const { windowWidth } = useMediaResize()

  const arrows = ['arrows', 'arrows-dots'].includes(buttonsType)
  let showDots = false
  if (['dots', 'arrows-dots'].includes(buttonsType)) {
    showDots = Object.values(responsive).reduce((prevResult, device) => {
      const {
        breakpoint: { min, max },
        items: itemCount,
      } = device

      // Don't show dots when all items are visible
      if (windowWidth >= min && windowWidth <= max) {
        return children.length > itemCount
      }
      return prevResult
    }, true)
  }

  const customDotListClass = classNames('carousel__dot-list', dotListClass)

  return (
    <MultiCarousel
      dotListClass={customDotListClass}
      customDot={<CustomDot className={dotsClass} />}
      renderDotsOutside={renderDotsOutside}
      responsive={responsive}
      showDots={showDots}
      arrows={arrows}
      customLeftArrow={
        <CustomArrow
          direction="prev"
          arrowColor={arrowColor}
          className={arrowsClass}
          isFullWidth={isFullWidth}
        />
      }
      customRightArrow={
        <CustomArrow
          direction="next"
          arrowColor={arrowColor}
          className={arrowsClass}
          isFullWidth={isFullWidth}
        />
      }
      {...props}
    >
      {children}
    </MultiCarousel>
  )
}

Carousel.propTypes = {
  children: PropTypes.any,
  arrowColor: PropTypes.string,
  buttonsType: PropTypes.oneOf(['arrows', 'dots', 'arrows-dots', 'none']),
  dotListClass: PropTypes.string,
  arrowsClass: PropTypes.string,
  dotsClass: PropTypes.string,
  isFullWidth: PropTypes.bool,
  renderDotsOutside: PropTypes.bool,
  responsive: PropTypes.object,
}

Carousel.defaultProps = {
  children: null,
  arrowColor: null,
  buttonsType: 'arrows',
  dotListClass: null,
  arrowsClass: null,
  dotsClass: null,
  isFullWidth: false,
  renderDotsOutside: true,
  responsive: null,
}

export default Carousel
