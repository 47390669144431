import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Image, RawHtml, ConditionalLink } from '../../'

const TwoColumnsImageText = ({
  isFullWidth = false,
  image: { imageMobile, imageDesktop, link, altText } = {},
  richText = '',
  isReverse = false,
}) => {
  const imageSourceMobile = imageMobile
  const imageSourceDesktop = imageDesktop
  const containerClasses = classNames('two-columns-image-text mb-4', {
    container: !isFullWidth,
  })
  const rowClasses = classNames('row', {
    'flex-column-reverse flex-md-row-reverse': isReverse,
    'mx-0': isFullWidth,
  })

  return (
    <section className={containerClasses}>
      <div className={rowClasses}>
        <div className="col-12 col-md-6">
          <ConditionalLink href={link} className="d-block text-center">
            <Image
              srcDesktop={imageSourceDesktop}
              srcMobile={imageSourceMobile}
              alt={altText}
            />
          </ConditionalLink>
        </div>
        <div
          className={
            'col-12 col-md-6 align-self-center ' +
            (isReverse ? 'mb-3 mb-md-0' : 'mt-3 mt-md-0')
          }
        >
          <RawHtml element="div" className="rich-content" html={richText} />
        </div>
      </div>
    </section>
  )
}

TwoColumnsImageText.propTypes = {
  isFullWidth: PropTypes.bool,
  image: PropTypes.shape({
    imageMobile: PropTypes.string,
    imageDesktop: PropTypes.string,
    link: PropTypes.string,
    altText: PropTypes.string,
  }),
  richText: PropTypes.string,
  isReverse: PropTypes.bool,
}

export default TwoColumnsImageText
export { default as twoColumnsImageTextVariants } from './variants.js'
