import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { CustomCouponingContext } from './CustomCouponingProvider'
import {
  InternalPricingContext,
  InternalPriceCalculator,
} from './InternalPriceCalculator'

const PricingContext = React.createContext()

function PricingProvider({ productData, children }) {
  const customCouponingContext = useContext(CustomCouponingContext)
  const pricingContext = useContext(InternalPricingContext)
  const value = {
    productData,
    customCouponingContext,
    pricingContext,
  }
  return (
    <PricingContext.Provider value={value}>{children}</PricingContext.Provider>
  )
}

PricingProvider.propTypes = {
  productData: PropTypes.object.isRequired,
  children: PropTypes.any,
}

function ListPagePricingProvider({ productData, getAboPrice, children }) {
  if (!productData) {
    return children
  }
  return (
    <InternalPriceCalculator
      productData={productData}
      getAboPrice={getAboPrice}
    >
      <PricingProvider productData={productData}>{children}</PricingProvider>
    </InternalPriceCalculator>
  )
}

ListPagePricingProvider.propTypes = {
  productData: PropTypes.object,
  getAboPrice: PropTypes.func,
  children: PropTypes.any,
}

function usePricingProvider() {
  return useContext(PricingContext)
}

export {
  // Context:
  PricingContext,
  // For PDP,List&Grid Pages - Wrapper:
  ListPagePricingProvider,
  // For Functional Components:
  usePricingProvider,
}
