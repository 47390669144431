import React from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import { Icon } from '../..'
import { useTranslation } from '../../../utils'

const CustomArrow = ({
  className,
  direction,
  onClick,
  arrowColor,
  isFullWidth,
}) => {
  const { t } = useTranslation()
  const label = direction === 'prev' ? t('PREVIOUS_SLIDE') : t('NEXT_SLIDE')
  const arrowClassNames = classNames(
    'carousel__arrow position-absolute cursor-pointer border-0',
    `carousel__arrow--${direction}-${isFullWidth ? 'l' : 's'}`,
    `carousel__arrow--${arrowColor}`,
    className
  )
  const iconClasses = classNames(
    'font-size-3xl',
    direction === 'next' ? 'ml-1' : 'mr-1'
  )

  return (
    <button aria-label={label} className={arrowClassNames} onClick={onClick}>
      <Icon symbol={`cf-${direction}`} className={iconClasses} />
    </button>
  )
}

CustomArrow.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(['next', 'prev']),
  onClick: PropTypes.func,
  arrowColor: PropTypes.oneOf(['primary', 'white']),
  isFullWidth: PropTypes.bool,
}

CustomArrow.defaultProps = {
  className: null,
  direction: 'next',
  onClick: null,
  arrowColor: 'white',
  isFullWidth: false,
}

export default CustomArrow
