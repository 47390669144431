import React, { Component } from 'react'

import { isFixedBundle } from '../../../utils'

const BundleContext = React.createContext()

const BUNDLE_TYPE_FIXED = 'fixed'
const BUNDLE_TYPE_DYNAMIC = 'dynamic'

class BundleProvider extends Component {
  constructor(props) {
    super(props)
    this.state = this.getBundleSelections()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.productData.id != this.props.productData.id) {
      this.setState(this.getBundleSelections())
    }
  }

  /**
   * We need to set selected bundle options as they will be needed while adding the product into the cart.
   *
   * @param {string} name
   * @param value
   */
  setSelectedOptions = (name, value) => {
    const { selectedOptions } = this.state
    selectedOptions[name] = value ? value : null
    this.setState({
      selectedOptions,
    })
  }

  /**
   * Here we are setting initial value of bundle options.
   * As in case of fixed bundle we don't have select box from which we can select options.
   * So, it will set option-selection pair as default value of selected options.
   * Which will be used in addToCart later.
   */
  getBundleSelections = () => {
    let selectedOptions = {}
    const { productData } = this.props
    const bundleData = productData.bundle_data_only
    if (
      bundleData &&
      Object.keys(bundleData).length > 0 &&
      isFixedBundle(productData)
    ) {
      Object.entries(bundleData.options).map(([selectName, value]) => {
        const selectionsKeys = Object.keys(value.selections)
        if (selectionsKeys.length === 1) {
          let selectionValue = selectionsKeys[0]
          selectedOptions[selectName] = selectionValue
        }
      })
    }
    return {
      selectedOptions,
    }
  }

  /**
   * Here, we are collecting all bundle option-selection pair in an array.
   * @returns {[]}
   */
  getOptions() {
    const { productData } = this.props
    const bundleData = productData.bundle_data_only?.options

    const isFixed = productData.is_fixed_bundle
    const bundleOptions = []
    if (
      bundleData &&
      Object.keys(bundleData).length > 0 &&
      productData.product_type === 'bundle'
    ) {
      Object.keys(bundleData).forEach((optionId) => {
        const { title, selections } = bundleData[optionId] || {}
        if (!selections) {
          return
        }
        const selectionObj = []
        const errorVisible = this.isErrorVisible(optionId)
        Object.keys(selections).map((selectionId) => {
          const selection = selections[selectionId]
          const name = selection.nameApv
          selectionObj[selectionId] = {
            id: selectionId,
            name: name,
            selected: isFixed,
          }
        })
        bundleOptions[optionId] = {
          id: optionId,
          title: title,
          selections: selectionObj,
          errorVisible: errorVisible,
        }
      })
    }
    return bundleOptions
  }

  /**
   * If a customer is clicking on addToCart and has not selected bundle options then we must show error.
   * This function is checking if optionId is in selectedOptions or not. If not then it cause an exception and
   * product will not be added into the cart.
   * @param optionId
   * @returns {boolean}
   */
  isErrorVisible = (optionId) => {
    const keyIsSet = optionId in this.state.selectedOptions
    const valueIsSet = !!this.state.selectedOptions[optionId]
    const visible = !keyIsSet || (keyIsSet && !valueIsSet)
    return visible
  }

  /**
   * If a bundle product is fixed bundle and has only one option and only one selection
   * inside then we don't show selection info at all.
   * @returns {boolean}
   */
  showBundleOptions = () => {
    const { productData } = this.props
    let showBundleOptions = true
    if (productData.product_type === 'bundle') {
      let countSelections = 1
      const bundleData = productData.bundle_data_only.options
      Object.keys(bundleData).map((propskey) => {
        const selections = bundleData[propskey].selections
        countSelections = Object.keys(selections).length
      })
      if (Object.keys(bundleData).length === 1 && countSelections === 1) {
        showBundleOptions = false
      }
    }
    return showBundleOptions
  }

  render() {
    const { productData, children } = this.props
    const bundleType = productData.is_fixed_bundle
      ? BUNDLE_TYPE_FIXED
      : BUNDLE_TYPE_DYNAMIC
    const value = {
      bundleType,
      options: this.getOptions(),
      showBundleOptions: this.showBundleOptions(),
      setSelectedOptions: this.setSelectedOptions,
      selectedOptions: this.state.selectedOptions,
    }
    return (
      <BundleContext.Provider value={value}>{children}</BundleContext.Provider>
    )
  }
}

export default BundleProvider
export { BundleContext }
