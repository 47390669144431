import React from 'react'
import { RawHtml } from '../../../../index'
import { useTranslation } from '../../../../../utils'

export default function BannerTop(props) {
  const { addToCartCoupon, bannerTopLeftText, bannerTopRightText } = props
  const { t } = useTranslation()
  return (
    <div id="customcouponing_container_react">
      <div>
        <div className="customcoupon-container py-2">
          <div className="container">
            <div className="row">
              <div className="col-4 text-center">
                <RawHtml className="d-block" html={bannerTopLeftText} />
              </div>
              <div className="col-4 d-lg-block text-center couponcode">
                <span className="d-block">{t('VOUCHER_CODE')}</span>
                <span className="font-weight-semi-bold">{addToCartCoupon}</span>
              </div>
              <div className="col-4 d-lg-block">
                <RawHtml className="d-block" html={bannerTopRightText} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { default as bannerTopVariants } from './bannerTopVariants'
