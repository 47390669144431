import React from 'react'

import ProductLink from '../../../Link/ProductLink'

export default function CategoryItem(props) {
  const { url, category_title: name } = props.itemData
  const imageUrl = '/assets/images/tags_20.png'
  return (
    <li className="search__results-item">
      <ProductLink className="header__search-results-item-link" href={url}>
        <img className="d-block" src={imageUrl} alt="category-icon" />
        <div className="header__search-results-item-info">
          <div className="header__search-results-item-name">{name}</div>
        </div>
      </ProductLink>
    </li>
  )
}
