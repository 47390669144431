const normalizeMenuData = (item, language) => {
  const { children } = item
  if (!children) {
    return null
  }
  return children.map((child) => {
    const out = {
      label: child.text[language],
      href: child.link[language],
    }
    if (child.children?.length) {
      out.items = normalizeMenuData(child, language)
    }
    return out
  })
}

export default normalizeMenuData
