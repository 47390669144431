import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router'
import classNames from 'classnames'

import {
  useCartContent,
  useTranslation,
  setCartWidgetOffset,
} from '../../../../utils'

import { useIcons } from '../IconProvider'
import HeaderAction from './HeaderAction'

function CartButton({ showLabel, showAsBubble }) {
  const { t } = useTranslation()
  const { items } = useCartContent()
  const getIcon = useIcons()

  const totalItemCount = items?.reduce((count, item) => count + item.qty, 0)

  const className = classNames('header__action-cart', {
    'customer-cart': showAsBubble, // Selector for GTMs dynamic chatbot positions
  })

  useEffect(() => {
    const resetCartWidgetOffset = () => setCartWidgetOffset(0)
    Router.events.on('beforeHistoryChange', resetCartWidgetOffset)
    return () => {
      Router.events.off('beforeHistoryChange', resetCartWidgetOffset)
    }
  }, [showAsBubble])

  if (showAsBubble && !totalItemCount) {
    return null
  }

  return (
    <HeaderAction
      symbol={getIcon('actionCart')}
      label={t('HEADER_CART')}
      showLabel={showLabel}
      href="/checkout/cart"
      showAsBubble={showAsBubble}
      className={className}
    >
      {totalItemCount > 0 && (
        <span className="header__cart-badge">{totalItemCount}</span>
      )}
    </HeaderAction>
  )
}

CartButton.protoTypes = {
  showLabel: PropTypes.bool,
  showAsBubble: PropTypes.bool,
}

CartButton.defaultProps = {
  showLabel: true,
  showAsBubble: false,
}

export default CartButton
