import React from 'react'
import classNames from 'classnames'
import { Icon, ConditionalLink } from '../../index'

function Button(props) {
  const {
    variant = 'secondary',
    className = '',
    icon = '',
    iconPosition = 'right',
    href = '',
    children,
    btnSize = '',
    type = 'button',
    ...more
  } = props

  const classes = classNames(className, 'btn', {
    [`btn-${variant}`]: variant,
    [`btn-icon`]: icon,
    [`btn-icon-${iconPosition}`]: icon && iconPosition && children,
    [`btn-${btnSize}`]: btnSize,
  })

  // Separate check to properly render disabled link-buttons
  const { disabled, ...less } = more
  if (disabled) {
    return (
      <button className={classes} type={type} disabled={disabled} {...less}>
        <span className="button__text">{children}</span>

        {icon && <Icon symbol={icon} />}
      </button>
    )
  }

  return (
    <ConditionalLink
      href={href}
      fallbackElement="button"
      className={classes}
      type={type}
      {...more}
    >
      {children && <span className="button__text">{children}</span>}

      {icon && <Icon symbol={icon} />}
    </ConditionalLink>
  )
}

export default Button
export { default as buttonVariants } from './variants.js'
