import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  dispatchShowOverlayEvent,
  dispatchHideOverlayEvent,
  useTranslation,
} from '../../../utils'
import { Icon, InlineButton } from '../../index'

let isLocked = false // FIXME: <Overlay /> doesn't support this yet

/**
 * A generic popup.
 *
 * Accessibility note: It's a good idea to focus an element within the popup
 * when showing it. The previous focus will be restored when the popup is
 * closed. Because the popup is rendered outside the main content area the
 * tab-index moves to a different part of the DOM when focusing something
 * within the popup.
 *
 * @param {string} className - Additional classes for the popup container element
 * @param {function} closeAction - Usually a function that sets the 'isVisible' flag to 'false'
 * @param {boolean} [isVisible=true] - A flag that indicates whether the popup shall be visible or not
 * @param {number} [autoCloseTimeout] - When given, automatically closes the popup after the given time in milliseconds
 * @param {string} [htmlId] - An optional ID attribute
 * @param {any} children - The popup content elements
 * @param {Object} rest - Additional attributes for the popup container element
 * @returns {React.ReactPortal|JSX.Element|null}
 * @constructor
 */
export default function CenterPopup({
  className,
  closeAction,
  autoCloseTimeout,
  isVisible = true,
  htmlId,
  children,
  ...rest
}) {
  const { t } = useTranslation()

  // Restore focus to this element when closing the popup
  const lastFocusElement =
    typeof document !== 'undefined' ? document.activeElement : null

  const handleClose = () => {
    if (typeof closeAction === 'function') {
      closeAction()
    }
    dispatchHideOverlayEvent()
  }

  const handleKeyUp = (evt) => {
    if (evt.key === 'Escape' && !isLocked) {
      handleClose()
    }
  }

  const handleLock = () => {
    window.removeEventListener('overlay:clicked', handleClose)
    isLocked = true
  }
  const handleUnlock = () => {
    window.addEventListener('overlay:clicked', handleClose)
    isLocked = false
  }

  useEffect(() => {
    dispatchShowOverlayEvent()
    window.addEventListener('overlay:clicked', handleClose)
    window.addEventListener('popup:lock', handleLock)
    window.addEventListener('popup:unlock', handleUnlock)
    window.addEventListener('keyup', handleKeyUp)

    let timer
    if (autoCloseTimeout) {
      timer = setTimeout(handleClose, autoCloseTimeout)
    }

    return () => {
      dispatchHideOverlayEvent()
      window.removeEventListener('overlay:clicked', handleClose)
      window.removeEventListener('popup:lock', handleLock)
      window.removeEventListener('popup:unlock', handleUnlock)
      window.removeEventListener('keyup', handleKeyUp)

      if (timer) {
        clearTimeout(timer)
      }
      handleUnlock()

      // Restore previous focus
      lastFocusElement?.focus?.()
    }
  }, [])

  if (!isVisible) {
    return null
  }

  const containerClass = classNames('center-popup shadow-sm', className)
  const content = (
    <div
      className={containerClass}
      id={htmlId}
      role="dialog"
      aria-modal={true}
      {...rest}
    >
      <InlineButton
        className="center-popup__close-button"
        onClick={handleClose}
        title={t('CLOSE_WINDOW')}
        aria-label={t('CLOSE_WINDOW')}
      >
        <Icon symbol="cf-close-light" />
      </InlineButton>
      {children}
    </div>
  )

  const portalNode =
    typeof window !== 'undefined' ? document.getElementById('modal-root') : null

  if (portalNode) {
    return createPortal(content, portalNode)
  }
  return content
}

CenterPopup.propTypes = {
  htmlId: PropTypes.string,
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  autoCloseTimeout: PropTypes.number,
  closeAction: PropTypes.func,
}

export { default as PopupOverlay } from './PopupOverlay'
