import { pushDataLayer } from './dataLayer'

/**
 * Tracks a register event and sends data to the dataLayer
 */
function trackRegister() {
  pushDataLayer({
    dataLayer: {
      event: 'register',
    },
  })
}

export default trackRegister
