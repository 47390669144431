import React from 'react'

import { useCustomer, useFeatureFlags, useTranslation } from '../../../../utils'
import { dispatchLoginPopupShowEvent } from '../../LoginPopup'

import { useIcons } from '../IconProvider'
import {
  FlyoutHeading,
  FlyoutItem,
  FlyoutMenu,
  FlyoutSection,
} from './FlyoutMenu'
import PortalWrapper from './PortalWrapper'
import { normalizeMenuData, useMenuListener } from './utils'

function AccountNavigationFlyout({ id, menuData }) {
  const { t, language } = useTranslation()
  const { fullName, isLoggedIn, isAboGroup } = useCustomer()
  const { isFeatureEnabled } = useFeatureFlags()
  const [isVisible, setVisible] = useMenuListener(['navigation', 'account'])
  const getIcon = useIcons()

  const flyoutItems = [
    {
      label: t('ACCOUNT_MENU_DASHBOARD'),
      href: '/customer/account',
    },
    {
      label: t('ACCOUNT_MENU_INFORMATION'),
      href: '/customer/account/edit',
    },
    {
      label: t('ACCOUNT_MENU_ORDERS'),
      href: '/sales/order/history',
    },
    {
      label: t('ACCOUNT_MENU_MY_INTERVALS'),
      href: '/cartUpdate/abodelivery/view',
      hideItem: !isFeatureEnabled('abo') || !isAboGroup,
    },
    {
      label: t('ACCOUNT_MENU_CONTACT'),
      href: '/customer/account/contact',
    },
    {
      label: t('ACCOUNT_MENU_LOGOUT'),
      href: '/customer/account/logout',
      endIcon: getIcon('accountMenuLogout'),
    },
  ]

  const primary =
    normalizeMenuData(
      menuData.find((m) => m.text[language] === 'PRIMARY_NAVIGATION') || {},
      language
    ) || []
  const secondary =
    normalizeMenuData(
      menuData.find((m) => m.text[language] === 'SECONDARY_NAVIGATION') || {},
      language
    ) || []

  return (
    <PortalWrapper query="#modal-root">
      <FlyoutMenu id={id} isVisible={isVisible} setVisible={setVisible}>
        <FlyoutSection className="bg-primary" label={t('HEADER_ACCOUNT_AREA')}>
          {isLoggedIn ? (
            <FlyoutHeading
              label={fullName}
              startIcon={getIcon('actionAccountLoggedIn')}
            >
              {flyoutItems.map((item) =>
                item.hideItem ? null : (
                  <FlyoutItem
                    key={`${item.href}_${item.label}`}
                    variant="primary"
                    {...item}
                  />
                )
              )}
            </FlyoutHeading>
          ) : (
            <FlyoutHeading
              label={t('HEADER_LOGIN')}
              endIcon={null}
              onClick={dispatchLoginPopupShowEvent}
              startIcon={getIcon('accountMenuLogin')}
              actionClasses="justify-content-start"
            />
          )}
        </FlyoutSection>
        <FlyoutSection label={t('HEADER_NAVIGATION')}>
          {primary?.map((item, index) => (
            <FlyoutItem key={index} {...item} />
          ))}
          {secondary?.map((item, index) => (
            <FlyoutItem key={index} {...item} />
          ))}
        </FlyoutSection>
      </FlyoutMenu>
    </PortalWrapper>
  )
}

export default AccountNavigationFlyout
