import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { RawHtml } from '../../index'
import { renderGlobalVariables, useGlobalVariables } from '../../../utils'

/**
 * @deprecated - Use TextEditor instead
 */
function RichContent({ richText, bgColor, align }) {
  const globalVars = useGlobalVariables()

  const classes = classNames({
    [`bg-${bgColor}`]: bgColor,
    ['p-3']: bgColor === 'gray-4' || bgColor === 'gray-6',
    ['text-white']: bgColor === 'gray-6',
    [`text-${align}`]: align,
  })

  const output = renderGlobalVariables(richText, globalVars)

  return (
    <section className="container rich-content mb-4">
      <RawHtml element="div" className={classes} html={output} />
    </section>
  )
}

RichContent.propTypes = {
  richText: PropTypes.string,
  bgColor: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right']),
}

RichContent.defaultProps = {
  richText: null,
  bgColor: null,
  align: null,
}

export default RichContent
