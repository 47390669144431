import React from 'react'
import Slider from './Slider'
import ConditionalLink from '../../core/ConditionalLink'
import classNames from 'classnames'

export default function DesktopView(props) {
  const { subcategories = [], isFullWidth = false } = props
  const containerClasses = classNames('position-relative mb-4', {
    container: !isFullWidth,
  })
  return (
    <div className={containerClasses}>
      <Slider>
        {subcategories.map((subcategory, key) => (
          <ConditionalLink
            key={key}
            className="child font-weight-semi-bold"
            href={subcategory.catLink}
          >
            {subcategory.catName}
          </ConditionalLink>
        ))}
      </Slider>
    </div>
  )
}
