import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function InlineButton({ className, children, ...rest }) {
  const buttonClasses = classNames('btn-inline', className)
  return (
    <button type="button" className={buttonClasses} {...rest}>
      {children}
    </button>
  )
}

InlineButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}
