import React, { useContext } from 'react'
import { useRouter } from 'next/router'

import {
  CustomCouponingContext,
  ListPagePricingProvider,
  PricingContext,
  useFeatureFlags,
  useGlobalData,
  useSnippetContent,
} from '../../../../utils/providers'
import { PriceTypes } from '../../../../utils'
import MageBirdPopup from '../../MagebirdPopup'

import CustomCouponBanner from './CustomCouponing'
import BannerTop from './BannerTop'
import Topbar from './Topbar'

const excludeUrls = [
  '/customer/account/logout',
  '/checkout/onepage/success',
  '/checkout/shopify/success',
  '/paypal/express/review',
  '/payment/addresscorrection',
  '/404',
]

const PricingWrapper = (props) => {
  // PDPs can show a "CustomCoupon" banner, for which we need product data to extract price attributes from
  const { pageData } = useGlobalData()
  const productData =
    pageData?.type === 'makaira-productgroup' ? { ...pageData.data.self } : null

  return (
    <ListPagePricingProvider productData={productData}>
      <Banners {...props} />
    </ListPagePricingProvider>
  )
}

const Banners = ({ colorScheme }) => {
  const context = useContext(PricingContext)
  const customCouponingContext = useContext(CustomCouponingContext)
  const { statusCode } = useGlobalData() || {}
  const { isFeatureEnabled } = useFeatureFlags()
  const topbarProps = useSnippetContent('topbar_snippet')
  const router = useRouter() || {}
  const checkUrls = (urls) => urls.every((url) => url !== router.query?.seoUrl)

  if (statusCode === 404 || !checkUrls(excludeUrls)) {
    return null
  }

  let BannerComponent
  let bannerProps

  if (context) {
    const {
      customCouponingContext: { addToCartCoupon, ccBanner },
      pricingContext: { priceType, specialPrice },
    } = context

    if (priceType === PriceTypes.PRICE_COUPON && specialPrice) {
      BannerComponent = CustomCouponBanner
      bannerProps = {
        customCouponPrice: specialPrice,
        couponCode: addToCartCoupon,
        banner: ccBanner,
      }
    }
  }

  if (!BannerComponent && customCouponingContext) {
    const { addToCartCoupon, bannerTopLeftText, bannerTopRightText } =
      customCouponingContext

    if (bannerTopLeftText || bannerTopRightText) {
      BannerComponent = BannerTop
      bannerProps = {
        bannerTopLeftText,
        bannerTopRightText,
        addToCartCoupon,
      }
    }
  }

  // Show "Topbar" only when there is no other banner
  if (
    !BannerComponent &&
    !!topbarProps &&
    router.query?.seoUrl !== '/checkout/cart'
  ) {
    BannerComponent = Topbar
    bannerProps = {
      colorScheme,
      ...topbarProps,
    }
  }

  return (
    <>
      {isFeatureEnabled('magebird') && <MageBirdPopup />}
      {BannerComponent && <BannerComponent {...bannerProps} />}
    </>
  )
}

export default PricingWrapper
