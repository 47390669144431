import { getProductPrice } from '../../index'

import { pushDataLayer, clearGA4Data } from './dataLayer'

function trackQuickview(productData) {
  clearGA4Data()
  const price = getProductPrice(productData.priceinformation[0])
  pushDataLayer({
    dataLayer: {
      event: 'quickView',
      ga4: {
        currencyCode: process.env.CURRENCY,
        totalValue: price,
        items: [
          {
            item_id: productData.sku,
            item_name: productData.title,
            item_variant: productData.config_variable_a,
            price: price,
          },
        ],
      },
    },
  })
}

export default trackQuickview
