import React, { Component } from 'react'
import { TranslationComponent, formatPrice } from '../../../../../utils'

export default class CustomCouponBanner extends Component {
  render() {
    const customCouponPriceHtml = formatPrice(this.props.customCouponPrice)
    return (
      <div id="customcouponing_container_react">
        <div>
          <div className="customcoupon-container py-2">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4 text-center">
                  <span className="d-inline-block font-weight-semi-bold percent-discount">
                    <TranslationComponent
                      text="SPECIAL_PRICE"
                      args={customCouponPriceHtml}
                    />
                  </span>
                  <span className="font-size-xxs pl-3 d-lg-none more-details">
                    <TranslationComponent text="MORE_DETAILS" />
                  </span>
                </div>
                <div className="col-12 col-lg-4 d-none d-lg-block text-center couponcode">
                  <span className="d-block">
                    <TranslationComponent text="VOUCHER_CODE" />
                  </span>
                  <span className="font-weight-semi-bold">
                    {this.props.couponCode}
                  </span>
                </div>
                <div className="col-12 col-lg-4 d-none d-lg-block">
                  <span className="d-block">{this.props.banner}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { default as customCouponBannerVariants } from './customCouponBannerVariants'
