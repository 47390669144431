class CaptchaError extends Error {
  constructor(message) {
    super(message)
    this.name = this.constructor.name
  }
}

export { CaptchaError }

/**
 * Fetch a reCaptcha token.
 *
 * **Important**: When using this function manually, make sure to check if the
 * 'captcha' feature flag is enabled before calling it.
 *
 * @param {string} actionName - The reCaptcha action name that is associated with a backend request
 * @returns {Promise<unknown>}
 */
export default function validateCaptcha(actionName) {
  if (!actionName) {
    throw TypeError('Captcha: Missing action name')
  }

  return new Promise((resolve, reject) => {
    const isConsentGiven = () => {
      let consentGiven = false

      // 'dataLayer' is set by GTM
      if (typeof dataLayer !== 'undefined') {
        // eslint-disable-next-line no-undef
        consentGiven = dataLayer.some((data) => data['reCAPTCHA'] === true)
      }
      return consentGiven
    }

    if (!isConsentGiven()) {
      reject(new CaptchaError('Consent not given!'))
    } else if (typeof grecaptcha !== 'undefined') {
      // 'grecaptcha' is set by the recaptcha library
      // eslint-disable-next-line no-undef
      grecaptcha.ready(() => {
        // eslint-disable-next-line no-undef
        grecaptcha
          .execute(process.env.RECAPTCHA_SITEKEY, { action: actionName })
          .then((token) => {
            resolve(token)
          })
          .catch((error) => {
            reject(error || new CaptchaError('Failed to get captcha response'))
          })
      })
    } else {
      reject(new CaptchaError('grecaptcha is not available!'))
    }
  })
}
