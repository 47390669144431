import React, { Component } from 'react'
import { getFullUrl } from '../../../utils'
import PortalWrapper from './PortalWrapper'

export default class MageBirdPopup extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.loadPopup()
  }

  appendScript = (scriptToAppend) => {
    const script = document.createElement('script')
    script.src = scriptToAppend
    script.async = true
    document.body.appendChild(script)
  }
  appendCss = (cssFile) => {
    const script = document.createElement('link')
    script.href = cssFile
    script.rel = 'stylesheet'
    script.type = 'text/css'
    document.body.appendChild(script)
  }

  buildMbParams() {
    return {
      storeId: '2',
      previewId: 0,
      templateId: 0,
      popupPageId: 2,
      filterId: '0',
      isAjax: '1',
      page: '1',
      requestType: '3',
      rootUrl: process.env.MAGENTO_DOMAIN,
      baseUrl: process.env.MAGENTO_DOMAIN,
      ajaxAsync: 'true',
      doGaTracking: 'true',
      doMousetracking: '0',
      bc: process.env.CURRENCY,
      cc: process.env.CURRENCY,
      cs: process.env.CURRENCYSYMBOL,
      cf: '1,00 ' + process.env.CURRENCYSYMBOL,
      customParams: '',
    }
  }

  loadPopup = async () => {
    window.mbPopupParams = this.buildMbParams()
    const basePath = '/skin/frontend/base/default'
    const scripts = [
      `${basePath}/js/magebird_popup/jquery.js`,
      `${basePath}/js/magebird_popup/main.js?v=1.6.2`,
    ]
    const styles = [`${basePath}/css/magebird_popup/style_v148.css`]
    scripts.map((s) => {
      const { fullUrl } = getFullUrl(s)
      this.appendScript(fullUrl)
    })
    styles.map((s) => {
      const { fullUrl } = getFullUrl(s)
      this.appendCss(fullUrl)
    })
  }

  render() {
    return <PortalWrapper />
  }
}
