import { createPortal } from 'react-dom'

function PortalWrapper({ query, children }) {
  const q = query || '.header__menu'
  const portalNode =
    typeof document !== 'undefined' ? document.querySelector(q) : null

  // TODO: avoid flickering when the portal node is not there yet.
  // See https://stackoverflow.com/questions/53949942/is-it-safe-to-render-a-react-portal-into-another-component-dom
  // See https://stackoverflow.com/questions/55189388/use-react-portal-to-render-a-child-into-a-dom-node-of-the-parent-component
  if (!portalNode) {
    return null
  }
  return createPortal(children, portalNode)
}

export default PortalWrapper
