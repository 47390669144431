import React from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'

import { extendCarouselBreakpoints, useCarouselSettings } from '../../../utils'
import Carousel from '../Carousel'

import ProductCard from './ProductCard'

const responsiveLayout = {
  desktop: {
    breakpoint: { max: Number.MAX_VALUE, min: 1200 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1199.98, min: 1024 },
    items: 3,
  },
  mobileM: {
    breakpoint: { max: 1023.98, min: 568 },
    items: 2,
  },
  mobileS: {
    breakpoint: { max: 567.98, min: 160 },
    items: 1,
  },
}

const productCardWidth = 280

const ProductPlacementExtendedContainer = ({
  isFullWidth,
  products,
  renderButtonGroupOutside,
  customButtonGroup,
  afterSlideChange,
  couponCodes,
  productRatings,
  badges,
  ...props
}) => {
  const carouselSettings = useCarouselSettings('product-placement-extended')

  const containerClass = classNames(
    'm-auto',
    products.length < 4 &&
      'react-multi-carousel-center product-placement-extended__list'
  )

  const productCardClass = classNames(
    products.length === 1 && 'justify-content-lg-center'
  )

  if (products.length === 0) {
    return null
  }

  const className = classNames(
    'product-placement-extended mb-4',
    isFullWidth || 'container'
  )

  let breakpoints = responsiveLayout
  if (isFullWidth) {
    breakpoints = extendCarouselBreakpoints(
      responsiveLayout,
      products.length,
      productCardWidth
    )
  }

  return (
    <section className={className}>
      <div
        className={containerClass}
        // hack for react-multi-carousel lib to narrow the cards
        {...(products.length > 1
          ? {
              style: {
                maxWidth: `${products.length * productCardWidth}px`,
              },
            }
          : {})}
      >
        <Carousel
          responsive={breakpoints}
          className={productCardClass}
          customButtonGroup={customButtonGroup}
          renderButtonGroupOutside={renderButtonGroupOutside}
          afterChange={afterSlideChange}
          {...carouselSettings}
        >
          {products.map((product, key) => {
            const itemRating = productRatings?.[product.id] || null

            return (
              <ProductCard
                ratingSummary={itemRating}
                key={`card_${product.id}_${key}`}
                productData={product}
                data-selector={`ri-${product.id}`}
                sku={product.sku}
                couponCode={couponCodes[key]}
                badge={badges[key]}
                {...props}
              />
            )
          })}
        </Carousel>
      </div>
    </section>
  )
}

ProductPlacementExtendedContainer.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  renderButtonGroupOutside: PropTypes.bool,
  customButtonGroup: PropTypes.node,
  afterSlideChange: PropTypes.func,
  couponCodes: PropTypes.arrayOf(
    PropTypes.shape({
      couponCode: PropTypes.string,
      couponCodeLabel: PropTypes.string,
    })
  ),
  badges: PropTypes.arrayOf(PropTypes.string),
}

ProductPlacementExtendedContainer.defaultProps = {
  products: [],
  renderButtonGroupOutside: false,
  customButtonGroup: null,
  afterSlideChange: null,
  couponCodes: [],
  badges: [],
}

export default ProductPlacementExtendedContainer
