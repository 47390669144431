import React from 'react'
import PropTypes from 'prop-types'

import { TranslationComponent } from '../../../../../utils'
import ResultSection from './ResultSection'
import ResultSectionType from './ResultSectionType'

class Results extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: true,
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.closeOnClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeOnClick)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.searchTerm !== prevProps.searchTerm &&
      this.props.searchTerm !== ''
    ) {
      this.setState({ isVisible: true })
    }
  }

  // We want to close the autoSuggestion box whenever a click is done
  closeOnClick = () => {
    this.setState({ isVisible: false })
  }

  render() {
    const { searchResults } = this.props
    const { isVisible } = this.state

    if (!(isVisible && searchResults)) {
      return null
    }

    const sections = [
      {
        type: ResultSectionType.CATEGORY,
        data: searchResults.category,
      },
      {
        type: ResultSectionType.PRODUCT,
        data: searchResults.product,
      },
      /*{ // disabled for now
        type: ResultSectionType.LINK,
        data: searchResults.links
      },*/
    ]
    const resultCount = sections.reduce((totalCount, currentSection) => {
      return totalCount + (currentSection.data?.count || 0)
    }, 0)

    return (
      <div className="header__search-results">
        {resultCount === 0 && (
          <em className="d-block px-2 py-1">
            <TranslationComponent text="SEARCH_NO_RESULTS" />
          </em>
        )}
        {resultCount > 0 &&
          sections.map((section) => {
            const { type, data } = section
            return <ResultSection key={type} type={type} data={data} />
          })}
      </div>
    )
  }
}

Results.propTypes = {
  searchResults: PropTypes.object,
}

Results.defaultProps = {
  searchResults: [],
}

export default Results
