import React, { useContext } from 'react'
import classNames from 'classnames'

import { useTranslation } from '../../../../utils/providers'

import { BundleContext } from '../BundleProvider'
import OptionWrapper from '../OptionWrapper'

export default function SelectableOptions({
  currentProductId,
  currentSelectedBundle,
  isDisabled = false,
  showValidationError,
}) {
  const { t } = useTranslation()
  const {
    setSelectedOptions,
    selectedOptions,
    options: bundleOptions,
  } = useContext(BundleContext)

  const onChange = (event) => {
    const { name, value } = event.target
    setSelectedOptions(name, value)
  }

  return bundleOptions.map((option, index) => {
    const selections = option.selections
    const isErrorVisible =
      showValidationError &&
      option.errorVisible &&
      currentProductId === currentSelectedBundle
    const selectClassNames = classNames(
      'form-control bundle-change',
      isErrorVisible && 'border-danger'
    )

    return (
      <OptionWrapper key={`${index}-${option.id}`} title={option.title}>
        <div className="select-box">
          <select
            name={option.id}
            className={selectClassNames}
            onChange={onChange}
            disabled={isDisabled}
            value={selectedOptions[option.id] || ''}
          >
            <option value="">{t('SELECT_BOX_DEFAULT_OPTION')}</option>
            {selections.map(({ id: selectionId, name }, idx) => (
              <option
                value={selectionId}
                key={`${idx}-${option.id}-${selectionId}`}
              >
                {name}
              </option>
            ))}
          </select>
        </div>
        {isErrorVisible && (
          <div className="validation-advice text-danger">
            {t('THIS_IS_A_REQUIRED_FIELD')}
          </div>
        )}
      </OptionWrapper>
    )
  })
}
