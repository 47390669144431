import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { FormStatus } from '../../../utils'
import {
  useFeatureFlags,
  useMediaResize,
  useTranslation,
} from '../../../utils/providers'
import { AlertBox, AlertBoxTypes, Image } from '../../index'

import NewsletterForm from './NewsletterForm'

function PlaceableNewsletter({
  identifier,
  anchor,
  appearance: {
    isFullWidth,
    background,
    borderStyle = 'angled',
    paddingTop,
    paddingBottom,
  } = {},
  infobox: {
    headline,
    headlineFont,
    headlineColor,
    paragraph,
    icon,
    align: infoboxAlign = { desktop: 'left', tablet: 'left', mobile: 'center' },
  } = {},
  form: {
    order,
    placeholder,
    privacyPolicyUrl,
    submitButtonPlacement,
    interests = [],
    interestButtonSize,
    align: formAlign = { desktop: 'left', tablet: 'left', mobile: 'center' },
    isCrossengage,
    privacyPolicyText,
    hidePrivacyPolicy,
  } = {},
}) {
  const { isFeatureEnabled } = useFeatureFlags()
  const { t } = useTranslation()
  const {
    breakpoints: { downLG, downMD, upMD },
  } = useMediaResize()
  const [version, setVersion] = useState('desktop')
  const [submitStatus, setSubmitStatus] = useState(FormStatus.NOT_SUBMITTED)

  useEffect(() => {
    let version = 'desktop'
    if (downMD) {
      version = 'mobile'
    } else if (upMD && downLG) {
      version = 'tablet'
    }
    setVersion(version)
  }, [downLG, downMD, upMD])

  if (!isFeatureEnabled('newsletter')) {
    return <AlertBox>{t('NEWSLETTER_NOT_SUPPORTED')}</AlertBox>
  }

  const showInfobox = headline || icon || paragraph

  const wrapperClasses = classNames('newsletter', {
    [`bg-${background}`]: !!background,
    'text-white': background === 'logo-color',
    container: !isFullWidth,
    [`pt-${paddingTop}`]: !!paddingTop,
    [`pb-${paddingBottom}`]: !!paddingBottom,
  })
  const headlineClasses = classNames('newsletter__headline', {
    [`font-family-${headlineFont}`]: !!headlineFont,
    [`text-${headlineColor}`]: !!headlineColor,
    'mt-3': !!icon,
  })
  const paragraphClasses = headline || icon ? 'mt-3' : null

  return (
    <div className={wrapperClasses} id={anchor}>
      <div className="container row m-auto justify-content-center">
        {showInfobox && (
          <div className={`col-12 col-lg-6 text-${infoboxAlign[version]}`}>
            {icon && (
              <Image
                className="newsletter__icon"
                srcDesktop={icon}
                srcMobile={icon}
              />
            )}
            {headline && <h3 className={headlineClasses}>{headline}</h3>}
            {paragraph && <p className={paragraphClasses}>{paragraph}</p>}
          </div>
        )}
        <div
          className={`col-12 col-lg-6 align-self-center text-${formAlign[version]}`}
        >
          <NewsletterForm
            isCrossengage={isCrossengage}
            identifier={`placeable_newsletter_${identifier}`}
            privacyPolicyUrl={privacyPolicyUrl}
            placeholder={placeholder || t('NEWSLETTER_DEFAULT_PLACEHOLDER')}
            interestList={interests}
            interestButtonSize={interestButtonSize}
            onSubmitStatusChange={setSubmitStatus}
            submitButtonPlacement={submitButtonPlacement}
            borderStyle={borderStyle}
            order={order}
            privacyPolicyText={privacyPolicyText}
            hidePrivacyPolicy={hidePrivacyPolicy}
          />
        </div>
        {submitStatus === FormStatus.SUCCESS && (
          <AlertBox type={AlertBoxTypes.SUCCESS} className="w-100">
            {t('NEWSLETTER_SUBSCRIPTION_SUCCESS')}
          </AlertBox>
        )}
        {submitStatus === FormStatus.ERROR && (
          <AlertBox type={AlertBoxTypes.ERROR} className="w-100">
            {t('NEWSLETTER_SUBSCRIPTION_ERROR')}
          </AlertBox>
        )}
      </div>
    </div>
  )
}

PlaceableNewsletter.propTypes = {
  identifier: PropTypes.string,
  anchor: PropTypes.string,
  appearance: PropTypes.shape({
    isFullWidth: PropTypes.bool,
    background: PropTypes.string,
    borderStyle: PropTypes.string,
    paddingTop: PropTypes.oneOf(['1', '2', '3', '4']),
    paddingBottom: PropTypes.oneOf(['1', '2', '3', '4']),
  }),
  infobox: PropTypes.shape({
    align: PropTypes.shape({
      desktop: PropTypes.oneOf(['left', 'center', 'right']),
      tablet: PropTypes.oneOf(['left', 'center', 'right']),
      mobile: PropTypes.oneOf(['left', 'center', 'right']),
    }),
    headline: PropTypes.string,
    headlineFont: PropTypes.oneOf([
      'OpenSans',
      'DancingScript',
      'Bitter',
      'Mansalva',
      'Roboto',
      'RobotoSlab',
      'SourceSansPro',
      'Gotcha',
    ]),
    headlineColor: PropTypes.oneOf(['primary', 'logo-color', 'dark']),
    paragraph: PropTypes.string,
    icon: PropTypes.string,
  }),
  form: PropTypes.shape({
    align: PropTypes.shape({
      desktop: PropTypes.oneOf(['left', 'center', 'right']),
      tablet: PropTypes.oneOf(['left', 'center', 'right']),
      mobile: PropTypes.oneOf(['left', 'center', 'right']),
    }),
    interestButtonSize: PropTypes.oneOf(['large', 'small']),
    submitButtonPlacement: PropTypes.oneOf([
      'integrated',
      'integratedGap',
      'separate',
    ]),
    order: PropTypes.shape({
      interests: PropTypes.number,
      input: PropTypes.number,
      legalNotice: PropTypes.number,
    }),
    placeholder: PropTypes.string,
    privacyPolicyUrl: PropTypes.string.isRequired,
    interests: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        selected: PropTypes.bool,
      })
    ).isRequired,
    isCrossengage: PropTypes.bool,
    privacyPolicyText: PropTypes.string,
  }),
}

PlaceableNewsletter.defaultProps = {
  identifier: 'default',
  anchor: null,
  appearance: {
    isFullWidth: true,
    background: 'bg-light',
    borderStyle: 'angled',
    paddingTop: '2',
    paddingBottom: '2',
  },
  infobox: {
    align: {
      desktop: 'left',
      tablet: 'left',
      mobile: 'center',
    },
    headline: null,
    headlineFont: null,
    headlineColor: null,
    paragraph: null,
    icon: null,
  },
  form: {
    submitButtonPlacement: 'integratedGap',
    interestButtonSize: 'small',
    align: {
      desktop: 'left',
      tablet: 'left',
      mobile: 'center',
    },
    order: {
      interests: 1,
      input: 2,
      legalNotice: 3,
    },
    placeholder: null,
    privacyPolicyUrl: '/',
    interests: [],
    isCrossengage: false,
    privacyPolicyText: '',
  },
}

export default PlaceableNewsletter
