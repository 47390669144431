import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import AlertBoxTypes from './AlertBoxTypes'
import { default as alertBoxVariants } from './variants'

function AlertBox(props) {
  const {
    className,
    children,
    element = 'div',
    type = AlertBoxTypes.ERROR,
  } = props

  if (!children) {
    return null
  }

  const Element = element
  const content = children instanceof Error ? children.toString() : children
  const containerClass = classNames('alert', type, className)

  return <Element className={containerClass}>{content}</Element>
}

AlertBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  type: PropTypes.oneOf(Object.values(AlertBoxTypes)),
}

export { AlertBox, AlertBoxTypes, alertBoxVariants }
