import React, { useEffect } from 'react'
import classNames from 'classnames'

import { useTranslation } from '../../../utils'
import { Button } from '../../index'

let timeout // Needs to be outside because re-rendering the component would reset it

export default function DefaultInfoBox(props) {
  const { message, status, cartRedirect, closePopup } = props
  const { t } = useTranslation()

  const iconClasses = classNames('mr-2', {
    'cf-warning': status !== 'ok',
    'cf-added-cart': status === 'ok',
  })

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(closePopup, 3000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className="default-cart-infobox">
      <i className="cf-close-light" onClick={closePopup} />
      <i className={iconClasses} />
      <p className="d-inline-block">{message}</p>
      {status === 'ok' && (
        <Button
          onClick={cartRedirect}
          variant="outline-primary"
          className="d-block w-100"
        >
          {t('ACCOUNT_MENU_TO_CART')}
        </Button>
      )}
    </div>
  )
}
