import * as Yup from 'yup'

const ProductData = Yup.object().shape({
  id: Yup.number().required(),
  sku: Yup.string().required(),
  parent_sku: Yup.string(),
  ean: Yup.string(),
  name: Yup.string(),
  title: Yup.string(),
  manufacturer_name: Yup.string(),
  longdesc: Yup.string(),
  images: Yup.array().of(Yup.string()),
  small_image: Yup.string(),
  url: Yup.array().of(Yup.string()),
  priceinformation: Yup.array().of(Yup.string()),
  groupedrelations: Yup.array().of(Yup.number()),
  product_type: Yup.array().oneOf(['bundle', 'simple', 'virtual']),
  badge: Yup.string(),
  productart: Yup.string(),
  availability: Yup.boolean(),
  aktionen_apv: Yup.string(),
  produkt_linie: Yup.string(),
  produktgruppe_apv: Yup.string(),
  config_variable_a: Yup.string(),
  tiericon: Yup.string(),
  bundle_data_only: Yup.object().shape({
    options: Yup.array().of(Yup.number()),
  }),
  is_fixed_bundle: Yup.boolean(),
})

export { ProductData }
