import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ConditionalLink } from '../../../index'
import Image from '../../Image'

function Logo({ className, alt, srcDesktop, srcMobile, url }) {
  const srcFallback =
    !srcDesktop && !srcMobile ? '/assets/library/alphapet-logo.png' : null
  const containerClass = classNames('header__logo', className)

  return (
    <div className={containerClass}>
      <ConditionalLink href={url} fallbackElement="span">
        <Image
          srcFull={srcFallback}
          srcDesktop={srcDesktop}
          srcMobile={srcMobile}
          alt={alt}
        />
      </ConditionalLink>
    </div>
  )
}

Logo.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string,
  srcDesktop: PropTypes.string,
  srcMobile: PropTypes.string,
  url: PropTypes.string,
}

Logo.defaultProps = {
  className: null,
  alt: 'Logo',
  srcDesktop: null,
  srcMobile: null,
  url: null,
}

export default Logo
