import React from 'react'
import * as Yup from 'yup'

import { InlineButton } from '../../../index'
import { dispatchUpdateCustomerInfo } from '../../../../utils'
import { useBackendApi, useTranslation } from '../../../../utils/providers'
import { trackLogin } from '../../../../utils/Alphapet/tracking'

import FormBox from '../FormBox'
import { LoginPopupTypes } from '../LoginPopupTypes'

export default function LoginForm({ setMode, closeAction, headline }) {
  const { t } = useTranslation()
  const backendApi = useBackendApi()

  const handleSubmit = async ({ body }) => await backendApi.loginUser(body)

  const handleSuccess = (response) => {
    dispatchUpdateCustomerInfo()
    closeAction()
    trackLogin(response)
  }

  const createFormData = () => ({
    email: {
      name: 'email',
      schema: Yup.string()
        .trim()
        .email(t('FORM_ERROR_WRONG_EMAIL'))
        .required(t('FORM_ERROR_REQ_EMAIL')),
      value: '',
      error: null,
    },
    password: {
      name: 'password',
      schema: Yup.string().trim().required(t('FORM_ERROR_NO_PASSWORD')),
      value: '',
      error: null,
    },
  })

  const createElements = (formData) => [
    {
      type: 'headline',
      label: headline || t('LOGINBOX_LOGIN'),
      upperCase: true,
      className: 'mb-4 text-uppercase',
    },
    {
      type: 'errorMessage',
      className: 'mb-4',
    },
    {
      type: 'email',
      label: t('FORM_LABEL_EMAIL_ADDRESS'),
      className: 'mb-4',
      data: formData.email,
      autoFocus: true,
    },
    {
      type: 'password',
      showConfirm: false,
      className: 'mb-2',
      data: formData.password,
    },
    {
      type: 'forgotPassword',
      label: t('LOGINBOX_FORGOT_PASSWORD'),
      className: 'mb-4',
    },
    {
      type: 'submit',
      label: t('LOGINBOX_LOGIN'),
      className: 'mb-3',
    },
    {
      type: 'successMessage',
    },
    {
      type: 'staticText',
      label: t('LOGINBOX_NO_ACCOUNT_YET')({
        Link: InlineButton,
        props: {
          className: 'text-primary',
          onClick: () => setMode(LoginPopupTypes.REGISTER),
        },
      }),
      className: 'info-box m-0 pt-3',
    },
  ]

  return (
    <FormBox
      className="login-box"
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      createFormData={createFormData}
      createElements={createElements}
      setMode={setMode}
    />
  )
}
