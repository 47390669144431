import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withFeatureFlags } from '../FeatureFlagProvider'

import { CustomCouponingContext } from './CustomCouponingProvider'
import { PriceTypes } from '../PriceTypes'

const InternalPricingContext = React.createContext()

class InternalPriceCalculator extends Component {
  static contextType = CustomCouponingContext

  constructor(props) {
    super(props)
    this.state = {
      isAbo: false,
    }
  }

  componentDidUpdate(prevProps) {
    const oldProductId = prevProps.productData.id
    const newProductId = this.props.productData.id
    if (oldProductId !== newProductId) {
      this.setState({ isAbo: false })
    }
  }

  setAbo = (isAbo) => {
    if (this.props.isFeatureEnabled('abo')) {
      this.setState({ isAbo })
    }
  }

  getCouponPrice(product, priceAttribute) {
    let couponPrice = null
    if (product) {
      const ccPrices = product.cc_prices
      if (ccPrices !== undefined && priceAttribute in ccPrices) {
        couponPrice = ccPrices[priceAttribute]
      }
    }
    return couponPrice
  }

  /**
   * Accessor function to return the discount price of a given product when selected as a subscription.
   * The default is to return 'priceinformation[0].abo_price'. Can be overriden by providing a 'getAboPrice' prop.
   * This function is exposed in the consumer.
   *
   * @param {Object} productData
   */
  getAboPrice = (productData) => {
    const { getAboPrice } = this.props
    return (
      getAboPrice?.(productData) || productData.priceinformation[0].abo_price
    )
  }

  render() {
    const customCouponingContext = this.context
    const { isFeatureEnabled, productData, children } = this.props

    const ccPriceAttribute = customCouponingContext?.ccPriceAttribute
    const priceData = productData.priceinformation[0]
    // Calculate Normal Price:
    let priceType = PriceTypes.PRICE_NORMAL
    let specialPrice = priceData.special
    let regularPrice = priceData.regular
    // Calculate Abo-Price:
    if (isFeatureEnabled('abo') && this.state.isAbo === true) {
      priceType = PriceTypes.PRICE_ABO
      regularPrice = priceData.regular ? priceData.regular : priceData.special
      specialPrice = this.getAboPrice(productData)
    } else {
      // Calculate coupon Price:
      let couponPrice = this.getCouponPrice(productData, ccPriceAttribute)
      if (couponPrice) {
        priceType = PriceTypes.PRICE_COUPON
        specialPrice = couponPrice
        regularPrice = priceData.regular
      }
    }
    // Return everything back:
    const providerObject = {
      priceType,
      specialPrice,
      regularPrice,
      setAbo: this.setAbo,
      isAbo: this.state.isAbo,
      getAboPrice: this.getAboPrice,
    }
    return (
      <InternalPricingContext.Provider value={providerObject}>
        {children}
      </InternalPricingContext.Provider>
    )
  }
}

const Wrapped = withFeatureFlags(InternalPriceCalculator)

Wrapped.propTypes = {
  children: PropTypes.any,
  /**
   * The product on which to perform price calculations.
   */
  productData: PropTypes.object,
  /**
   * Accessor function to return the discount price of a given product when selected as a subscription.
   * If this function is not specified here, the default is to return 'priceinformation[0].abo_price'.
   * This function (or its default implementation) is exposed to the consumer.
   *
   * @param {Object} productData
   * @return {number}
   */
  getAboPrice: PropTypes.func,
}

export { InternalPricingContext, Wrapped as InternalPriceCalculator }
