import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function FlyoutSection({ className, label, children }) {
  const ulClasses = classNames('header__flyout_section', className)
  return (
    <nav aria-label={label}>
      <ul className={ulClasses}>{children}</ul>
    </nav>
  )
}

FlyoutSection.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.any,
}

FlyoutSection.defaultProps = {
  className: null,
  label: null,
  children: null,
}

export default FlyoutSection
