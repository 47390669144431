import * as Yup from 'yup'

import { StatusData } from './status'

const UserData = Yup.object()
  .shape({
    fullName: Yup.string().required().nullable(),
    mailSha256: Yup.string().optional(),
    mailSha512: Yup.string().optional(),
    postcode: Yup.string().optional(),
    isAboGroup: Yup.boolean().optional(),
  })
  .concat(StatusData)

const LoginData = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required(),
})

const RegisterData = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string().required(),
})

const NewPasswordData = Yup.object().shape({
  password: Yup.string().required(),
  confirmPassword: Yup.string().required(),
  id: Yup.string().optional(),
  resetToken: Yup.string().optional(),
})

const ForgotPasswordData = Yup.object().shape({
  email: Yup.string().email().required(),
})

const Birthday = Yup.object().shape({
  day: Yup.string().required(),
  month: Yup.string().required(),
  year: Yup.string().required(),
})

const CustomerAccountData = Yup.object()
  .shape({
    customerData: Yup.object().shape({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      email: Yup.string().email().required(),
      birthday: Birthday.optional(),
      isSubscribed: Yup.boolean().optional(),
    }),
  })
  .concat(StatusData)

const SetCustomerAccountData = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  changePassword: Yup.boolean().optional(),
  password: Yup.string().optional(),
  confirmation: Yup.string().optional(),
  currentPassword: Yup.string().optional(),
  birthday: Birthday.optional(),
})

const ConfirmAccountStatusData = Yup.object()
  .shape({
    backUrl: Yup.string(),
  })
  .concat(StatusData)

export {
  UserData,
  LoginData,
  RegisterData,
  NewPasswordData,
  ForgotPasswordData,
  CustomerAccountData,
  SetCustomerAccountData,
  ConfirmAccountStatusData,
}
