import * as Yup from 'yup'

import { StatusData } from './status'

const OrderListRequestData = Yup.object().shape({
  page: Yup.number().integer().optional(),
  limit: Yup.number().integer().required(),
})

const OrderListEntry = Yup.object().shape({
  orderId: Yup.string().required(),
  orderNumber: Yup.string().required(),
  date: Yup.string().required(),
  shippingAddressee: Yup.string().required(),
  price: Yup.string().required(),
  status: Yup.string().required(),
  canCancel: Yup.boolean().required(),
  canReorder: Yup.boolean().optional(),
  encryptionKey: Yup.string().optional(),
})

const OrderListData = Yup.object()
  .shape({
    maxCount: Yup.number().integer().optional(),
    orders: Yup.array().of(OrderListEntry).required().min(0),
  })
  .concat(StatusData)

const OrderDetailsProductData = Yup.object().shape({
  productId: Yup.string().optional(),
  productName: Yup.string().required(),
  sku: Yup.string().optional(),
  numOrdered: Yup.number().required(),
  numShipped: Yup.number().optional(),
  numCanceled: Yup.number().optional(),
  numRefunded: Yup.number().optional(),
  unitPrice: Yup.string().optional(),
  totalPrice: Yup.string().required(),
  type: Yup.string().optional(),
})

const OrderTotalData = Yup.object().shape({
  code: Yup.string().required(),
  title: Yup.string().nullable().optional(),
  value: Yup.string().required(),
})

const DeliverSlip = Yup.object().shape({
  link: Yup.string().required(),
  name: Yup.string().required(),
})

const OrderDetailData = Yup.object()
  .shape({
    order: Yup.object().shape({
      orderId: Yup.string().required(),
      orderNumber: Yup.string().required(),
      orderStatus: Yup.string().required(),
      date: Yup.string().required(),
      deliveryType: Yup.string().optional(),
      paymentType: Yup.string().optional(),
      deliveryAddress: Yup.string().required(),
      billingAddress: Yup.string().nullable().optional(),
      invoiceLink: Yup.string().nullable().optional(),
      deliverySlips: Yup.array().of(DeliverSlip).optional(),
      orderItems: Yup.array().of(OrderDetailsProductData).required().min(0),
      totals: Yup.array().of(OrderTotalData).required().min(0),
      discountDescription: Yup.string().optional(),
      encryptionKey: Yup.string().optional(),
      shipmentTrackingNumbers: Yup.array().of(Yup.string()).optional(),
      canCancel: Yup.boolean().required(),
      documentsAvailable: Yup.boolean().optional(),
    }),
  })
  .concat(StatusData)

const OrderDetailsRequestData = Yup.object().shape({
  orderId: Yup.string().required(),
})

const RegularDeliveryStatus = Yup.string().oneOf([
  'active',
  'paused',
  'canceled',
])

const RegularDeliveryAddress = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().nullable().optional(),
  company: Yup.string().nullable().optional(),
  street: Yup.string().required(),
  postcode: Yup.string().required(),
  city: Yup.string().required(),
  country: Yup.string().required(),
})

const RegularDeliveryData = Yup.object()
  .shape({
    subscriptions: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().nullable().optional(),
        status: RegularDeliveryStatus,
        nextDeliveryDate: Yup.date().required(),
        deliveryInterval: Yup.number().integer().required(),
        deliveryAddress: RegularDeliveryAddress,
        items: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required(),
            manufacturer: Yup.string().required(),
            productLine: Yup.string().nullable().optional(),
            config_variable_a: Yup.string().required(),
            imageUrl: Yup.string().required(),
            unitPrice: Yup.string().required(),
            qty: Yup.number().integer().required(),
          })
        ),
      })
    ),
  })
  .concat(StatusData)

const RegularDeliveryChangeData = Yup.object().shape({
  id: Yup.string().required(),
  status: RegularDeliveryStatus,
  nextDeliveryDate: Yup.date().required(),
  deliveryInterval: Yup.number().integer().required(),
  label: Yup.string().required(),
  deliveryAddress: RegularDeliveryAddress,
})

export {
  OrderListData,
  OrderListRequestData,
  OrderDetailData,
  OrderDetailsRequestData,
  RegularDeliveryData,
  RegularDeliveryChangeData,
}
