import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import * as Yup from 'yup'

import {
  AlertBox,
  AlertBoxTypes,
  Button,
  CenterPopup,
  Link,
  PopupOverlay,
} from '../..'
import { FormStatus, useGlobalVariables, useForm } from '../../../utils'
import {
  useBackendApi,
  useSnippetContent,
  useTranslation,
} from '../../../utils/providers'
import { trackPreorder } from '../../../utils/Alphapet/tracking'
import { Input } from '../Form'
import { LoadingIcon } from '../Icons'

/**
 * Wrapper around the actual popup component to allow rendering it through a portal.
 *
 * @param props
 * @returns {JSX.Element|React.ReactPortal}
 * @constructor
 */
function PreorderPopup({ productData, hidePopup }) {
  const backendApi = useBackendApi()
  const { popupSettings: { heading, text, buttonLabel } = {} } =
    useSnippetContent('preorder_popup') || {}
  const { t } = useTranslation()
  const [privacyPolicyUrl] = useGlobalVariables(['privacyPolicyPage'])

  const createFormData = () => ({
    email: {
      name: 'email',
      value: '',
      error: null,
      schema: Yup.string()
        .trim()
        .email(t('INVALID_EMAIL_ADDRESS'))
        .required(t('FORM_ERROR_REQ_EMAIL')),
    },
  })

  const onSubmit = async ({ body, token }) => {
    body.token = token
    body.productId = productData.id

    return await backendApi.preorderProduct(body)
  }

  const { handleChange, handleSubmit, formStatus, formData, error } = useForm({
    createFormData,
    onSubmit,
    captchaActionName: 'PREORDER',
    resetAfterSubmit: true,
  })

  useEffect(() => {
    let timeout
    if (formStatus === FormStatus.SUCCESS) {
      trackPreorder(productData)

      timeout = setTimeout(() => {
        hidePopup()
        clearTimeout(timeout)
      }, 5000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [formStatus])

  const getErrorMesssage = (message) => {
    switch (message) {
      case 'Consent not given!':
      case 'grecaptcha is not available!':
        return t('PREORDER_CONSENT_ERROR')({
          Link,
          props: {
            href: privacyPolicyUrl,
            target: '_blank',
            rel: 'noreferrer',
            className: 'text-underline',
          },
        })

      case 'Invalid email address.':
        return t('PREORDER_INVALID_EMAIL')

      case 'Google captcha is invalid.':
      case 'Invalid product ID.':
      case 'Failed to get captcha response':
      default:
        return t('PREORDER_REQUEST_ERROR')
    }
  }

  return (
    <CenterPopup closeAction={hidePopup} className="preorder-popup">
      {(formStatus === FormStatus.PENDING ||
        (!buttonLabel && !text && !heading)) && (
        <PopupOverlay>
          <LoadingIcon />
        </PopupOverlay>
      )}
      <div className="form-preorder mb-3">
        <h1 className="mb-4 text-uppercase">{heading}</h1>
        <p>{text}</p>

        <form onSubmit={handleSubmit}>
          <Input
            className="mb-3"
            type="email"
            data={formData.email}
            label={t('FORM_LABEL_EMAIL_ADDRESS')}
            onChange={handleChange}
          />

          {formStatus === FormStatus.SUCCESS && (
            <AlertBox type={AlertBoxTypes.SUCCESS} className="mt-3">
              {t('PREORDER_SUCCESSFUL')}
            </AlertBox>
          )}
          {formStatus === FormStatus.ERROR && (
            <AlertBox type={AlertBoxTypes.ERROR} className="mt-3">
              {getErrorMesssage(error?.message)}
            </AlertBox>
          )}

          {formStatus !== FormStatus.SUCCESS && (
            <Button
              type="submit"
              variant="primary"
              className="w-100"
              disabled={formStatus === FormStatus.PENDING}
            >
              {buttonLabel}
            </Button>
          )}
        </form>
      </div>
    </CenterPopup>
  )
}

PreorderPopup.propTypes = {
  productData: PropTypes.object,
  hidePopup: PropTypes.func,
}

export default PreorderPopup
