/**
 * Get Sessionly review-URLs for multiple products by their SKUs.
 *
 * @param {string[]} productSkus - A list of product-SKUs
 * @returns {Promise<{}>} A promise that resolves to an object
 */
async function fetchSessionlyReviewUrls(productSkus) {
  // No need to make an empty request
  if (productSkus?.length) {
    const params = {
      sku: productSkus,
    }
    const endpoint = '/widget/review/get/reviews/links'
    return fetchFromSessionly(endpoint, params)
  }
  return {}
}

/**
 * Get Sessionly ratings for multiple products by their product-IDs.
 *
 * @param {number[]} productIds - A list of product-IDs
 * @returns {Promise<{}>} A promise that resolves to an object
 */
async function fetchSessionlyRatings(productIds) {
  // No need to make an empty request
  if (productIds?.length) {
    const params = {
      ids: productIds,
    }
    const endpoint = '/widget/productreview/get/stars/ids/json'
    return fetchFromSessionly(endpoint, params)
  }
  return {}
}

/**
 * Perform a request against Sessionly.
 *
 * @param {string} endpoint
 * @param {Object} params - An object containing the request parameters
 * @returns {Promise<{}>}
 */
async function fetchFromSessionly(endpoint, params) {
  if (endpoint && params) {
    const body = {
      shopId: process.env.SESSIONLY_SHOPID,
      apiKey: process.env.SESSIONLY_APIKEY,
      ...params,
    }
    const url = 'https://sessionly.io' + endpoint
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Cookie: 'device_view=full',
      },
    })
    if (response.status !== 200) {
      const { status, statusText } = response
      const error = new Error()
      error.code = status
      error.message = statusText
      error.stack = 'fetchFromSessionly()'
      try {
        error.cause = await response.json()
      } catch (e) {
        console.warn(e) // JSON parse error
      }
      throw error
    }
    return response.json()
  }
  return {}
}

export { fetchSessionlyRatings, fetchSessionlyReviewUrls }
