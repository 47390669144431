export default function getTierIconClass(tiere) {
  if (Array.isArray(tiere) === false) {
    tiere = []
  }
  let tiereIconClass = []
  tiere.map((icon) => {
    if (icon != null) {
      if (icon == 'Hund') tiereIconClass.push('cf-dog')
      if (icon == 'Katze') tiereIconClass.push('cf-cat')
    }
  })
  return tiereIconClass
}
