import React from 'react'

import ProductLink from '../../../Link/ProductLink'
import Image from '../../../Image'
import ProductPrices from '../../../PriceInfo/ProductPrices'
import { ListPagePricingProvider } from '../../../../../utils'

export default function ProductItem(props) {
  const { itemData } = props
  const {
    url,
    small_image,
    images,
    config_variable_a,
    ean,
    manufacturer_name,
    produkt_linie,
    name,
  } = itemData
  const imageUrl = small_image || images?.[0]
  const itemName = [
    manufacturer_name,
    produkt_linie,
    name,
    config_variable_a,
  ].join(' ')
  return (
    <li className="header__search-results-item">
      <ProductLink className="header__search-results-item-link" href={url}>
        <Image
          className="header__search-results-item-image"
          srcFull={imageUrl}
        />
        <div className="header__search-results-item-info">
          <div className="header__search-results-item-name">{itemName}</div>
          <div className="d-none">sku: {ean}</div>
          <ListPagePricingProvider productData={itemData}>
            <ProductPrices
              productData={itemData}
              classSpecialPrice="header__search-results-item-price"
              classRegularPrice="header__search-results-item-price"
              classUnitPrice="header__search-results-item-desc"
            />
          </ListPagePricingProvider>
        </div>
      </ProductLink>
    </li>
  )
}
