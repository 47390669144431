import React from 'react'
import classNames from 'classnames'

export default function PopupOverlay(props) {
  const { className, children } = props
  const containerClass = classNames('popup-overlay-container', className)
  return (
    <div className={containerClass}>
      <div className="overlay-icon">{children}</div>
    </div>
  )
}
