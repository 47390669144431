import React from 'react'
import { PropTypes } from 'prop-types'
import {
  isFixedBundle,
  promotionConversionTracking,
  shouldOpenInNewTab,
  useTranslation,
} from '../../../utils'
import ProductLink from '../Link/ProductLink'
import ProductActionButton from '../ProductActionButton'

const ProductActions = ({
  actionClass,
  url,
  productDataForTracking,
  sku,
  trackingIdentifier,
  buttonType,
  openInNewTab,
  productData,
}) => {
  const { t } = useTranslation()
  const isBundle =
    productData.product_type === 'bundle' && !isFixedBundle(productData)

  if (buttonType === 'addToCart' && !isBundle) {
    return (
      <ProductActionButton
        className={actionClass}
        productData={productData}
        qty={1}
        disableVariants={true}
        trackingData={{ origin: 'content-page' }}
      />
    )
  }

  return (
    <ProductLink
      href={url}
      className={actionClass}
      data-product-name={productDataForTracking}
      onClick={() => promotionConversionTracking(sku, trackingIdentifier)}
      {...shouldOpenInNewTab(openInNewTab)}
    >
      {t('PRODUCT_TILE_TO_PRODUCT')}
    </ProductLink>
  )
}

ProductActions.propTypes = {
  actionClass: PropTypes.string,
  url: PropTypes.arrayOf(PropTypes.string),
  productDataForTracking: PropTypes.string,
  sku: PropTypes.string,
  trackingIdentifier: PropTypes.string,
  buttonType: PropTypes.string,
  openInNewTab: PropTypes.bool,
  productData: PropTypes.instanceOf(Object),
}

ProductActions.defaultProps = {
  actionClass: '',
  url: '',
  productDataForTracking: '',
  sku: '',
  trackingIdentifier: '',
  buttonType: 'productPage',
  openInNewTab: false,
}

export default ProductActions
