import { useEffect } from 'react'
import { scrollToElement } from '../../../utils'

export default function AnchorWatcher() {
  const maxCount = 5
  let interval

  useEffect(() => {
    let counter = 0

    // No need to watch for anything when there is no anchor
    if (typeof window !== 'undefined' && window.location.hash) {
      interval = setInterval(() => {
        const hash = window.location.hash
        const element = document.getElementById(hash.substr(1))
        if (element) {
          scrollToElement({ selector: hash })
        } else {
          counter++
        }

        // Cancel the counter when we reach the maximum, or when we found what we're looking for
        if (element || counter === maxCount) {
          clearInterval(interval)
        }
      }, 1000)
    }

    return () => {
      // Cancel the counter when unmounting
      clearInterval(interval)
    }
  }, [])

  return null
}
