import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function Icon({ symbol, className }) {
  const classes = classNames(className, 'icon', `${symbol}`)

  return <span className={classes} aria-hidden={true} />
}

Icon.propTypes = {
  className: PropTypes.string,
  symbol: PropTypes.string,
}
