import React from 'react'
import classNames from 'classnames'

import { Button } from '../../index'

function CTAButtons({ buttons = [], type, isMobileFullWidth, className }) {
  const buttonClass = classNames('mr-2 mb-2', {
    'mobile-full-width': isMobileFullWidth,
    className,
  })
  return (
    <section className="container mb-4 text-center buttons">
      {buttons.map((button, key) => (
        <Button
          variant={type}
          href={button.link}
          key={key}
          className={buttonClass}
        >
          {button.text}
        </Button>
      ))}
    </section>
  )
}

export default CTAButtons
export { default as ctaButtonsVariants } from './variants.js'
