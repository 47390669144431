import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Link, MarkdownAwareSpan } from '../../../../index'
import Submenu from './Submenu'

function FirstLevelItem({ label, href, items }) {
  const [isExpanded, setExpanded] = useState(false)
  const parentRef = useRef(null)

  const onBlur = (evt) => {
    // Whenever the focus changes from one top-level menu to the next we need
    // to close the "old" menu. Whenever we navigate through sub items of the
    // same top-level menu we want to keep the current menu open.
    if (!parentRef?.current?.contains(evt.relatedTarget)) {
      setExpanded(false)
    }
  }

  const liClasses = classNames('header__navbar_item', {
    'header__navbar_item--active': isExpanded,
  })

  const openMenu = () => {
    setExpanded(true)
  }
  const closeMenu = () => {
    setExpanded(false)
  }

  return (
    <li
      ref={parentRef}
      className={liClasses}
      onMouseEnter={openMenu}
      onMouseLeave={closeMenu}
    >
      <Link
        className="header__navbar_link"
        href={href}
        onFocus={openMenu}
        onBlur={onBlur}
        onClick={closeMenu}
      >
        <MarkdownAwareSpan>{label}</MarkdownAwareSpan>
      </Link>

      {items?.length > 0 && (
        <Submenu
          items={items}
          isExpanded={isExpanded}
          onItemClick={closeMenu}
          onItemBlur={onBlur}
        />
      )}
    </li>
  )
}

FirstLevelItem.propTypes = {
  label: PropTypes.string,
  href: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  items: PropTypes.array,
}

FirstLevelItem.defaultProps = {
  label: null,
  href: null,
  items: [],
}

export default FirstLevelItem
