import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  dispatchHideOverlayEvent,
  dispatchShowOverlayEvent,
  useTranslation,
} from '../../../../../utils'

function FlyoutMenu({ className, id, isVisible, setVisible, children }) {
  const { t } = useTranslation()
  const hideMenu = useCallback(() => setVisible(false), [setVisible])

  useEffect(() => {
    if (isVisible) {
      dispatchShowOverlayEvent()
      window.addEventListener('overlay:clicked', hideMenu)
    } else {
      dispatchHideOverlayEvent()
      window.removeEventListener('overlay:clicked', hideMenu)
    }
    return () => {
      dispatchHideOverlayEvent()
      window.removeEventListener('overlay:clicked', hideMenu)
    }
  }, [isVisible, hideMenu])

  const containerClasses = classNames('header__flyout_menu', className, {
    'header__flyout_menu--active': isVisible,
  })
  const closeButtonClasses = classNames(
    'header__flyout_close_btn reset-btn cf-close-light',
    {
      'header__flyout_close_btn--active': isVisible,
    }
  )

  return (
    <>
      <button
        className={closeButtonClasses}
        onClick={hideMenu}
        title={t('FLYOUT_MENU_CLOSE')}
        aria-controls={id}
      >
        <span className="d-none">{t('FLYOUT_MENU_CLOSE')}</span>
      </button>
      <div id={id} className={containerClasses}>
        {children}
      </div>
    </>
  )
}

FlyoutMenu.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  isVisible: PropTypes.bool,
  setVisible: PropTypes.func,
  children: PropTypes.any,
}

FlyoutMenu.defaultProps = {
  className: null,
  id: null,
  isVisible: false,
  setVisible: null,
  children: null,
}

export { FlyoutMenu }
export { default as FlyoutItem } from './FlyoutItem'
export { default as FlyoutHeading } from './FlyoutHeading'
export { default as FlyoutSection } from './FlyoutSection'
