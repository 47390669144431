import fetch from 'isomorphic-unfetch'

import { getDefaultLanguage } from '../..'

async function getDocuments({ body }) {
  const url = process.env.MAKAIRA_API_URL + '/documents/public'

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Makaira-Instance': process.env.MAKAIRA_API_INSTANCE,
    },
    body: JSON.stringify(body),
  })

  if (response.status !== 200) {
    const { status, statusText } = response
    const error = new Error()
    error.code = status
    error.message = statusText
    error.stack = 'getDocuments()'
    try {
      error.cause = await response.json()
    } catch (e) {
      console.warn(e) // JSON parse error
    }
    throw error
  }

  return response.json()
}

export default async function fetchMakairaProducts(
  productIds = [],
  language = getDefaultLanguage()
) {
  const body = {
    datatype: 'product',
    ids: productIds,
    fields: [],
    constraints: {
      'query.language': language,
      'query.attribute_ids': [],
    },
  }
  let page = await getDocuments({ body })
  // Remove NULL Elements:
  page = page?.filter((pageData) => pageData !== null)
  return page
}
