import React, { useContext, useEffect, useState } from 'react'

import {
  GlobalDataContext,
  useTranslation,
  withCartRedirect,
} from '../../../utils'
import DefaultInfoBox from './DefaultInfoBox'

function AddToCartInfoBox(props) {
  const { cartRedirect } = props
  const [isVisible, setVisible] = useState(false)
  const [message, setMessage] = useState(null)
  const [status, setStatus] = useState('ok')
  const [productData, setProductData] = useState(null)
  const { pageData } = useContext(GlobalDataContext) || {}
  const { t } = useTranslation()

  const setProduct = (e) => {
    const { productData } = e?.detail || {}
    setProductData(productData)
  }

  const showInfoBox = (e) => {
    const { message, status } = e.detail
    let displayMessage = message instanceof Error ? message.toString() : message
    if (!displayMessage) {
      displayMessage = status === 'ok' ? t('ADDED_TO_CART') : null
    }

    setVisible(true)
    setMessage(displayMessage)
    setStatus(status)
  }

  useEffect(() => {
    setVisible(false)
    window.addEventListener('addtocart:request', setProduct)
    window.addEventListener('addtocart:response', showInfoBox)
    return () => {
      window.removeEventListener('addtocart:request', setProduct)
      window.removeEventListener('addtocart:response', showInfoBox)
    }
  }, [pageData])

  if (!isVisible) {
    return null
  }
  const boxProps = {
    message,
    status,
    closePopup: () => setVisible(false),
    cartRedirect,
    productData,
  }

  return <DefaultInfoBox {...boxProps} />
}

export default withCartRedirect(AddToCartInfoBox)
