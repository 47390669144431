/**
 * Truncate the string to a specified length and adding an ellipsis at the end of the string.
 *
 * @param {string} str - The string to truncate
 * @param {number} strlength - The number of chars which truncated string should have.
 * Please use only numbers greater than '3' and less than string length.
 * @returns {string} - truncated string
 */

export default function truncateString(str, strlength) {
  if (typeof str === 'string') {
    if (
      typeof strlength === 'number' &&
      str.length > strlength &&
      strlength > 3
    ) {
      return `${str.substring(0, strlength - 3)}...`
    }
    return str
  }
  return ''
}
