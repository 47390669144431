import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useCustomer, useTranslation } from '../../../../utils'
import { dispatchLoginPopupShowEvent } from '../../LoginPopup'

import {
  dispatchHideAccountMenu as closeMenu,
  dispatchShowAccountMenu as openMenu,
} from '../Menu'
import { useIcons } from '../IconProvider'
import HeaderAction from './HeaderAction'

function AccountButton({ className, showLabel, menuStyle, owns }) {
  const { isLoggedIn } = useCustomer()
  const { t } = useTranslation()
  const getIcon = useIcons()

  const label = isLoggedIn ? t('HEADER_ACCOUNT') : t('HEADER_LOGIN')
  const icon = isLoggedIn
    ? getIcon('actionAccountLoggedIn')
    : getIcon('actionAccountLoggedOut')
  const onClick = isLoggedIn ? openMenu : dispatchLoginPopupShowEvent
  const onMouseEnter = isLoggedIn && menuStyle === 'dropdown' ? openMenu : null
  const onMouseLeave = isLoggedIn && menuStyle === 'dropdown' ? closeMenu : null

  const containerClasses = classNames('header__action-account', className, {
    'header__action-account--logged-in': isLoggedIn,
  })

  return (
    <div
      className={containerClasses}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <HeaderAction
        symbol={icon}
        label={label}
        showLabel={showLabel}
        onClick={onClick}
        owns={isLoggedIn ? owns : null}
      />
    </div>
  )
}

AccountButton.protoTypes = {
  className: PropTypes.string,
  showLabel: PropTypes.bool,
  menuStyle: PropTypes.oneOf(['dropdown', 'flyout']),
  owns: PropTypes.string,
}

AccountButton.defaultProps = {
  className: null,
  showLabel: true,
  menuStyle: 'flyout',
  owns: null,
}

export default AccountButton
