import React, { Component, useContext } from 'react'
import PropTypes from 'prop-types'

import TranslationContext from '../../core/TranslationProvider'
import { fetchSnippetData } from '../..'

/**
 * Helper function to return the argument as an array if it isn't already and indicate whether it was an array or not.
 *
 * @param {string|string[]} snippetIds - A single snippet-ID or an array of snippet-IDs
 * @returns {[null, boolean]|[string[], boolean]} - '[null, false]' if argument is empty
 */
const toArray = (snippetIds) => {
  if (!snippetIds || !snippetIds.length) {
    return [null, false]
  }
  const isArray = Array.isArray(snippetIds)
  if (!isArray) {
    snippetIds = [snippetIds]
  }
  return [snippetIds, isArray]
}

/**
 * The snippet context.
 *
 * @type {React.Context<unknown>}
 */
const SnippetContext = React.createContext()

/**
 * Higher-Order-Component for passing the SnippetContext to another component.
 *
 * @param {React.Component} WrappedComponent - the component to upgrade
 * @returns {React.Component} - the upgraded component with a 'snippetContext' prop
 */
const withSnippet = (WrappedComponent) => {
  return function WithSnippet(props) {
    const ctx = useContext(SnippetContext)
    const getSnippetContent = (snippetIds) => {
      const { fetchSnippets, ...cache } = ctx
      const [ids, isArray] = toArray(snippetIds)
      fetchSnippets(ids)

      return isArray
        ? ids.map((s) => cache[s]?.[0]?.properties?.content)
        : cache?.[snippetIds]?.[0]?.properties?.content
    }
    return (
      <WrappedComponent
        snippetContext={ctx}
        getSnippetContent={getSnippetContent}
        {...props}
      />
    )
  }
}

/**
 * Get a set of snippets from the global snippet-cache.
 * If a snippet hasn't been cached yet it will be fetched in the background.
 *
 * @param {string|string[]} snippetIds - A single snippet-ID or an array of snippet-IDs
 * @returns {undefined|Object[]|Object[][]}
 *    An array of snippet element arrays if 'snippetIds' is an array of strings,
 *    or a single snippet element array if 'snippetIds' is a string
 */
const useSnippet = (snippetIds) => {
  const [ids, isArray] = toArray(snippetIds)

  const ctx = useContext(SnippetContext)
  const { fetchSnippets, ...cache } = ctx
  fetchSnippets(ids)

  return isArray ? ids.map((s) => cache[s]) : cache[ids[0]]
}

/**
 * Get the first element's content for a set of snippets from the global snippet-cache.
 * If a snippet hasn't been cached yet it will be fetched in the background.
 * This is a shorthand of {@link useSnippet} to return the content properties for the first element of each snippet.
 *
 * @param {string|string[]} snippetIds - A single snippet-ID or an array of snippet-IDs
 * @returns {undefined|Object[]|Object[][]}
 *    An array of snippet content objects if 'snippetIds' is an array of strings,
 *    or a single snippet content object if 'snippetIds' is a string.
 *    Only the contents of the first element will be returned for each snippet.
 */
const useSnippetContent = (snippetIds) => {
  const [ids, isArray] = toArray(snippetIds)
  const snippets = useSnippet(isArray ? ids : snippetIds)

  return isArray
    ? snippets.map((s) => s?.[0]?.properties?.content)
    : snippets?.[0]?.properties?.content
}

/**
 * The snippet provider. Keeps a cache of already fetched snippets and provides a function to fetch new snippets.
 *
 * It must be a descendant of the {@link TranslationProvider}!
 */
class SnippetProvider extends Component {
  // Snippets depend on the language
  static contextType = TranslationContext

  static propTypes = {
    /**
     * Initial set of snippets. Defaults to an empty cache.
     */
    snippets: PropTypes.shape({
      '<snippetId>': PropTypes.arrayOf(PropTypes.object),
    }),
  }

  /**
   * @constructor
   * @param {Object} [props]
   * @param {Object} [props.snippets] - Initial set of snippets. Defaults to an empty cache.
   */
  constructor(props) {
    super(props)
    this.state = { ...props.snippets } || {}
  }

  componentDidMount() {
    // Whenever the language changes, we need to re-fetch all snippets using the current language
    addEventListener('change:language', this.refetchSnippets)
  }

  componentWillUnmount() {
    removeEventListener('change:language', this.refetchSnippets)
  }

  /**
   * Re-fetch all snippets that have been fetched before.
   *
   * @returns {Promise<void>}
   */
  refetchSnippets = async () => {
    const snippetIds = Object.keys(this.state)
    if (snippetIds.length) {
      this.invalidateSnippets(snippetIds)
      this.fetchSnippets(snippetIds)
    }
  }

  /**
   * Fetch one or more snippets. Ignores already queued or fetched snippets.
   *
   * @param {string|string[]} snippetIds - One or more snippets-IDs.
   * @returns {Promise<Object|Object[]|Object[][]>} - A promise that resolves
   *    to a content element array if the parameter is a string, or
   *    to an array of content elements if the parameter is an array of strings.
   */
  fetchSnippets = async (snippetIds) => {
    const [asArray, isArray] = toArray(snippetIds)
    snippetIds = asArray
    if (snippetIds === null) {
      throw TypeError('Invalid argument: ' + snippetIds)
    }

    // Fetch only missing snippets
    const missing = snippetIds.filter((s) => !(s in this.state))

    if (missing.length) {
      // Initialize the cache-key in-place, _before_ the actual fetch, **without** setting the state.
      // While the fetch is running, no other fetch of the same snippet should be triggered.
      // In short: queue without re-rendering
      // eslint-disable-next-line react/no-direct-mutation-state
      missing.map((s) => (this.state[s] = []))

      const { language = 'en' } = this.context
      const response = await fetchSnippetData({
        ids: missing,
        language,
      }).catch(console.error)

      const output = {}
      response?.map((r) => {
        const s = r.data?.snippetId
        const elements = r.data?.config?.top?.elements
        output[s] = elements
      })

      // Merge with the _latest_ state to avoid concurring overwrites
      this.setState((latest) => {
        return { ...latest, ...output }
      })
    }

    return isArray
      ? snippetIds.map((s) => this.state[s])
      : this.state[snippetIds[0]]
  }

  /**
   * Forcefully remove one or more snippets from the cache.
   *
   * @param {string|string[]} snippetIds - One or more snippets-IDs.
   */
  invalidateSnippets = (snippetIds) => {
    if (!snippetIds || !snippetIds.length) {
      return
    }
    if (!Array.isArray(snippetIds)) {
      snippetIds = [snippetIds]
    }
    const cache = this.state
    snippetIds.map((s) => {
      if (s in cache) {
        cache[s] = undefined
        delete cache[s]
      }
    })
    this.setState(cache)
  }

  render() {
    const expose = {
      fetchSnippets: this.fetchSnippets,
      invalidateSnippets: this.invalidateSnippets,
      ...this.state,
    }
    return (
      <SnippetContext.Provider value={expose}>
        {this.props.children}
      </SnippetContext.Provider>
    )
  }
}

export {
  SnippetContext,
  SnippetProvider,
  useSnippet,
  useSnippetContent,
  withSnippet,
}
