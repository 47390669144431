import { useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import { useFeatureFlags } from '../../providers'

import validateCaptcha, { CaptchaError } from './validateCaptcha'
import FormStatus from './FormStatus'
import logError from '../logError'

export { CaptchaError, FormStatus, validateCaptcha }

/**
 * @param {function} createFormData
 * @param {function} onSubmit
 * @param {string} [captchaActionName]
 * @param {boolean} [validateOnChange=true]
 * @returns {{handleSubmit: ((function(Event): Promise<*>)|*), handleChange: handleChange, response: unknown, setFormData: (value: (((prevState: {}) => {}) | {})) => void, formStatus: string, resetForm: resetForm, formData: {}, error: unknown}}
 */
export default function useForm({
  createFormData,
  captchaActionName,
  onSubmit,
  validateOnChange = true,
}) {
  const [formData, setFormData] = useState(createFormData() || {})
  const [formStatus, setFormStatus] = useState(FormStatus.NOT_SUBMITTED)
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const { isFeatureEnabled } = useFeatureFlags()

  const resetForm = () => {
    setFormStatus(FormStatus.NOT_SUBMITTED)
    setFormData(createFormData())
    setResponse(null)
    setError(null)
  }

  /**
   * Validation errors are *not* set in the global error state.
   *
   * @param evt
   */
  const handleChange = (evt) => {
    // Reset status
    setFormStatus(FormStatus.NOT_SUBMITTED)

    // Extract name and value
    const element = evt.target
    const fieldName = element.name || element.getAttribute('data-name')
    let value = null
    if (element.type === 'checkbox') {
      value = element.checked
    } else if (element.getAttribute('data-value')) {
      // e.g. <li> within <Prefix/>
      value = element.getAttribute('data-value')
    } else {
      value = element.value
    }
    value = typeof value === 'string' ? value.trim() : value

    // Validate and update form object
    const fieldData = formData[fieldName]
    if (fieldData) {
      const schema = Yup.object().shape({ [fieldName]: fieldData.schema })
      fieldData.value = value
      if (validateOnChange) {
        let error = null
        try {
          schema.validateSync({ [fieldName]: value })
        } catch (err) {
          error = err.message
        }
        fieldData.error = error
      }
    }

    setFormData({
      ...formData,
      [fieldName]: fieldData,
    })
  }

  /**
   * Validation errors are set in the global error state.
   *
   * @param {Event} evt - the event that triggered the change
   * @returns {Promise<unknown>}
   */
  const handleSubmit = async (evt) => {
    evt.preventDefault()

    // Reset status
    setFormStatus(FormStatus.PENDING)
    setError(null)
    setResponse(null)

    // Collect field data
    const body = {} // Request parameters
    const validationData = {} // Data to validate the schemas against
    const fieldSchemas = {} // Field schemas to combine
    const fieldDependencies = []

    Object.keys(formData).forEach((k) => {
      const field = formData[k]
      // Ignore fields that have no name attribute
      if (field.name) {
        const value =
          typeof field.value === 'string' ? field.value.trim() : field.value
        // Ignore fields that are to be explicitly excluded
        if (!field.exclude) {
          body[field.name] = value
        }
        // Excluded fields still have to be validated
        validationData[field.name] = value
        fieldSchemas[field.name] = field.schema
        // Add dependencies
        if (field.depends?.length) {
          fieldDependencies.push([field.name, ...field.depends])
        }
      }
    })

    // Reset field errors
    Object.values(formData).forEach((v) => {
      v.error = null
    })

    // Validate
    const schema = Yup.object().shape(fieldSchemas, fieldDependencies)
    try {
      schema.validateSync(validationData, { abortEarly: false })
    } catch (error) {
      setFormStatus(FormStatus.NOT_SUBMITTED)
      setError(error)
      const errorMessages = []
      error.inner.map((e) => {
        if (e.path in formData) {
          // The same path can be present multiple times, meaning there is
          // more than one validation error for a field. For simplicity,
          // always display the last error by overwriting.
          formData[e.path].error = e.message
          errorMessages.push(e.message)
        }
      })
      setFormData(formData)
      return error
    }

    // Pre-submit
    const params = { body }
    if (isFeatureEnabled('captcha') && captchaActionName) {
      try {
        params.token = await validateCaptcha(captchaActionName)
      } catch (e) {
        setFormStatus(FormStatus.ERROR)
        setError(e)
        await logError(e, captchaActionName)
        return error
      }
    }

    // Submit
    return onSubmit(params)
      .then((response) => {
        setResponse(response)
        if (response.status !== 'ok') {
          throw new Error(response.message)
        }
        setFormStatus(FormStatus.SUCCESS)
        return response
      })
      .catch((e) => {
        setFormStatus(FormStatus.ERROR)
        setError(e)
      })
  }

  return {
    formData,
    formStatus,
    response,
    error,
    handleChange,
    handleSubmit,
    resetForm,
    setFormData,
  }
}

useForm.propTypes = {
  createFormData: PropTypes.func.isRequired,
  captchaActionName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  validateOnChange: PropTypes.bool,
}
