/**
 * Replace variable placeholders in a string with predefined values.
 *
 * Variables within the given string are expected to be in the form:
 * 'variable{{someName}}'.
 *
 * The list of replacement values has the following structure:
 *  [
 *    {
 *      varName: 'someName',
 *      varValue: 'someValue'
 *    },
 *    ...
 *  ]
 *
 * @param {string} searchString - The string to search and replace variables in.
 * @param {Object[]} globalVariables - A list of variable definitions.
 * @returns {string}
 */
export default function renderGlobalVariables(searchString, globalVariables) {
  if (typeof searchString !== 'string' || !Array.isArray(globalVariables)) {
    return searchString
  }

  const regex = /(variable{{)(\w+)(}})/g
  const result = searchString.replace(regex, (match, p1, p2) => {
    // Filter the available variables for a matching variable name
    const f = globalVariables.filter((v) => !!v && v.varName === p2)
    // Return the matching variable's value, or the placeholder itself when there is no match
    return f?.[0]?.varValue || match
  })

  return result
}
