import React from 'react'
import { Text, Icon } from '../..'

import { useGlobalData, useTranslation } from '../../../utils/providers'

const NoComponent = ({ name = '' }) => {
  const { t } = useTranslation()
  const { isPreview = false } = useGlobalData()

  if (!isPreview) return null

  return (
    <div className="no-component">
      <div className="no-component__inner">
        <Icon symbol="construction" />
        <div className="no-component__content">
          <Text className="no-component__name" text={name} />
          <Text
            className="no-component__description"
            text={t('NO_COMPONENT_AVAILABLE')}
          />
        </div>
      </div>
    </div>
  )
}

export default NoComponent
