import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  AccountButton,
  CartButton,
  HamburgerButton,
  SearchButton,
  StoreButton,
} from '../../Actions'
import {
  AccountFlyout,
  AccountNavigationFlyout,
  NavigationFlyout,
} from '../../Menu'
import ActionContainer from '../ActionContainer'
import Logo from '../Logo'

function Mobile({ actions, logo, menuData, searchBar, isSticky, isCollapsed }) {
  const { showLabels, showAccount, showCart, showStorefinder, storefinderUrl } =
    actions

  const version = 'mobile'
  const isLabelVisible = showLabels.includes(version)
  const searchLayout = searchBar.layout

  const logoContainerClasses = classNames('col-12 header__logo-container', {
    'header__logo-container--collapsed': isSticky && isCollapsed,
  })

  return (
    <>
      <div className={logoContainerClasses}>
        <Logo className="text-center" {...logo} />
      </div>
      <ActionContainer className="col-12 justify-content-between bg-logo-color">
        <SearchButton
          showLabel={isLabelVisible}
          searchBar={searchBar}
          showAsPopup={true}
          layout={searchLayout}
        />
        {showStorefinder.includes(version) && storefinderUrl && (
          <StoreButton showLabel={isLabelVisible} href={storefinderUrl} />
        )}
        {showAccount.includes(version) && (
          <AccountButton showLabel={isLabelVisible} owns="account-menu" />
        )}
        <CartButton
          showLabel={isLabelVisible}
          showAsBubble={!showCart.includes(version)}
        />
        <HamburgerButton showLabel={isLabelVisible} owns="navigation-menu" />
      </ActionContainer>

      {showAccount.includes(version) ? (
        <>
          <AccountFlyout id="account-menu" />
          <NavigationFlyout id="navigation-menu" menuData={menuData} />
        </>
      ) : (
        <AccountNavigationFlyout id="navigation-menu" menuData={menuData} />
      )}
    </>
  )
}

Mobile.protoTypes = {
  actions: PropTypes.shape({
    showLabels: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    showAccount: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    showCart: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    showStorefinder: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    storefinderUrl: PropTypes.string,
  }),
  logo: PropTypes.object,
  menuData: PropTypes.array,
  searchBar: PropTypes.object,
  isSticky: PropTypes.bool,
  viewport: PropTypes.shape({
    top: PropTypes.number,
  }),
}

Mobile.defaultProps = {
  actions: {
    showLabels: [],
    showAccount: [],
    showCart: [],
    showStorefinder: [],
    storefinderUrl: [],
  },
  logo: {},
  menuData: [],
  searchBar: {},
  isSticky: false,
  viewport: {
    top: 0,
  },
}

export default Mobile
