import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Icon, Link } from '../../../../index'

function DropdownItem({
  label,
  href,
  separator,
  onClick,
  startIcon,
  endIcon,
  className,
}) {
  let Element
  let actionProps = {}
  if (onClick) {
    Element = 'button'
    actionProps.onClick = onClick
  } else {
    Element = Link
    actionProps.href = href
  }

  const liClasses = classNames('header__dropdown_item', className, {
    [`header__dropdown_separator--${separator}`]: !!separator,
  })

  return (
    <li className={liClasses}>
      <Element {...actionProps}>
        {startIcon && <Icon symbol={startIcon} />}
        <span className="flex-grow">{label}</span>
        {endIcon && <Icon symbol={endIcon} />}
      </Element>
    </li>
  )
}

DropdownItem.propTypes = {
  label: PropTypes.string,
  href: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  separator: PropTypes.oneOf(['top', 'bottom']),
  onClick: PropTypes.func,
  startIcon: PropTypes.string,
  endIcon: PropTypes.string,
  className: PropTypes.string,
}

export default DropdownItem
