import { fetchSessionlyRatings } from '../..'

export default async function fetchRatings(products) {
  const productIds = []
  products?.map((product) => {
    productIds.push(product.id)
  })
  if (productIds.length) {
    const result = await fetchSessionlyRatings(productIds)
    return result
  }
}
