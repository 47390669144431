import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Link, Text } from '../../index'

export default function Overlay({
  isVisible,
  fontColor,
  bgColor,
  position,
  heading,
  subHeading,
  isGap,
  button,
}) {
  const teaserBannerHeadingClasses = classNames(
    `d-block image-text-overlay__overlay-heading ${heading.order}`,
    {
      'mt-3':
        isGap && (heading.order === 'order-2' || heading.order === 'order-3'),
      'mt-0': !isGap,
    }
  )

  const teaserBannerSubHeadingClasses = classNames(
    `d-block image-text-overlay__overlay-sub-heading ${subHeading.order}`,
    {
      'mt-3':
        isGap &&
        (subHeading.order === 'order-2' || subHeading.order === 'order-3'),
      'mt-0': !isGap,
    }
  )

  const teaserBannerButtonClasses = classNames(
    `image-text-overlay__overlay-button ${button.order}`,
    {
      'mt-4': button.order === 'order-2' || button.order === 'order-3',
      'btn btn-primary': button.btnType === 'primary',
      'btn btn-secondary': button.btnType === 'secondary',
      [`image-text-overlay__overlay-button--link text-${fontColor}`]:
        button.btnType === 'link',
    }
  )

  if (!isVisible) return null

  return (
    <div
      className={`text-${fontColor} image-text-overlay__overlay image-text-overlay__overlay--${position} image-text-overlay__overlay--${bgColor}`}
    >
      {heading.isVisible && (
        <Text
          size={heading.fontSize}
          weight={heading.fontWeight}
          element="span"
          className={teaserBannerHeadingClasses}
          text={heading.text}
        />
      )}

      {subHeading.isVisible && (
        <Text
          size={subHeading.fontSize}
          weight={subHeading.fontWeight}
          element="span"
          className={teaserBannerSubHeadingClasses}
          text={subHeading.text}
        />
      )}

      {button.isVisible && button.link && (
        <Link href={button.link} className={teaserBannerButtonClasses}>
          {button.text}
        </Link>
      )}
    </div>
  )
}

Overlay.propTypes = {
  isVisible: PropTypes.bool,
  theme: PropTypes.string,
  position: PropTypes.string,
  heading: PropTypes.object,
  subHeading: PropTypes.object,
  isGap: PropTypes.bool,
  button: PropTypes.object,
}

Overlay.defaultProps = {
  isVisible: false,
  theme: '',
  position: '',
  heading: {},
  subHeading: {},
  isGap: false,
  button: {},
}
