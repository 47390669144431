import React from 'react'

import { useBackendApi, useTranslation } from '../../../../utils/providers'

import NewPasswordForm from './NewPasswordForm'

export default function ConfirmPasswordForm(props) {
  const { t } = useTranslation()
  const backendApi = useBackendApi()

  return (
    <NewPasswordForm
      headline={t('LOGINBOX_ACTIVATE_CUSTOMER')}
      onSubmit={backendApi.confirmRegistration}
      {...props}
    />
  )
}
