import React from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import ProductOffersCard from './ProductOffersCard'

const RowCard = ({
  trackingClass,
  hasShadow,
  hasButtonBorderRadius,
  cardBorderRadius,
  hasBorder,
  ...rest
}) => {
  const productCardClassNames = classNames(
    'product-offers__item',
    'position-relative',
    'overflow-hidden',
    'm-2',
    'w-auto',
    'align-items-center',
    'justify-content-center',
    'flex-row',
    'row',
    `border-radius-${cardBorderRadius}`,
    {
      border: hasBorder,
      shadow: hasShadow,
    }
  )
  const buttonClasses = classNames(
    'btn btn-primary w-100 product-offers__clickable',
    hasButtonBorderRadius && 'border-radius-lg',
    trackingClass
  )

  return (
    <ProductOffersCard
      productCardClassNames={productCardClassNames}
      buttonClasses={buttonClasses}
      imageClasses="p-3 pr-0"
      descriptionClasses="py-3 w-100 text-left product-offers__description"
      benefitsClasses="product-offers__product-benefits list-item-checkmark d-block d-sm-none d-md-block font-size-m mb-1"
      descriptionWrapperClasses="col-sm-6"
      imageWrapperClasses="product-offers__image-wrapper d-flex align-items-center justify-content-center col-sm-6 position-static"
      couponLabelAlignment="left"
      {...rest}
    />
  )
}

RowCard.propTypes = {
  trackingClass: PropTypes.string,
  hasShadow: PropTypes.bool,
  hasButtonBorderRadius: PropTypes.bool,
  cardBorderRadius: PropTypes.oneOf(['0', 'lg', 'sm']),
  hasBorder: PropTypes.bool,
}

RowCard.defaultProps = {
  trackingClass: '',
  hasShadow: false,
  hasButtonBorderRadius: true,
  cardBorderRadius: 'lg',
  hasBorder: false,
}

export default RowCard
