import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import markdownRegexes from './markdownRegexes'

export { markdownRegexes }

export default class MarkdownAwareSpan extends Component {
  static propTypes = {
    children: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.regexes = {
      'font-weight-semi-bold': markdownRegexes.regexBold,
      'font-italic': markdownRegexes.regexItalic,
      'text-underline': markdownRegexes.regexUnderline,
    }
  }

  render() {
    let txt = this.props.children // we assume that 'children' is a text node

    const classes = {}
    Object.entries(this.regexes).map(([clazz, regex]) => {
      if (txt.match(regex)) {
        classes[clazz] = true
        txt = txt.replace(regex, '$1')
      }
    })
    const formatClass = classNames(null, classes)

    return <span className={formatClass}>{txt}</span>
  }
}
