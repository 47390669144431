import React from 'react'
import PropTypes from 'prop-types'

const ChatchampWizard = ({ wizardId }) => (
  // additional <div /> to make react work correctly with removing of children MAG-2861
  <div>
    <div id="chatchamp-wizard" data-wizard-id={wizardId} />
  </div>
)

ChatchampWizard.propTypes = {
  wizardId: PropTypes.string.isRequired,
}

export default ChatchampWizard
