import React, { useContext } from 'react'

import { BundleContext } from '../BundleProvider'
import OptionWrapper from '../OptionWrapper'

export default function FixedOption() {
  const { options: bundleOptions } = useContext(BundleContext)

  return bundleOptions.map(({ id: optionId, title, selections }, index) => (
    <OptionWrapper title={title} key={`${index}-${optionId}`}>
      {selections.map(({ id: selectionId, name }, idx) => (
        <div
          key={`${idx}-${optionId}-${selectionId}`}
          className="fixed-selection mb-2"
        >
          {name}
          <input type="hidden" name={optionId} value={selectionId} />
        </div>
      ))}
    </OptionWrapper>
  ))
}
