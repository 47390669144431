import React from 'react'
import PropTypes from 'prop-types'

import { ConditionalLink, Image, Link } from '../../index'
import CopyCoupon from '../CopyCoupon'

const Item = ({
  card,
  cardColumnClasses,
  cardClasses,
  firstColClasses,
  secondColClasses,
}) => {
  const {
    image,
    badgeText,
    preTitle,
    title,
    subTitle,
    icon,
    couponSettings,
    btnText,
    btnLink,
  } = card

  return (
    <>
      <div className={cardColumnClasses}>
        <div className={cardClasses}>
          {badgeText && (
            <div className="copy-coupon-cards__item-badge copy-coupon-cards__item-badge--top-left font-size-sm">
              <span>{badgeText}</span>
            </div>
          )}
          <div className="row justify-content-center align-items-center">
            <div className={firstColClasses}>
              <ConditionalLink href={btnLink}>
                <Image
                  srcDesktop={image}
                  alt="card-image"
                  className="copy-coupon-cards__image"
                />
              </ConditionalLink>
            </div>
            <div className={secondColClasses}>
              {preTitle && (
                <span className="text-uppercase d-block text-gray-2">
                  {preTitle}
                </span>
              )}
              {title && (
                <span className="font-weight-semi-bold d-block font-size-l">
                  {title}
                </span>
              )}
              {subTitle && <span>{subTitle}</span>}
              {icon && <i className={`text-primary-lighten-20 ${icon}`} />}
              {couponSettings.couponCode && (
                <CopyCoupon
                  {...couponSettings}
                  labelTextSize="sm"
                  className="text-center w-100"
                />
              )}
              {btnText && btnLink && (
                <Link href={btnLink} className="btn btn-primary w-100">
                  {btnText}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Item.propTypes = {
  image: PropTypes.string,
  badgeText: PropTypes.string,
  preTitle: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  icon: PropTypes.string,
  couponSettings: PropTypes.object,
  btnText: PropTypes.string,
  btnLink: PropTypes.string,
}

Item.defaultProps = {
  image: '',
  badgeText: '',
  preTitle: '',
  title: '',
  subTitle: '',
  icon: '',
  couponSettings: {},
  btnText: '',
  btnLink: '',
}

export default Item
