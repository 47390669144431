import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function LineSpacer({ size = 'sm' }) {
  const className = classNames('line-spacer', {
    [`line-spacer--${size}`]: ['md', 'lg', 'xl'].includes(size),
  })
  return <div className={className} />
}

LineSpacer.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
}

export default LineSpacer
export { default as lineSpacerVariants } from './variants.js'
