import React from 'react'

import { ConditionalProductLink, Image, ProductBadges } from '../..'
import ProductPrices from '../PriceInfo/ProductPrices'
import ProductPriceHint from '../PriceInfo/ProductPriceHint'

import { getTierIconClass, useFeatureFlags } from '../../../utils'

function ProductInfo({ productData }) {
  const { isFeatureEnabled } = useFeatureFlags()
  const tierIconClass = getTierIconClass(productData.tiericon)

  return (
    <>
      <div className="font-size-m font-weight-semi-bold mb-3">
        <span>{productData.manufacturer_name}</span>{' '}
        <span>{productData.produkt_linie}</span>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          {isFeatureEnabled('product.badges') && (
            <ProductBadges badge={productData.badge} />
          )}
          <ConditionalProductLink
            href={productData.url}
            className="product-item__image"
          >
            <Image
              className="m-auto d-block"
              srcFull={productData.small_image}
            />
          </ConditionalProductLink>
        </div>
        <div className="col-8">
          <div className="d-block mb-1 truncate-text">
            <span>{productData.name}</span>
          </div>

          <ProductPrices
            productData={productData}
            classSpecialPrice="font-size-m"
            classRegularPrice="d-inline-block ml-2"
            classUnitPrice="font-size-xxs d-block"
            forPage="quickViewPopup"
          />

          <ProductPriceHint className="font-size-xxs" />
          {tierIconClass.map((iconClass, index) => {
            return <i key={index} className={iconClass} />
          })}
        </div>
      </div>
    </>
  )
}

export default ProductInfo
