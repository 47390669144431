import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { parseCookies, setCookie } from 'nookies'
import classNames from 'classnames'
import Carousel from 'react-multi-carousel'

import {
  toIndex,
  truncateString,
  useMediaResize,
  useTranslation,
} from '../../../../../utils'
import {
  ConditionalLink,
  CopyCoupon,
  Icon,
  InlineButton,
} from '../../../../index'

import SocialNetworks from './SocialNetworks'

const Topbar = ({
  text,
  couponCode,
  socialNetworks,
  order,
  timer,
  colorScheme,
  hasRoundedCorners,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { breakpoints } = useMediaResize()
  const { t } = useTranslation()
  const { isTopbarClosed = 'false' } = parseCookies(null)

  const isTablet = breakpoints.downLG

  const copyCouponClasses = classNames('topbar__copy mb-0', {
    'topbar__copy--rounded-corners': hasRoundedCorners,
  })
  const componentsArr = []
  const handleClose = () => {
    setIsOpen(false)

    // hide topbar for selected hours
    setCookie(null, 'isTopbarClosed', true, {
      maxAge: toIndex(timer, 4) * 60 * 60,
      path: '/',
    })
  }

  if (text.label) {
    const shortText = truncateString(text.label, 40)
    const textIndex = toIndex(order.text, 0)
    const conditionalLinkClasses = classNames(
      'd-block text-white text-truncate',
      {
        'topbar__text-link text-underline': text.link,
      }
    )

    componentsArr[textIndex] = (
      <ConditionalLink
        href={text.link}
        fallbackElement="span"
        className={conditionalLinkClasses}
        key="topbar__text"
        title={text.label}
      >
        {shortText}
      </ConditionalLink>
    )
  }
  if (couponCode) {
    const couponCodeIndex = toIndex(order.couponCode, 1)

    componentsArr[couponCodeIndex] = (
      <CopyCoupon
        couponCode={couponCode}
        copyText={t('TOPBAR_COPY_CODE')}
        copiedText={t('TOPBAR_COPIED_CODE')}
        className={copyCouponClasses}
        key="topbar__copy"
      />
    )
  }
  if (socialNetworks.length) {
    const socialNetworksIndex = toIndex(order.socialNetworks, 2)

    componentsArr[socialNetworksIndex] = (
      <SocialNetworks key="topbar__social" socialNetworks={socialNetworks} />
    )
  }

  const componentsArrFiltered = componentsArr.filter(Boolean)

  const desktopItemsClasses = classNames(
    'topbar__item',
    componentsArrFiltered.length !== 2
      ? `col-${Math.round(12 / componentsArrFiltered.length)}`
      : 'mx-2'
  )

  const topbarClasses = classNames('topbar text-white', {
    'd-none': !isOpen,
    'topbar--bg-primary': colorScheme === 'white',
    'topbar--bg-logo': colorScheme === 'primary',
  })

  useEffect(() => {
    const isClosed = isTopbarClosed === 'true'
    !isClosed && setIsOpen(true)
  }, [isTopbarClosed])

  return (
    <div className={topbarClasses}>
      <div className="container h-100">
        <div className="row align-items-center position-relative h-100 justify-content-center">
          {!isTablet
            ? componentsArrFiltered.map((item, i) => (
                <div key={`topbar_${i}`} className={desktopItemsClasses}>
                  {item}
                </div>
              ))
            : isOpen && (
                <div className="topbar__column w-100 col-12">
                  <Carousel
                    arrows={false}
                    infinite={true}
                    autoPlay={componentsArrFiltered.length > 1}
                    responsive={{
                      mobile: {
                        breakpoint: { max: 991.98, min: 0 },
                        items: 1,
                      },
                    }}
                    swipeable={false}
                    draggable={false}
                    additionalTransfrom={0}
                    centerMode={false}
                    itemClass="d-flex align-items-center justify-content-center"
                  >
                    {componentsArrFiltered}
                  </Carousel>
                </div>
              )}
        </div>
      </div>
      <InlineButton
        className="topbar__close"
        onClick={handleClose}
        title={t('TOPBAR_CLOSE')}
      >
        <Icon symbol="cf-close-light" className="font-size-m" />
      </InlineButton>
    </div>
  )
}

Topbar.propTypes = {
  text: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }),
  couponCode: PropTypes.string,
  socialNetworks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  order: PropTypes.shape({
    text: PropTypes.string,
    couponCode: PropTypes.string,
    socialNetworks: PropTypes.string,
  }),
  timer: PropTypes.number,
  colorScheme: PropTypes.oneOf(['primary', 'white']),
  hasRoundedCorners: PropTypes.bool,
}

Topbar.defaultProps = {
  text: {},
  couponCode: '',
  socialNetworks: [],
  order: {
    text: '0',
    couponCode: '1',
    socialNetworks: '2',
  },
  timer: 4,
  colorScheme: 'white',
  hasRoundedCorners: false,
}

export default Topbar
export { default as topbarVariants } from './variants.js'
