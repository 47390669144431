import { pushDataLayer } from './dataLayer'

/**
 * Fires an 'xngChangeCartItemQty' event with absolute quantities of a product before and after a quantity change in the cart.
 * This is not intended to be fired when an item has been completely removed from the cart (quantity "0")!
 *
 * @param {Object} item - The cart item object.
 * @param {number} qtyNew - The new quantity of the item in the cart.
 * @param {string} quoteIdHash - The quote id from the cart.
 */
function trackCartItemQtyChange(item, qtyNew, quoteIdHash) {
  const tagManagerDataLayerArgs = {
    dataLayer: {
      event: 'xngChangeCartItemQty',
      ecommerce: {
        quoteIdHash,
        currency_code: process.env.CURRENCY,
        product: [
          {
            sku: item.sku,
            price: item.finalPrice,
            quantity_old: item.qty,
            quantity_new: qtyNew,
          },
        ],
      },
    },
  }
  pushDataLayer(tagManagerDataLayerArgs)
}

export default trackCartItemQtyChange
