import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ErrorMessage from '../ErrorMessage'

export default function MultiCheckbox({
  className = null,
  itemWrapper = 'div',
  itemWrapperClassName = 'form-group',
  inputClassName = null,
  labelClassName = null,
  htmlId,
  options,
  onChange,
  data,
  label,
  disabled,
  hidden,
}) {
  // We want the data as an array, so we have to create a fake event target.
  const handleChange = (evt) => {
    const value = evt.target.value
    if (!data.value || !Array.isArray(data.value)) {
      data.value = [value]
    } else if (data.value.find((v) => v === value)) {
      data.value = data.value.filter((v) => v !== value)
    } else {
      data.value.push(value)
    }

    onChange({
      ...evt,
      target: {
        name: data.name,
        value: data.value,
        getAttribute: () => false,
      },
    })
  }

  const Wrapper =
    itemWrapper.toLowerCase() === 'fragment' ? Fragment : itemWrapper
  const wrapperProps = {}
  if (itemWrapper.toLowerCase() !== 'fragment') {
    wrapperProps.className = itemWrapperClassName
  }

  const containerClasses = classNames('multi-select', className)

  return (
    <fieldset
      className={containerClasses}
      id={htmlId}
      disabled={disabled}
      hidden={hidden || null}
    >
      {label && <legend className="multi-select__label">{label}</legend>}
      {options.map(({ label, value, disabled: disabledOpt }) => {
        const id = `${htmlId || data.name}_${value}`
        let checked = !!data.value?.includes?.(value)
        return (
          <Wrapper key={id} {...wrapperProps}>
            <input
              type="checkbox"
              id={id}
              name={data.name}
              value={value}
              checked={checked}
              disabled={disabledOpt || null}
              onChange={handleChange}
              className={inputClassName}
            />
            <label htmlFor={id} className={labelClassName}>
              {label}
            </label>
          </Wrapper>
        )
      })}
      <ErrorMessage message={data.error} />
    </fieldset>
  )
}

MultiCheckbox.propTypes = {
  /**
   * A CSS class added to the container element (optional).
   */
  className: PropTypes.string,

  /**
   * The element with which to wrap an option (optional, defaults to 'div').
   * When used with 'fragment' the 'itemWrapperClass' prop will have no effect.
   */
  itemWrapper: PropTypes.string,

  /**
   * A CSS class added to the item wrapper (optional, defaults to 'form-group').
   * Will have no effect when 'itemWrapper' is a 'fragment'.
   */
  itemWrapperClassName: PropTypes.string,

  /**
   * A CSS class added to each item's <input> element (optional).
   */
  inputClassName: PropTypes.string,

  /**
   * A CSS class added to each item's <label> element (optional).
   */
  labelClassName: PropTypes.string,

  /**
   * Add an 'id' attribute to the container element (optional).
   */
  htmlId: PropTypes.string,

  /**
   * Items to be displayed.
   *
   * @param {String} label - The visible label
   * @param {any} value - The value by which to identify an entry. Needs to be unique!
   * @param {boolean} selected - Should this option be pre-selected or not
   * @param {boolean} disabled - Should this option be disabled or not
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      selected: PropTypes.bool,
      disabled: PropTypes.bool,
    })
  ),

  /**
   * Callback that is called by 'Form.onChange()' when the value of the input
   * element changed. See 'data' prop, and '../Form/index.js'.
   *
   * @param {string} name - The 'name' attribute of the input element
   * @param {string} value - The changed input value
   * @param {any} error - An error message in case validation fails
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The data object which identifies the input by name, holds the initial
   * value, and an error message (can be a component).
   *
   * See also '../Form/index.js'
   */
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.any,
  }).isRequired,

  /**
   * A label text to be rendered inside a '<strong>' (optional, can be a component).
   */
  label: PropTypes.any,

  /**
   * Disable all options.
   */
  disabled: PropTypes.bool,

  /**
   * Set the 'hidden' attribute to the component wrapper (optional).
   */
  hidden: PropTypes.bool,
}
