import React from 'react'
import { RawHtml } from '../../index'

export default function ProductBadges(props) {
  const { badge = '' } = props
  return (
    <RawHtml element="div" className="product-badge-wrapper" html={badge} />
  )
}
export { default as productBadgesVariants } from './variants.js'
