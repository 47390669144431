import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { shouldOpenInNewTab, useConfiguration } from '../../../utils'
import { ConditionalLink, Image, Text } from '../..'

const Card = ({
  bgImage,
  bgImageMobile,
  bgColor,
  logo,
  headline,
  description,
  cardLink,
  openInNewTab,
  scaleOnHover,
  linkButtons,
  cardArrLength,
  isSmallRow,
  isFullWidth,
  index,
  isTablet,
  hasRoundCorners,
}) => {
  const { getImageLink } = useConfiguration()
  const bgImageLink = getImageLink({
    source: (isTablet && bgImageMobile) || bgImage,
  })
  const logoLink = getImageLink({ source: logo })

  const backgroundClasses = classNames(
    'image-grid__background',
    scaleOnHover && 'image-grid__background--scale-bg'
  )
  const cardClasses = classNames(
    'image-grid__card',
    cardArrLength === 1 && 'image-grid__card--xl',
    cardArrLength === 2 &&
      (index === 0 ? 'image-grid__card--md' : 'image-grid__card--sm'),
    (cardArrLength === 3 ||
      cardArrLength === 4 ||
      (isSmallRow && cardArrLength === 5)) &&
      'image-grid__card--sm'
  )

  // This is option for split action. If you have one card in array and there
  // are more than 3 buttons in array,there will be 3 buttons maximum. If you
  // have 2-4 cards you will have one button
  let maxButtons = linkButtons.length
  if (cardArrLength === 1 && maxButtons > 2) maxButtons = 3
  else if (cardArrLength < 5 && maxButtons > 1) maxButtons = 1

  // Description will be shown only if you have it on props and if row is
  // large, and also if there is full width and 5 or less cards in array, or box
  // width and 4 or less cards in array.
  let showDescription = false
  if (
    !isTablet &&
    description &&
    !isSmallRow &&
    ((isFullWidth && cardArrLength < 6) || (!isFullWidth && cardArrLength < 5))
  ) {
    showDescription = true
  }

  // Headline will be shown only if it is present in props and also if it is
  // full width and there is 7 or less cards in array or box width with 5 or
  // less cards in array
  let showHeadline = false
  if (
    headline &&
    ((isFullWidth && cardArrLength < 8) || (!isFullWidth && cardArrLength < 6))
  ) {
    showHeadline = true
  }

  const buttons = cardArrLength < 5 && linkButtons.slice(0, maxButtons)

  const logoClasses = classNames(
    'image-grid__logo',
    !showHeadline && !showDescription && !buttons?.length && 'mb-0'
  )
  const headlineClasses = classNames(
    'image-grid__headline',
    'w-100',
    'h4',
    !showDescription && !buttons?.length && 'mb-0'
  )
  // If there is 4 cards in array, or if there is full width and 5 cards,
  // description will be in one line
  const descriptionClasses = classNames(
    'image-grid__description',
    'font-size-sm',
    'w-100',
    !buttons?.length && 'mb-0',
    (cardArrLength === 4 || (isFullWidth && cardArrLength === 5)) &&
      'text-truncate white-space-nowrap'
  )
  const buttonClasses = classNames(
    'image-grid__buttons--item white-space-nowrap btn btn-outline-light',
    hasRoundCorners && 'border-radius-lg'
  )

  return (
    <div className="image-grid__container">
      <div
        style={
          bgImageLink
            ? { backgroundImage: `url(${bgImageLink})` }
            : { backgroundColor: `var(--${bgColor})` }
        }
        className={backgroundClasses}
      />

      {/* show card content if something is present */}
      {logo ||
      showHeadline ||
      showDescription ||
      buttons?.length ||
      cardLink ? (
        <ConditionalLink
          href={!buttons?.length ? cardLink : null}
          className={cardClasses}
          fallbackElement="div"
          {...shouldOpenInNewTab(openInNewTab)}
        >
          {logo && <Image className={logoClasses} srcFull={logoLink} />}

          {showHeadline && (
            <Text
              className={headlineClasses}
              title={headline}
              text={headline}
              color="white"
              element="strong"
            />
          )}

          {showDescription && (
            <Text
              title={description}
              text={description}
              className={descriptionClasses}
              color="white"
              wight="semi-bold"
              element="p"
            />
          )}

          {/* buttons are not shown if there is small row or it is mobile screen width or there is more then 4 cards in array */}
          {Array.isArray(buttons) && (
            <div className="image-grid__buttons">
              {buttons?.map((item, index) => (
                <ConditionalLink
                  href={item.link}
                  key={`button-link-${item.link}-${index}`}
                  className={buttonClasses}
                  {...shouldOpenInNewTab(openInNewTab)}
                >
                  {item.label}
                </ConditionalLink>
              ))}
            </div>
          )}
        </ConditionalLink>
      ) : (
        <></>
      )}
    </div>
  )
}

Card.propTypes = {
  bgImage: PropTypes.string,
  bgImageMobile: PropTypes.string,
  bgColor: PropTypes.string,
  logo: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string,
  cardLink: PropTypes.string,
  openInNewTab: PropTypes.bool,
  scaleOnHover: PropTypes.bool,
  linkButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  cardArrLength: PropTypes.number,
  isSmallRow: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  index: PropTypes.number,
  isTablet: PropTypes.bool,
  hasRoundCorners: PropTypes.bool,
}

Card.defaultProps = {
  bgImage: '',
  bgImageMobile: '',
  bgColor: 'white',
  logo: '',
  headline: '',
  description: '',
  cardLink: '',
  openInNewTab: false,
  scaleOnHover: true,
  linkButtons: [],
  cardArrLength: 0,
  isSmallRow: false,
  isFullWidth: false,
  index: 0,
  isTablet: false,
  hasRoundCorners: false,
}

export default Card
