import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function LoadingIcon({ className }) {
  const containerClassName = classNames(
    'icon-outer-circle icon-border-loading',
    className
  )
  return <div className={containerClassName} />
}

LoadingIcon.propTypes = {
  className: PropTypes.string,
}
