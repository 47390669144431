import { pushDataLayer, clearGA4Data } from './dataLayer'

/**
 * Tracks a pre-order event and sends data to the dataLayer for analytics.
 *
 * @param {string} sku - The SKU of the pre-ordered item.
 * @param {string} title - The title of the pre-ordered item.
 * @param {number} price - The price of the pre-ordered item.
 * @param {string} config_variable_a - The configuration variable A of the pre-ordered item.
 */
function trackPreorder({ sku, title, price, config_variable_a }) {
  clearGA4Data()
  pushDataLayer({
    dataLayer: {
      event: 'preorder',
      ga4: {
        currencyCode: process.env.CURRENCY,
        totalValue: price,
        items: [
          {
            item_id: sku,
            item_name: title,
            item_variant: config_variable_a,
            price,
            quantity: 1,
          },
        ],
      },
    },
  })
}

export default trackPreorder
