import React from 'react'
import classNames from 'classnames'
class Slider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      prevInvisible: true,
      nextInvisible:
        // eslint-disable-next-line react/no-string-refs
        this.refs && this.refs.offsetWidth >= this.refs.scrollWidth
          ? true
          : false,
    }
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-string-refs
    this.checkButtons(this.refs.offsetWidth, this.refs.scrollWidth)
  }

  checkButtons = (offsetWidthValue, scrollWidthValue, scrollLeft) => {
    let leftFlag = false
    let rightFlag = false

    if (offsetWidthValue == scrollWidthValue) {
      leftFlag = rightFlag = true
    } else {
      leftFlag = scrollLeft <= 0
      if (scrollLeft + offsetWidthValue >= scrollWidthValue) {
        rightFlag = true
      }
    }
    this.setState({
      prevInvisible: leftFlag,
      nextInvisible: rightFlag,
    })
  }

  render() {
    // eslint-disable-next-line react/no-string-refs
    const offsetWidthValue = this.refs.offsetWidth,
      // eslint-disable-next-line react/no-string-refs
      scrollWidthValue = this.refs.scrollWidth
    let scrollLeft
    const sliderContainerClasses = classNames({
      'justify-content-center':
        this.state.prevInvisible && this.state.nextInvisible,
      'ml-5': !this.state.prevInvisible,
      'mr-5': !this.state.nextInvisible,
    })
    return (
      <div
        className={'slider-container ' + sliderContainerClasses}
        ref={(el) => {
          // eslint-disable-next-line react/no-string-refs
          this.refs = el
        }}
      >
        <div className="slider-wrapper">{this.props.children}</div>
        <button
          className={`slider-arrow slider-arrow--left ${
            this.state.prevInvisible ? 'd-none' : ''
          }`}
          onClick={() => {
            // eslint-disable-next-line react/no-string-refs
            scrollLeft = this.refs.scrollLeft -= offsetWidthValue / 2
            this.checkButtons(offsetWidthValue, scrollWidthValue, scrollLeft)
          }}
        />
        <button
          className={`slider-arrow slider-arrow--right ${
            this.state.nextInvisible ? 'd-none' : ''
          }`}
          onClick={() => {
            // eslint-disable-next-line react/no-string-refs
            scrollLeft = this.refs.scrollLeft += offsetWidthValue / 2
            this.checkButtons(offsetWidthValue, scrollWidthValue, scrollLeft)
          }}
        />
      </div>
    )
  }
}

export default Slider
