import React from 'react'
import { PropTypes } from 'prop-types'

import { Image, Text, ProductRating } from '../..'
import ProductPrices from '../PriceInfo/ProductPrices'
import {
  ListPagePricingProvider,
  shouldOpenInNewTab,
  TranslationComponent,
} from '../../../utils'
import ProductActions from './ProductActions'
import Badge from './Badge'
import ProductLink from '../Link/ProductLink'
import CopyCoupon from '../CopyCoupon'
import ProductPriceHintWithPrice from '../PriceInfo/ProductPriceHintWithPrice'
import classNames from 'classnames'

const ProductCard = ({
  ratingSummary,
  trackingClass,
  productData,
  badgesFlag,
  manufacturerFlag,
  nameFlag,
  productSizeFlag,
  priceFlag,
  couponCodeFlag,
  buttonFlag,
  ratingFlag,
  buttonType,
  trackingIdentifier,
  hasShadow,
  openInNewTab,
  couponCode,
  badge,
}) => {
  const {
    manufacturer_name = '',
    produkt_linie = '',
    name = '',
    config_variable_a = '',
    small_image = '',
    images = [],
    url = '',
    sku = '',
  } = productData

  //productDataForTracking is used in GTM to push the product data from clicked recommendation product to Google Analytics
  const productDataForTracking = `${manufacturer_name} - ${produkt_linie} - ${name} - ${config_variable_a}`
  const productCardClassNames = classNames(
    'product-placement-extended-item',
    'border',
    'mx-sm-2',
    'my-sm-3',
    'text-center',
    'position-relative',
    hasShadow && 'shadow'
  )

  return (
    <ListPagePricingProvider productData={productData}>
      <article className={productCardClassNames}>
        <div className="product-placement-extended__card d-flex align-items-center justify-content-center flex-column position-relative overflow-hidden">
          <ProductLink
            className="product-placement-extended__link"
            href={url}
            {...shouldOpenInNewTab(openInNewTab)}
          >
            <div className="product-placement-extended__item-image-container w-100">
              {badgesFlag && <Badge badge={badge} />}

              <Image
                className="product-placement-extended-item__picture px-3 pt-2 pb-1 m-auto"
                srcFull={images[0] || small_image}
                alt={name}
              />
            </div>
          </ProductLink>

          <div className="pb-3 d-block px-3 w-100">
            {(manufacturerFlag || buttonType === 'addToCart') && (
              <Text
                weight="semi-bold"
                className="d-block w-100 text-truncate product-name font-size-m"
                element="span"
                text={`${manufacturer_name} ${produkt_linie}`}
              />
            )}

            {(nameFlag || buttonType === 'addToCart') && (
              <Text
                className="d-block w-100 text-truncate"
                element="span"
                text={name}
              />
            )}

            {(productSizeFlag || buttonType === 'addToCart') && (
              <div className="d-block w-100 text-truncate font-size-xs">
                <TranslationComponent text="LABEL_PRODUCT_CONFIG_A" />
                <strong className="qv-variant">{config_variable_a}</strong>
              </div>
            )}

            {(priceFlag || buttonType === 'addToCart') && (
              <>
                <ProductPrices
                  productData={productData}
                  classContainer="white-space-nowrap font-size-m"
                  classSpecialPrice="font-weight-semi-bold"
                  classRegularPrice="d-inline-block ml-2"
                  showBasePrice={false}
                />
                <ProductPriceHintWithPrice
                  productData={productData}
                  openInNewTab={openInNewTab}
                  className="font-size-xxs d-block mb-2 white-space-nowrap product-placement-extended__clickable"
                />
              </>
            )}

            {couponCodeFlag && (
              <CopyCoupon
                className="product-placement-extended__clickable"
                {...couponCode}
              />
            )}

            {(buttonFlag || buttonType === 'addToCart') && (
              <ProductActions
                url={url}
                productDataForTracking={productDataForTracking}
                actionClass={`btn btn-primary w-100 product-placement-extended__clickable ${trackingClass}`}
                sku={sku}
                trackingIdentifier={trackingIdentifier}
                buttonType={buttonType}
                openInNewTab={openInNewTab}
                productData={productData}
              />
            )}

            {(ratingFlag || buttonType === 'addToCart') &&
              (ratingSummary?.count > 0 ? (
                <ProductRating
                  productData={productData}
                  ratingClass="mt-2 product-placement-extended__clickable"
                  ratingSummary={ratingSummary}
                  openInNewTab={openInNewTab}
                />
              ) : (
                <div className="product-ratings mt-2" />
              ))}
          </div>
        </div>
      </article>
    </ListPagePricingProvider>
  )
}

ProductCard.propTypes = {
  ratingSummary: PropTypes.shape({
    rating: PropTypes.number,
    count: PropTypes.number,
  }),
  trackingClass: PropTypes.string,
  productData: PropTypes.shape({
    manufacturer_name: PropTypes.string,
    produkt_linie: PropTypes.string,
    name: PropTypes.string,
    config_variable_a: PropTypes.string,
    small_image: PropTypes.string,
    url: PropTypes.arrayOf(PropTypes.string),
    sku: PropTypes.string,
  }),
  badgesFlag: PropTypes.bool,
  manufacturerFlag: PropTypes.bool,
  nameFlag: PropTypes.bool,
  productSizeFlag: PropTypes.bool,
  priceFlag: PropTypes.bool,
  couponCodeFlag: PropTypes.bool,
  buttonFlag: PropTypes.bool,
  ratingFlag: PropTypes.bool,
  buttonType: PropTypes.string,
  trackingIdentifier: PropTypes.string,
  hasShadow: PropTypes.bool,
  openInNewTab: PropTypes.bool,
  couponCode: PropTypes.shape({
    couponCode: PropTypes.string,
    couponCodeLabel: PropTypes.string,
  }),
  badge: PropTypes.string,
}

ProductCard.defaultProps = {
  ratingSummary: null,
  trackingClass: '',
  productData: {},
  badgesFlag: true,
  manufacturerFlag: true,
  nameFlag: true,
  productSizeFlag: true,
  priceFlag: true,
  couponCodeFlag: false,
  buttonFlag: true,
  ratingFlag: true,
  buttonType: 'productPage',
  trackingIdentifier: '',
  hasShadow: false,
  openInNewTab: false,
  couponCode: {},
  badge: '',
}

export default ProductCard
