import React from 'react'
import PropTypes from 'prop-types'

import {
  AccountButton,
  CartButton,
  HamburgerButton,
  SearchButton,
  StoreButton,
} from '../../Actions'
import {
  AccountFlyout,
  AccountNavigationFlyout,
  NavigationFlyout,
} from '../../Menu'
import ActionContainer from '../ActionContainer'
import Logo from '../Logo'

function Mobile({ actions, logo, menuData, searchBar }) {
  const { showLabels, showAccount, showCart, showStorefinder, storefinderUrl } =
    actions

  const version = 'mobile'
  const isLabelVisible = showLabels.includes(version)
  const searchLayout = searchBar.layout
  const isAccountVisible = showAccount.includes(version)

  return (
    <>
      <Logo className="col-5" {...logo} />
      <div className="col-7 pr-0">
        <ActionContainer className="justify-content-end h-100">
          <SearchButton
            showLabel={isLabelVisible}
            searchBar={searchBar}
            showAsPopup={true}
            layout={searchLayout}
          />
          {showStorefinder.includes(version) && storefinderUrl && (
            <StoreButton showLabel={isLabelVisible} href={storefinderUrl} />
          )}
          {isAccountVisible && (
            <AccountButton showLabel={isLabelVisible} owns="account-menu" />
          )}
          <CartButton
            showLabel={isLabelVisible}
            showAsBubble={!showCart.includes(version)}
          />
          <HamburgerButton showLabel={isLabelVisible} owns="navigation-menu" />
        </ActionContainer>
      </div>

      {isAccountVisible ? (
        <>
          <AccountFlyout id="account-menu" />
          <NavigationFlyout id="navigation-menu" menuData={menuData} />
        </>
      ) : (
        <AccountNavigationFlyout id="navigation-menu" menuData={menuData} />
      )}
    </>
  )
}

Mobile.protoTypes = {
  actions: PropTypes.shape({
    showLabels: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    showAccount: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    showCart: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    showStorefinder: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    storefinderUrl: PropTypes.string,
  }),
  logo: PropTypes.object,
  menuData: PropTypes.array,
  searchBar: PropTypes.object,
}

Mobile.defaultProps = {
  actions: {
    showLabels: [],
    showAccount: [],
    showCart: [],
    showStorefinder: [],
    storefinderUrl: [],
  },
  logo: {},
  menuData: [],
  searchBar: {},
}

export default Mobile
