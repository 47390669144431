import React, { useState } from 'react'

import { useCarouselSettings } from '../../../utils'
import { Carousel, Image, Link } from '../../index'
import classNames from 'classnames'

const responsive = {
  desktop: {
    breakpoint: { max: Number.MAX_VALUE, min: 1200 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1199.98, min: 992 },
    items: 5,
  },
  mobileM: {
    breakpoint: { max: 991.98, min: 480 },
    items: 4,
  },
  mobileS: {
    breakpoint: { max: 479.98, min: 160 },
    items: 3,
  },
}

/**
 * @param {Array} params.items
 * @param {boolean} params.isFullWidth
 * @returns {React.ReactElement}
 *
 * Note: autoPlay forces full width, and will play regardless of the number
 * of items. Use with care!
 * See also: https://github.com/YIZHUANG/react-multi-carousel/issues/63
 */
export default function BrandCarousel({ items = [], isFullWidth = false }) {
  const [isMoving, setMoving] = useState(false)
  const carouselSettings = useCarouselSettings('brand-carousel')

  // Prevent following the card link while dragging the carousel
  const dragCheck = (evt) => {
    if (isMoving) {
      evt.preventDefault()
    }
  }

  const beforeChange = () => setMoving(true)

  const afterChange = () => setMoving(false)

  const containerClasses = classNames(
    'row mb-3 py-1 align-items-center flex-column justify-content-center',
    {
      'mx-0': isFullWidth,
    }
  )

  return (
    <section className={!isFullWidth ? 'container' : null}>
      <Carousel
        beforeChange={beforeChange}
        afterChange={afterChange}
        containerClass={containerClasses}
        itemClass="d-flex flex-column px-3 brand-carousel-item justify-content-center"
        responsive={responsive}
        renderButtonGroupOutside={false}
        infinite={false}
        {...carouselSettings}
      >
        {items.map((item, index) => (
          <Link
            key={`${index}_${index.category_image}`}
            className="d-block"
            href={item.url[0]}
            onClick={dragCheck}
          >
            <Image
              srcFull={item.category_image}
              alt={item.category_title}
              title={item.category_title}
              className="text-center"
            />
          </Link>
        ))}
      </Carousel>
    </section>
  )
}

export { default as brandCarouselVariants } from './variants'
