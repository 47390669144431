import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Icon, Link } from '../../../index'

function HeaderAction({
  className,
  symbol,
  label,
  href,
  showLabel,
  children,
  showAsBubble,
  owns,
  ...rest
}) {
  const btnClass = classNames('header__action', className, {
    'header__action--fixed': showAsBubble,
  })

  const Element = href ? Link : 'button'

  return (
    <Element
      className={btnClass}
      title={!showLabel || showAsBubble ? label : null}
      aria-label={label}
      href={href}
      aria-owns={owns}
      {...rest}
    >
      <Icon symbol={symbol} className="header__icon" />
      {showLabel && !showAsBubble && (
        <span className="header__label">{label}</span>
      )}
      {children}
    </Element>
  )
}

HeaderAction.propTypes = {
  className: PropTypes.string,
  symbol: PropTypes.string,
  label: PropTypes.string,
  href: PropTypes.string,
  showLabel: PropTypes.bool,
  children: PropTypes.node,
  showAsBubble: PropTypes.bool,
  owns: PropTypes.string,
}

HeaderAction.defaultProps = {
  className: null,
  symbol: null,
  label: null,
  href: null,
  showLabel: true,
  children: null,
  showAsBubble: false,
  owns: null,
}

export default HeaderAction
