import React from 'react'
import { Link } from '../..'

export default function ConditionalLink(props) {
  const {
    href = '',
    btnText,
    fallbackElement = 'div',
    children,
    ...rest
  } = props

  if (href && href != '' && !btnText) {
    return (
      <Link href={href} {...rest}>
        {children}
      </Link>
    )
  }

  if (fallbackElement.toLowerCase() == 'fragment') {
    return <>{children}</>
  }

  const Element = fallbackElement

  return <Element {...rest}>{children}</Element>
}
