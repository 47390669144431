import React from 'react'
import classNames from 'classnames'
import { shouldOpenInNewTab, useTranslation } from '../../../utils'
import { ConditionalLink } from '../..'
import scrollToElement from '../../../utils/Alphapet/scrollToElement'
import getParameterByName from '../../../utils/Alphapet/getParameterByName'

export default function ProductRating(props) {
  const {
    productData,
    ratingSummary,
    ratingUrl = true,
    ratingClass = null,
    openInNewTab = false,
  } = props
  const { t } = useTranslation()

  if (!ratingSummary?.rating) {
    return null
  }
  let url = productData.url
  if (Array.isArray(url)) {
    url = url[0]
  }
  const ratingPercent = (ratingSummary['rating'] / 5) * 100
  const ratingCount = ratingSummary['count']

  // For emails and direct link goto review tab
  if (getParameterByName({ selector: 'goto' }) === 'reviews') {
    goToReviews()
  }

  function goToReviews() {
    const reviewTab = document.getElementById('tab-ratings') // Bewertungen Tab
    scrollToElement({ selector: '.detailed-info', behavior: 'smooth' }) // general class for tab identifier
    if (reviewTab) {
      reviewTab.click()
    }
  }
  let urlWithRating = ratingUrl ? url + '?goto=reviews' : null

  const classes = classNames('product-ratings', ratingClass)

  return (
    <div className={classes}>
      <ConditionalLink
        className={urlWithRating ? null : 'cursor-pointer'}
        onClick={goToReviews}
        href={urlWithRating}
        title={t('PRODUCT_RATINGS_TITLE')}
        {...shouldOpenInNewTab(openInNewTab)}
      >
        <div className="rating">
          <div className="star-ratings mr-1 ">
            <span className="stars-fill" style={{ width: ratingPercent + '%' }}>
              <i className="cf-star"></i>
              <i className="cf-star"></i>
              <i className="cf-star"></i>
              <i className="cf-star"></i>
              <i className="cf-star"></i>
            </span>
            <span className="stars-empty">
              <i className="cf-e-star"></i>
              <i className="cf-e-star"></i>
              <i className="cf-e-star"></i>
              <i className="cf-e-star"></i>
              <i className="cf-e-star"></i>
            </span>
          </div>
          <span className="rating-count">&nbsp;({ratingCount}) </span>
        </div>
      </ConditionalLink>
    </div>
  )
}
export { default as productRatingVariants } from './variants.js'
