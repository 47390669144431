import React, { Component } from 'react'
import classNames from 'classnames'
import { TranslationComponent, useTranslation } from '../../../../utils'

export default class Password extends Component {
  state = {
    value: this.props.data?.value || '',
    hasFocus: false,
    passwordVisible: false,
    valueConfirmed: false,
  }

  onChange = (event) => {
    this.props.onChange(event)
    this.setState({
      value: event.target.value,
    })
  }

  onChangeConfirmation = (event) => {
    this.props.onChange(event)
    this.setState({
      valueConfirmed: event.target.value === this.state.value,
    })
  }

  setFocus = (focus) => {
    this.setState({
      hasFocus: focus,
    })
  }

  togglePasswordVisibility = () => {
    this.setState({
      passwordVisible: !this.state.passwordVisible,
    })
  }

  render() {
    const {
      className = null,
      classNameConfirm = null,
      classNameIndicator = null,
      readOnly = false,
      disabled = false,
      data,
      confirmData,
      label = <TranslationComponent text="FORM_LABEL_PASSWORD" />,
      showConfirm,
      showStrength,
    } = this.props
    const { value, hasFocus, passwordVisible } = this.state
    const error = data.error
    const inputType = passwordVisible ? 'text' : 'password'
    const inputClassnames = classNames('form-control password password-eye', {
      'reg-password password-d': showConfirm,
    })
    const groupClasses = classNames('md-input-group', className, {
      error: !!error,
    })
    const labelClass = classNames({
      'float-above': value || hasFocus,
    })
    const showHideClasses = classNames('show-hide-password', {
      show: passwordVisible && !error,
    })
    const showHideStyles = error || !value ? null : { display: 'inline' }

    const confirmElement = showConfirm ? (
      <PasswordConfirm
        className={classNameConfirm}
        data={confirmData}
        confirmValue={value}
        onChange={this.onChangeConfirmation}
        readOnly={readOnly ? 'readonly' : null}
        disabled={disabled}
      />
    ) : null
    const strengthElement = showStrength ? (
      <PasswordStrength value={value} className={classNameIndicator} />
    ) : null

    const errorMessage = error ? (
      <div className="error-message show">
        <span>{error}</span>
      </div>
    ) : null

    return (
      <>
        <div className={groupClasses}>
          <input
            type={inputType}
            className={inputClassnames}
            name={data.name}
            value={value || ''}
            onChange={this.onChange}
            onFocus={() => this.setFocus(true)}
            onBlur={() => this.setFocus(false)}
            readOnly={readOnly ? 'readonly' : null}
            disabled={disabled}
            spellCheck={false}
          />
          {label && (
            <div className="label-box">
              <label className={labelClass} htmlFor="password">
                {label}
              </label>
            </div>
          )}
          <span
            className={showHideClasses}
            style={showHideStyles}
            onClick={this.togglePasswordVisibility}
          ></span>
          {errorMessage}
        </div>
        {confirmElement}
        {strengthElement}
      </>
    )
  }
}

function PasswordStrength(props) {
  const { className, value } = props
  const { t } = useTranslation()
  const password = value
  if (!password) {
    return null
  }

  const specialAll = /^(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/
  let strengthText = t('FORM_LABEL_PW_WEAK')
  let strength = 'weak'

  if (password.length > 6) {
    if (password.match(specialAll)) {
      if (password.length > 10) {
        strengthText = t('FORM_LABEL_PW_VERY_STRONG')
        strength = 'very-strong'
      } else {
        strengthText = t('FORM_LABEL_PW_STRONG')
        strength = 'strong'
      }
    } else {
      strengthText = t('FORM_LABEL_PW_MEDIUM')
      strength = 'medium'
    }
  }

  const containerClass = classNames(
    'password-strength-container mb-3',
    className
  )
  const strengthClass = classNames('strength-indicator', {
    [strength]: true,
  })

  return (
    <div className={containerClass}>
      <div className="password-strength-text">{strengthText}</div>
      <div className="password-indicator">
        <div className={strengthClass}></div>
      </div>
    </div>
  )
}

class PasswordConfirm extends Component {
  state = {
    value: this.props.data?.value || '',
    error: null,
    hasFocus: false,
    passwordVisible: false,
  }

  onChange = (event) => {
    const v = event.target.value
    this.setState({
      value: v,
      error: this.getErrorMsg(v),
    })
    this.props.onChange(event)
  }

  getErrorMsg = (value) => {
    let error = null
    if (this.props.confirmValue !== value) {
      error = <TranslationComponent text="FORM_ERROR_NOT_SAME_PASSWORDS" />
    }
    return error
  }

  setFocus = (focus) => {
    this.setState({
      hasFocus: focus,
    })
  }

  togglePasswordVisibility = () => {
    this.setState({
      passwordVisible: !this.state.passwordVisible,
    })
  }

  render() {
    const { passwordVisible, value, hasFocus } = this.state
    const { className, data } = this.props
    const inputType = passwordVisible ? 'text' : 'password'
    const error = this.getErrorMsg(value)
    const errorMessage = error ? (
      <div className="error-message show">
        <span>{error}</span>
      </div>
    ) : null

    const groupClasses = classNames('md-input-group mb-3', className, {
      error: !!error,
    })
    const labelClass = classNames({
      'float-above': value || hasFocus,
    })

    const showHideClasses = classNames('show-hide-password', {
      show: passwordVisible && !error,
    })
    const showHideStyles = error || !value ? null : { display: 'inline' }

    return (
      <div className={groupClasses}>
        <input
          type={inputType}
          className="form-control confirm-password confirm-password-d"
          onChange={this.onChange}
          name={data.name}
          value={value || ''}
          onFocus={() => this.setFocus(true)}
          onBlur={() => this.setFocus(false)}
          spellCheck={false}
        />
        <div className="label-box">
          <label className={labelClass} htmlFor="password">
            <TranslationComponent text="FORM_LABEL_PASSWORD_CONFIRM" />
          </label>
        </div>
        <span
          className={showHideClasses}
          style={showHideStyles}
          onClick={this.togglePasswordVisibility}
        ></span>
        {errorMessage}
      </div>
    )
  }
}
