import React from 'react'
import { useRouter } from 'next/router'

function useCartRedirect() {
  const router = useRouter()
  return function (e) {
    e?.preventDefault()
    const externalHref = '/checkout/cart'
    const internalHref = `/frontend/static?seoUrl=` + externalHref
    router.push(internalHref, externalHref)
  }
}

function withCartRedirect(Component) {
  return function WithCartRedirect(props) {
    const cartRedirect = useCartRedirect()
    return <Component cartRedirect={cartRedirect} {...props} />
  }
}

export default useCartRedirect
export { withCartRedirect }
