import React from 'react'
import * as Yup from 'yup'

import { InlineButton } from '../../../index'
import {
  useBackendApi,
  useGlobalData,
  useTranslation,
} from '../../../../utils/providers'

import FormBox from '../FormBox'
import { LoginPopupTypes } from '../LoginPopupTypes'

export default function NewPasswordForm({ setMode, headline, onSubmit }) {
  const { t } = useTranslation()
  const { params } = useGlobalData()
  const backendApi = useBackendApi()

  const handleSubmit = async ({ body }) => {
    body.id = params.id
    body.token = params.token
    const fn = onSubmit || backendApi.newPassword
    return await fn(body)
  }

  const handleSuccess = () => {
    setMode(LoginPopupTypes.LOGIN)
  }

  const createFormData = () => ({
    password: {
      name: 'password',
      schema: Yup.string()
        .trim()
        .min(6, t('FORM_ERROR_SHORT_PASSWORD'))
        .max(20, t('FORM_ERROR_LONG_PASSWORD'))
        .required(t('FORM_ERROR_NO_PASSWORD')),
      value: '',
      error: null,
      autoFocus: true,
    },
    confirmPassword: {
      name: 'confirmPassword',
      value: '',
      error: null,
      schema: Yup.string()
        .trim()
        .min(6, t('FORM_ERROR_SHORT_PASSWORD'))
        .max(20, t('FORM_ERROR_LONG_PASSWORD'))
        .required(t('FORM_ERROR_NO_PASSWORD'))
        .when(['password'], {
          is: (value) => value && value.length > 0,
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            t('FORM_ERROR_NOT_SAME_PASSWORDS')
          ),
        }),
      depends: ['password'],
    },
  })

  const createElements = (formData) => [
    {
      type: 'headline',
      label: headline || t('LOGINBOX_PASSWORD_RESET'),
      className: 'mb-4',
    },
    {
      type: 'errorMessage',
      className: 'mb-4',
    },
    {
      type: 'password',
      showConfirm: true,
      showStrength: true,
      className: 'mb-3',
      data: formData.password,
      confirmData: formData.confirmPassword,
    },
    {
      type: 'submit',
      label: t('LOGINBOX_SUBMIT'),
      className: 'mb-4',
    },
    {
      type: 'staticText',
      label: t('LOGINBOX_BACK_TO_LOGIN')({
        Link: InlineButton,
        props: {
          className: 'text-primary',
          onClick: () => setMode(LoginPopupTypes.LOGIN),
        },
      }),
      className: 'info-box m-0 pt-3',
    },
  ]

  return (
    <FormBox
      className="new-password-box"
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      createFormData={createFormData}
      createElements={createElements}
      setMode={setMode}
    />
  )
}
