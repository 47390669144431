import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'

import { useGlobalData, useSnippet } from '../../providers'

const CustomCouponingContext = React.createContext()

/**
 * Displays a banner and changes the price property that is used in the
 * {@link InternalPriceCalculator} to calculate the visible product prices
 * when certain query parameters or cookies are set.
 *
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const CustomCouponingProvider = ({ children }) => {
  const [couponSnippet, bannerTopSnippet] = useSnippet(['coupons', 'bannertop'])
  const { allCookies, params } = useGlobalData() || {}
  const [state, setState] = useState({})

  /**
   * Check if the query parameters include a given property and if so, store it
   * in a cookie and return its value; if not, check if there already is a
   * cookie and return its value.
   *
   * @param name
   * @returns {*}
   */
  const valueFromCookieOrParam = (name) => {
    let value = params?.[name]

    // Save parameter in a cookie, so that it survives page switches
    if (value) {
      const cookies = new Cookies()
      cookies.set(name, value, { path: '/' })
    }
    // Read parameter from cookie
    else {
      value = allCookies?.[name]
    }
    return value
  }

  /**
   * Find the first element of a snippet where a property's value matches the
   * given value.
   *
   * @param {Object[]} snippet
   * @param {string} key
   * @param {string} value
   * @returns {*|null}
   */
  const findSnippetElement = (snippet, key, value) =>
    snippet?.find((component) => component.properties.content[key] === value)
      ?.properties.content || null

  useEffect(() => {
    let bannerTopLeftText,
      bannerTopRightText,
      ccBanner,
      ccPriceAttribute,
      addToCartCoupon

    // Prefer a 'coupon' banner
    const couponCode = valueFromCookieOrParam('code')
    const couponBanner = findSnippetElement(
      couponSnippet,
      'couponCode',
      couponCode
    )
    if (couponBanner) {
      ccBanner = couponBanner.banner
      ccPriceAttribute = couponBanner.priceAttribute
      addToCartCoupon = couponBanner.couponCode
    } else {
      // Fall back to the 'bannertop' banner
      const bannerCode = valueFromCookieOrParam('banner')
      const defaultBanner = findSnippetElement(
        bannerTopSnippet,
        'code',
        bannerCode
      )
      if (defaultBanner) {
        bannerTopLeftText = defaultBanner.text_left
        bannerTopRightText = defaultBanner.text_right
        addToCartCoupon = defaultBanner.couponCode
      }
    }

    setState({
      ccBanner,
      ccPriceAttribute,
      addToCartCoupon,
      bannerTopLeftText,
      bannerTopRightText,
    })
  }, [couponSnippet, bannerTopSnippet])

  return (
    <CustomCouponingContext.Provider value={state}>
      {children}
    </CustomCouponingContext.Provider>
  )
}

export { CustomCouponingContext, CustomCouponingProvider }
