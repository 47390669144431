import React from 'react'

import { useTranslation } from '../../../../../utils'

import CategoryItem from './CategoryItem'
import LinkItem from './LinkItem'
import ProductItem from './ProductItem'
import ResultSectionType from './ResultSectionType'

export default function ResultSection(props) {
  const { data, type } = props
  const { t } = useTranslation()

  let title, ItemComponent
  switch (type) {
    case ResultSectionType.CATEGORY:
      title = t('SEARCH_RESULTS_CATEGORIES')
      ItemComponent = CategoryItem
      break
    case ResultSectionType.LINK:
      title = t('SEARCH_RESULTS_LINKS')
      ItemComponent = LinkItem
      break
    case ResultSectionType.PRODUCT:
      title = t('SEARCH_RESULTS_PRODUCTS')
      ItemComponent = ProductItem
  }

  if (!title || !data?.items?.length) {
    return null
  }
  return (
    <>
      <strong className="header__search-results-label">{title}</strong>
      <ul>
        {data.items.map((item) => {
          return <ItemComponent key={item.id} itemData={item.fields} />
        })}
      </ul>
    </>
  )
}
