import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function SuccessIcon({
  className,
  animate = false,
  infinite = false,
}) {
  const containerClass = classNames(
    'icon-outer-circle icon-border-primary',
    className,
    {
      'check-animation': animate,
      infinite: infinite,
    }
  )
  return (
    <div className={containerClass}>
      <span className="icon-line line-tip bg-primary" />
      <span className="icon-line line-long bg-primary" />
    </div>
  )
}

SuccessIcon.propTypes = {
  className: PropTypes.string,
  animate: PropTypes.bool,
  infinite: PropTypes.bool,
}
