import dynamic from 'next/dynamic'

// The Storefinder uses Leaflet, a rather large dependency and, since it's
// rarely used, we want to split it from the main bundle to optimize loading
// times. We need to make sure the map only renders on the client side because
// Leaflet relies on the browser environment.
// In order keep it a single bundle and not having to deal with the dynamic
// import options elsewhere we're exporting a wrapper here.
const Storefinder = dynamic(() => import('./Storefinder'), {
  ssr: false,
  loading: () => (
    <div className="container my-5 text-center">Loading map...</div>
  ),
})

export default Storefinder
export { default as storefinderVariants } from './variants'
