import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function ActionContainer({ className, children }) {
  const containerClass = classNames(
    'header__actions-wrapper d-flex align-items-center',
    className
  )
  return <div className={containerClass}>{children}</div>
}

ActionContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.array,
}

ActionContainer.defaultProps = {
  className: null,
  children: [],
}

export default ActionContainer
