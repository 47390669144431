import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function ErrorMessage({ className, message }) {
  if (!message) {
    return null
  }
  const msg = message instanceof Error ? message.toString() : message
  const containerClass = classNames('error-message show', className)
  return (
    <div key={'' + msg} className={containerClass}>
      <span>{msg}</span>
    </div>
  )
}

ErrorMessage.propTypes = {
  className: PropTypes.string,
  message: PropTypes.any,
}
