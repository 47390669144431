import React from 'react'

export default function OptionWrapper({ title, children }) {
  return (
    <div className="option-wrap mb-3">
      <strong>{title}:</strong>
      {children}
    </div>
  )
}
