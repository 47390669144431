import React, { useState } from 'react'
import classNames from 'classnames'

import { useLazyLoadingComponent, useCarouselSettings } from '../../../utils'
import { Image, Carousel, Link } from '../../index'

/**
 * @param {Array} params.cards
 * @returns {React.ReactElement}
 *
 * Note: autoPlay forces full width, and will play regardless of the number
 * of items. Use with care!
 * See also: https://github.com/YIZHUANG/react-multi-carousel/issues/63
 */

export default function CardCarousel({ cards = [], isFullWidth = false }) {
  // Lazy-loading for react-multi-carousel:
  // Don't render the images until the container is visible
  const [isVisible, containerRef] = useLazyLoadingComponent()

  // Prevent following the card link while dragging the carousel
  const [isMoving, setMoving] = useState(false)
  const dragCheck = (evt) => {
    if (isMoving) {
      evt.preventDefault()
    }
  }

  const carouselSettings = useCarouselSettings('card-carousel')

  const responsive = {
    desktop: {
      breakpoint: { max: Number.MAX_VALUE, min: 1200 },
      items: Math.min(cards.length, 4),
      partialVisibilityGutter: cards.length > 4 ? 20 : 0,
    },
    tablet: {
      breakpoint: { max: 1199.98, min: 1024 },
      items: Math.min(cards.length, 3),
      partialVisibilityGutter: cards.length > 3 ? 30 : 0,
    },
    mobileM: {
      breakpoint: { max: 1023.98, min: 568 },
      items: Math.min(cards.length, 2),
      partialVisibilityGutter: cards.length > 2 ? 50 : 0,
    },
    mobileS: {
      breakpoint: { max: 567.98, min: 480 },
      items: Math.min(cards.length, 1),
      partialVisibilityGutter: cards.length > 1 ? 220 : 0,
    },
    mobileXS: {
      breakpoint: { max: 479.98, min: 160 },
      items: Math.min(cards.length, 1),
      partialVisibilityGutter: cards.length > 1 ? 100 : 0,
    },
  }

  const rowClasses = classNames('row mb-3 py-1', {
    'mx-0': isFullWidth,
  })

  return (
    <section className={!isFullWidth ? 'container' : null} ref={containerRef}>
      {!isVisible ? (
        <>
          {cards.map((card, index) => {
            // Render placebo element, so there is an indication of content in the markup
            if (card.link) {
              return (
                <Link key={`${index}_${card.link}`} href={card.link}>
                  {card.alt && <img alt={card.alt} />}
                  {!card.alt && card.link}
                </Link>
              )
            }
            return null
          })}
        </>
      ) : (
        <Carousel
          beforeChange={() => setMoving(true)}
          afterChange={() => setMoving(false)}
          containerClass={rowClasses}
          sliderClass="justify-content-center"
          itemClass="d-flex flex-column my-1 mb-lg-0 col-12 col-md-6 col-lg-3"
          responsive={responsive}
          partialVisible={true}
          infinite={carouselSettings.autoPlay && cards.length > 1}
          isFullWidth={isFullWidth}
          dotListClass="w-100"
          {...carouselSettings}
        >
          {cards.map((card, index) => {
            const { link, buttonText, imageDesktop, imageMobile, alt } = card
            return (
              <div
                key={`${index}_${imageDesktop}`}
                className="shadow-sm font-size-m text-center"
              >
                <Link className="d-block" href={link} onClick={dragCheck}>
                  <Image
                    srcDesktop={imageDesktop}
                    srcMobile={imageMobile}
                    alt={alt}
                    className="text-center"
                    disableLazyLoading={true}
                  />
                  <span className="d-block p-2 text-center font-weight-semi-bold">
                    {buttonText}
                  </span>
                </Link>
              </div>
            )
          })}
        </Carousel>
      )}
    </section>
  )
}

export { default as cardCarouselVariants } from './variants'
