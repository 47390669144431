/**
 * Iterates throught breakpoints object and add new breakpoints
 * to it, if 'fullwidth' option added to component
 *
 * @param {Object} currentBreakpoints - An object with breakpoints
 * @param {number} itemsLength - Number of cards which should be in carousel
 * @param {number} maxCardWidth - Width of one card in carousel
 * @param {number} addItems - How many cards visible should be added to each on higher breakpoints
 *
 * @returns {Object} A clone of itire breakpoinst object or extended cloned breakpoints object
 */
const extendCarouselBreakpoints = (
  currentBreakpoints,
  itemsLength = 0,
  maxCardWidth = 280,
  addItems = 1
) => {
  const formatedBreakpoints = { ...currentBreakpoints }
  const breakpointsEntries = Object.entries(formatedBreakpoints)

  if (itemsLength > breakpointsEntries.length) {
    const optionalBreakpointsCount = itemsLength - breakpointsEntries.length

    // iterating through breakpoints and looking for highest max width breakpoint on it
    let [
      highestBreakpointName,
      {
        items,
        breakpoint: { min },
      },
    ] = breakpointsEntries.reduce(
      (prev, current) =>
        prev[1]?.breakpoint.max > current[1]?.breakpoint.max ? prev : current,
      []
    )

    // set first new min breakpoint
    min += maxCardWidth

    // if count of items is bigger then breakpoints count, then change
    // max breakpoint into (min breakpoint + width of card)
    formatedBreakpoints[highestBreakpointName].breakpoint.max = min - 0.02

    // creating new keys with new properties in breakpoints object
    for (let i = 0; i < optionalBreakpointsCount; i++) {
      const newBreakpoint = `${highestBreakpointName}${'X'.repeat(i)}L`
      //set new current items count per breakpoint
      items += addItems
      formatedBreakpoints[newBreakpoint] = {
        breakpoint: {
          max:
            i < optionalBreakpointsCount - 1
              ? min + maxCardWidth - 0.02
              : Number.MAX_VALUE,
          min,
        },
        items,
      }
      // set new current breakpoint
      min += maxCardWidth
    }
  }

  return formatedBreakpoints
}

export default extendCarouselBreakpoints
