import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ErrorMessage from '../ErrorMessage'

export default function Checkbox(props) {
  const {
    className = null,
    onChange,
    data,
    checked = false,
    hidden = false,
    htmlId,
    label,
    ...rest
  } = props
  const [isChecked, setChecked] = useState(data.value || checked || false)

  useEffect(() => {
    // Watch changed props
    setChecked(data.value || checked)
  }, [data, checked])

  const handleChange = (evt) => {
    setChecked(evt.target?.checked)
    if (typeof onChange === 'function') {
      onChange(evt)
    }
  }

  const groupClasses = classNames('form-group', className, {
    error: !!data.error,
  })

  const id = htmlId || data.name

  return (
    <div className={groupClasses} hidden={hidden || null}>
      <input
        type="checkbox"
        id={id}
        name={data.name}
        checked={isChecked}
        onChange={handleChange}
        {...rest}
      />
      <label htmlFor={id}>{label}</label>
      <ErrorMessage message={data.error} />
    </div>
  )
}

/**
 * Additional props not covered here, will be passed to the '<input>' element.
 */
Checkbox.propTypes = {
  /**
   * A CSS class added to the container element (optional).
   */
  className: PropTypes.string,

  /**
   * The data object which identifies the input by name, holds the initial
   * value, and an error message (can be a component).
   *
   * See also '../Form/index.js'
   */
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    error: PropTypes.any,
  }).isRequired,

  /**
   * A label text to be rendered (optional, can be a component).
   */
  label: PropTypes.any,

  /**
   * Callback that is called by 'Form.onChange()' when the value of the input
   * element changed. See 'data' prop, and '../Form/index.js'.
   *
   * @param {string} name - The 'name' attribute of the input element
   * @param {boolean} value - The selected/entered date
   * @param {any} error - An error message in case validation fails
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Set the initial state to be checked (optional).
   */
  checked: PropTypes.bool,

  /**
   * Set the 'hidden' attribute to the component wrapper (optional).
   */
  hidden: PropTypes.bool,

  /**
   * Override the 'id' attribute of the form element (optional, defaults to
   * 'data.name').
   */
  htmlId: PropTypes.string,
}
