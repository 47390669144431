import React from 'react'
import PropTypes from 'prop-types'

import {
  AccountButton,
  CartButton,
  HamburgerButton,
  SearchButton,
  StoreButton,
} from '../../Actions'
import {
  AccountFlyout,
  AccountNavigationFlyout,
  NavigationFlyout,
} from '../../Menu'
import ActionContainer from '../ActionContainer'
import Logo from '../Logo'

function Tablet({ actions, logo, menuData, searchBar }) {
  const { showLabels, showAccount, showCart, showStorefinder, storefinderUrl } =
    actions

  const version = 'tablet'
  const isLabelVisible = showLabels.includes(version)
  const isAccountVisible = showAccount.includes(version)

  return (
    <>
      <ActionContainer className="col-4 justify-content-start">
        <SearchButton
          showLabel={isLabelVisible}
          searchBar={searchBar}
          showAsPopup={true}
        />
        {showStorefinder.includes(version) && storefinderUrl && (
          <StoreButton showLabel={isLabelVisible} href={storefinderUrl} />
        )}
      </ActionContainer>
      <Logo className="col-4 text-center" {...logo} />
      <ActionContainer className="col-4 justify-content-end">
        {isAccountVisible && (
          <AccountButton showLabel={isLabelVisible} owns="account-menu" />
        )}
        <CartButton
          showLabel={isLabelVisible}
          showAsBubble={!showCart.includes(version)}
        />
        <HamburgerButton showLabel={isLabelVisible} owns="navigation-menu" />
      </ActionContainer>

      {isAccountVisible ? (
        <>
          <AccountFlyout id="account-menu" />
          <NavigationFlyout id="navigation-menu" menuData={menuData} />
        </>
      ) : (
        <AccountNavigationFlyout id="navigation-menu" menuData={menuData} />
      )}
    </>
  )
}

Tablet.protoTypes = {
  actions: PropTypes.shape({
    showLabels: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    showAccount: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    showCart: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    showStorefinder: PropTypes.arrayOf(
      PropTypes.oneOf(['desktop', 'tablet', 'mobile'])
    ),
    storefinderUrl: PropTypes.string,
  }),
  logo: PropTypes.object,
  menuData: PropTypes.array,
  searchBar: PropTypes.object,
}

Tablet.defaultProps = {
  actions: {
    showLabels: [],
    showAccount: [],
    showCart: [],
    showStorefinder: [],
    storefinderUrl: [],
  },
  logo: {},
  menuData: [],
  searchBar: {},
}

export default Tablet
