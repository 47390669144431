import { useSnippet } from '../SnippetProvider'

/**
 * Get the full theme configuration, or a subset of the theme configuration, identified by an ID.
 *
 * @param {string} [id] - An optional ID of the part of the theme config to be returned
 * @returns {Object} - An object for the given ID, or an object containing all theme entries if no ID is specified. If neither a given ID nor the whole theme configuration exists, an empty object is returned
 */
const useTheme = (id) => {
  const snippet = useSnippet('theme_config') || []
  const themeSettings = snippet.reduce((acc, item) => {
    acc[item.component] = item.properties?.content
    return acc
  }, {})

  return (id ? themeSettings[id] : themeSettings) || {}
}

const useCarouselSettings = (element) => {
  const carouselSettings = useTheme('carousel-settings')
  const elementCarouselSettings = element
    ? carouselSettings?.[element]
    : carouselSettings
  return elementCarouselSettings || {}
}

const withCarouselSettings = (WrappedComponent, componentName) => {
  return function WithCarouselSettings(props) {
    const carouselSettings = useCarouselSettings(componentName)
    return <WrappedComponent carouselSettings={carouselSettings} {...props} />
  }
}

export { useTheme, useCarouselSettings, withCarouselSettings }
