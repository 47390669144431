import React, { Component, useContext } from 'react'
import classNames from 'classnames'

import { Button } from '../../../index'
import {
  PriceTypes,
  PricingContext,
  TranslationComponent,
  isFixedBundle,
  useCartActions,
  useCartContent,
  useFeatureFlags,
} from '../../../../utils'
import { trackAddToCart } from '../../../../utils/Alphapet/tracking'

class AddToCartImpl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
  }

  validateOptions = () => {
    let isValid = false
    const { productData, selectedOptions } = this.props
    const fixedBundle = isFixedBundle(productData)
    if (productData.product_type == 'bundle' && !fixedBundle) {
      let matchCount = 0
      const optionIds = Object.keys(productData.bundle_data_only.options)
      optionIds.map((id) => {
        Object.keys(selectedOptions).map((selectedId) => {
          if (id == selectedId && !!selectedOptions[id]) {
            matchCount++
          }
        })
      })
      if (matchCount === optionIds.length) {
        isValid = true
      }
      this.fireValidationEvent(isValid, productData.id)
    } else {
      isValid = true // nothing to validate for non-configurable products
    }
    return isValid
  }

  fireValidationEvent = (valid, productId) => {
    let payload
    if (valid) {
      payload = {
        status: 'ok',
        message: 'Bundle option validation succeeded.',
      }
    } else {
      payload = {
        status: 'error',
        message: 'Bundle option validation failed.',
        productId: productId,
      }
    }
    const event = new CustomEvent('addtocart:validation', {
      detail: payload,
    })
    window.dispatchEvent(event)
  }

  addToCartAction = async (e) => {
    e.preventDefault()
    const {
      cartActions,
      productData,
      trackingData,
      selectedOptions,
      qty,
      customCouponingContext,
      pricingContext,
      isFeatureEnabled,
    } = this.props
    this.setState({ isLoading: true })

    const isValid = this.validateOptions()
    try {
      if (isValid) {
        const isAboEnabled =
          isFeatureEnabled('abo') &&
          pricingContext.priceType === PriceTypes.PRICE_ABO
        const ret = await cartActions.addItem({
          productData,
          qty,
          selectedOptions,
          isAboEnabled,
          couponCode: customCouponingContext?.addToCartCoupon,
          trackingData,
        })
        if (ret.status === 'ok') {
          this.trackAddedProduct()
        }
      }
    } catch (error) {
      console.error(error)
    }
    this.setState({ isLoading: false })
  }

  trackAddedProduct = () => {
    const { productData, qty, pricingContext, cartContent, trackingData } =
      this.props
    const { items: allCartItems, totals, quoteIdHash } = cartContent
    const grandTotal = totals?.find((t) => t.code === 'grand_total')?.value
    const specialPrice = pricingContext.specialPrice

    trackAddToCart(
      productData,
      qty,
      grandTotal,
      allCartItems,
      specialPrice,
      trackingData,
      quoteIdHash
    )
  }

  render() {
    const {
      className = null,
      text = null,
      variant = null,
      showText = true,
      showIcon = false,
      iconClass = null,
    } = this.props
    const { isLoading } = this.state
    const btnClass = classNames({
      [className]: !!className,
    })
    const btnText = text ? (
      text
    ) : (
      <TranslationComponent text="PRODUCT_TILE_ADD_TO_CART" />
    )
    const variantName = variant ? variant : 'primary'
    const spinnerClass = classNames('spinner-animation', {
      'd-inline-block': showIcon,
    })
    return (
      <Button
        className={btnClass}
        variant={variantName}
        onClick={this.addToCartAction}
        disabled={isLoading}
        data-testid="btn-add-to-cart"
      >
        {showText && !isLoading && btnText}
        {showIcon && !isLoading && <i className={iconClass} />}
        {isLoading && <span className={spinnerClass}></span>}
      </Button>
    )
  }
}

export default function AddToCartButton(props) {
  const { pricingContext, customCouponingContext } =
    useContext(PricingContext) || {}
  const cartContent = useCartContent()
  const cartActions = useCartActions()
  const { isFeatureEnabled } = useFeatureFlags()
  return (
    <AddToCartImpl
      cartActions={cartActions}
      cartContent={cartContent}
      isFeatureEnabled={isFeatureEnabled}
      pricingContext={pricingContext}
      customCouponingContext={customCouponingContext}
      {...props}
    />
  )
}
