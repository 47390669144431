import React, { useEffect, useState } from 'react'
import { useMediaResize } from './index'

// Private helper method
const checkVisibility = (breakpoints, up, down) => {
  let visible = false
  if (!!up && !!down) {
    visible = breakpoints['up' + up] && breakpoints['down' + down]
  } else if (up) {
    visible = breakpoints['up' + up] === true
  } else if (down) {
    visible = breakpoints['down' + down] === true
  }
  return visible
}

export default function MediaResize(props) {
  /**
   * @param {string} up - Optional, possible values: null, 'XS', 'SM', 'MD', 'LG', 'XL'
   * @param {string} down - Optional, possible values: null, 'XS', 'SM', 'MD', 'LG', 'XL'
   */
  const { up, down } = props

  // Watch for breakpoint changes
  const { breakpoints } = useMediaResize()
  // Keep changes in a local state
  const [isVisible, setVisible] = useState(false)
  // Trigger changes after 'componentDidMount' phase
  useEffect(() => {
    setVisible(checkVisibility(breakpoints, up, down))
  })

  if (isVisible) {
    return <>{props.children}</>
  }
  return null
}
