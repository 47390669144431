/**
 * Save clicked item sku from promotion area in local storage
 * @param sku
 * @param trackingIdentifier
 * @return {Promise<void>}
 */
const promotionConversionTracking = async (sku, trackingIdentifier) => {
  if (trackingIdentifier && window.userTrackingEnabled) {
    const recommendationObject = getPromotionConversionObject() || {}
    let recommendationList = recommendationObject[trackingIdentifier] || []
    if (!recommendationList.includes(sku)) {
      recommendationList.push(sku)
      recommendationObject[trackingIdentifier] = recommendationList
      setPromotionConversionObject(recommendationObject)
    }
  }
}

function getPromotionConversionObject() {
  // If localStorage is not available (older browser),
  // or a user has deactivated cookies, there will be an exception
  try {
    const item = localStorage.getItem('promotionProductClickedList')
    return typeof item === 'string' ? JSON.parse(item) : null
  } catch (e) {
    console.error(e)
    return null
  }
}

function setPromotionConversionObject(recommendationObject) {
  // If localStorage is not available (older browser),
  // or a user has deactivated cookies, there will be an exception
  if (recommendationObject) {
    try {
      localStorage.setItem(
        'promotionProductClickedList',
        JSON.stringify(recommendationObject)
      )
    } catch (e) {
      console.error(e)
    }
  }
}

function clearPromotionConversionObject(identifier) {
  // If localStorage is not available (older browser),
  // or a user has deactivated cookies, there will be an exception
  try {
    const obj = getPromotionConversionObject()
    if (obj) {
      delete obj[identifier]
      if (!Object.keys(obj)?.length) {
        localStorage.removeItem('promotionProductClickedList')
      } else {
        setPromotionConversionObject(obj)
      }
    }
  } catch (e) {
    console.error(e)
  }
}

export default promotionConversionTracking
export {
  getPromotionConversionObject,
  setPromotionConversionObject,
  clearPromotionConversionObject,
}
