import React from 'react'

import ProductLink from '../../Alphapet/Link/ProductLink'
import {
  useFeatureFlags,
  useTranslation,
  promotionConversionTracking,
} from '../../../utils'

export default function ProductActions({
  actionClass = '',
  url,
  reviewUrl,
  productDataForTracking,
  redirectToReviewUrl,
  sku,
  trackingIdentifier,
}) {
  const { isFeatureEnabled } = useFeatureFlags()
  const { t } = useTranslation()

  const isReviewEnabled =
    isFeatureEnabled('product.review') && reviewUrl && redirectToReviewUrl

  let actionButton = (
    <ProductLink
      href={url}
      className={actionClass}
      data-product-name={productDataForTracking}
      onClick={() => promotionConversionTracking(sku, trackingIdentifier)}
    >
      {t('PRODUCT_TILE_TO_PRODUCT')}
    </ProductLink>
  )

  if (isReviewEnabled) {
    actionButton = (
      <a
        target="_blank"
        rel="noreferrer"
        href={reviewUrl}
        className={actionClass}
      >
        {t('WRITE_REVIEW')}
      </a>
    )
  }
  return <>{actionButton}</>
}
