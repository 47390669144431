import React, { useState } from 'react'
import classNames from 'classnames'

import { Icon, LanguageSelector } from '../../index'

export default function InstanceBanner() {
  const [isVisible, setVisible] = useState(true)
  const instance = process.env.MAKAIRA_API_INSTANCE
  const isStage = [
    'pp_stage',
    'ch_stage_shopify',
    'uk_stage_shopify',
    'de_stage_shopify',
  ].includes(instance)
  const isDev = process.env.NODE_ENV !== 'production'
  const isEnabled = process.env.INSTANCE_BANNER_ENABLED === 'true'

  if (!isEnabled || (!isStage && !isDev) || !isVisible) {
    return null
  }

  const className = classNames('instance_banner d-flex align-items-center', {
    'instance_banner--live': !isStage,
  })

  return (
    <div className={className}>
      <LanguageSelector />
      <span>
        ENV: <strong>{instance}</strong> - Makaira (Backend:{' '}
        <strong>{process.env.SHOP_BACKEND}</strong>)
      </span>
      <button
        className="ml-auto"
        onClick={() => setVisible(false)}
        title="Close banner"
      >
        <Icon className="font-size-xs" symbol="cf-close-light" />
      </button>
    </div>
  )
}
