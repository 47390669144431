import React, { useState } from 'react'

import { AlertBox, AlertBoxTypes, NewsletterForm } from '../..'
import { FormStatus, useGlobalVariables } from '../../../utils'
import { useTranslation } from '../../../utils/providers'

const NEWSLETTER_INTEREST_LABELS = {
  hund: 'NEWSLETTER_INTEREST_DOG',
  katze: 'NEWSLETTER_INTEREST_CAT',
}

function PopupNewsletter() {
  const [submitStatus, setSubmitStatus] = useState(FormStatus.NOT_SUBMITTED)
  const { t } = useTranslation()
  const [interests, privacyPolicy] = useGlobalVariables([
    'interestList',
    'privacyPolicyPage',
  ])
  if (!interests) {
    return null
  }
  const interestList =
    interests?.split(',').map((interest, i) => {
      const label = NEWSLETTER_INTEREST_LABELS[interest]
      return {
        label: label ? t(label) : interest,
        value: interest,
        selected: i === 0,
      }
    }) || []

  return (
    <div>
      <div>
        <NewsletterForm
          identifier="magebird"
          interestList={interestList}
          placeholder={t('NEWSLETTER_DEFAULT_PLACEHOLDER')}
          privacyPolicyUrl={privacyPolicy}
          onSubmitStatusChange={setSubmitStatus}
        />
      </div>
      {submitStatus === FormStatus.SUCCESS && (
        <AlertBox type={AlertBoxTypes.SUCCESS} className="w-100">
          {t('NEWSLETTER_SUBSCRIPTION_SUCCESS')}
        </AlertBox>
      )}
      {submitStatus === FormStatus.ERROR && (
        <AlertBox type={AlertBoxTypes.ERROR} className="w-100">
          {t('NEWSLETTER_SUBSCRIPTION_ERROR')}
        </AlertBox>
      )}
    </div>
  )
}

export default PopupNewsletter
