import React, { Component } from 'react'

import {
  TranslationComponent,
  dispatchHideOverlayEvent,
  fetchMakairaProducts,
} from '../../../utils'
import {
  ListPagePricingProvider,
  PricingContext,
  withTranslation,
} from '../../../utils/providers'
import { CenterPopup, LoadingSpinner, ProductActionButton } from '../..'
import { trackQuickview } from '../../../utils/Alphapet/tracking'
import ProductSlider, { ProductSliderItem } from '../ProductSlider'
import QtySelect from '../QtySelect'
import ProductLink from '../Link/ProductLink'
import ProductBundleOptions from '../ProductBundleOptions'
import BundleProvider from '../ProductBundleOptions/BundleProvider'

import ProductInfo from './ProductInfo'

class QuickviewPopup extends Component {
  static contextType = PricingContext

  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      qty: 1,
      groupedRelations: {},
      currentProduct: props.product,
    }
  }

  componentDidMount() {
    const { product, language } = this.props
    if (product?.groupedrelations) {
      fetchMakairaProducts(product.groupedrelations, language)
        .then((groupedrelations) => {
          const products = groupedrelations.filter((childData) => {
            return childData.active === true
          })
          this.setState({
            isLoading: false,
            groupedRelations: products,
          })
        })
        .catch((error) => {
          console.error(error)
        })
    }
    window.addEventListener('addtocart:success', this.hideQuickviewPopup)
    trackQuickview(product)
  }

  componentWillUnmount() {
    window.removeEventListener('addtocart:success', this.hideQuickviewPopup)
  }

  setCurrentProduct = (product) => {
    this.setState({
      currentProduct: product,
    })
    trackQuickview(product)
  }

  setQty = (qty) => {
    this.setState({
      qty: qty,
    })
  }

  hideQuickviewPopup = () => {
    this.props.hidePopup()
    dispatchHideOverlayEvent()
  }

  render() {
    const { currentProduct, qty, groupedRelations, isLoading } = this.state
    if (isLoading) {
      return <LoadingSpinner className="quick-view-loader" />
    }
    return (
      <ListPagePricingProvider productData={this.state.currentProduct}>
        <BundleProvider productData={this.state.currentProduct}>
          <CenterPopup
            className="quick-view-popup"
            closeAction={this.hideQuickviewPopup}
          >
            <ProductInfo productData={currentProduct} quantity={qty} />

            {this.props.product.groupedrelations.length > 0 && (
              <>
                <div className="mb-2">
                  <TranslationComponent text="LABEL_PRODUCT_CONFIG_A" />
                  <strong className="qv-variant">
                    {currentProduct.config_variable_a}
                  </strong>
                </div>

                <ProductSlider className="mb-2">
                  {Object.values(groupedRelations).map((product, index) => {
                    const isSelected = product.id == currentProduct.id
                    const isDisabled = !product.availability
                    return (
                      <ProductSliderItem
                        key={index}
                        label={product.config_variable_a}
                        isSelected={isSelected}
                        isDisabled={isDisabled}
                        onClick={() => this.setCurrentProduct(product)}
                      />
                    )
                  })}
                </ProductSlider>
              </>
            )}
            <ProductBundleOptions productData={currentProduct} />
            <span className="d-block mb-2">
              <TranslationComponent text="LABEL_QUANTITY" />
            </span>

            <QtySelect
              isDisabled={!currentProduct.availability}
              min={1}
              max={10}
              qty={qty}
              changeQty={this.setQty}
              type="inputBox"
            />

            <ProductActionButton
              className="w-100"
              qty={qty}
              productData={currentProduct}
              disableVariants={true}
              trackingData={{ origin: 'quickview' }}
            />

            <div className="text-center">
              <ProductLink
                href={currentProduct.url}
                className="font-weight-semi-bold text-uppercase text-primary"
              >
                <TranslationComponent text="MORE_DETAILS" />
              </ProductLink>
            </div>
          </CenterPopup>
        </BundleProvider>
      </ListPagePricingProvider>
    )
  }
}

export default withTranslation(QuickviewPopup)
