import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

const ProductSlider = ({ className, children }) => {
  const [isDown, setIsDown] = useState(false)
  const [startX, setStartX] = useState(null)
  const [scrollLeft, setScrollLeft] = useState(null)
  const carouselRef = useRef(null)
  const wrapperClasses = classNames('product-slider', {
    [className]: !!className,
  })

  // Source: https://stackoverflow.com/questions/28576636/mouse-click-and-drag-instead-of-horizontal-scroll-bar-to-view-full-content-of-c/66313884#66313884
  const startDragging = (e) => {
    if (!carouselRef.current) return
    setIsDown(true)
    setStartX(e.pageX - carouselRef.current.offsetLeft)
    setScrollLeft(carouselRef.current.scrollLeft)
  }

  const stopDragging = () => {
    if (!carouselRef.current) return
    setIsDown(false)
  }

  const onMove = (e) => {
    if (!isDown || !carouselRef.current) return
    e.preventDefault()
    const x = e.pageX - carouselRef.current.offsetLeft
    const walk = x - startX
    carouselRef.current.scrollLeft = scrollLeft - walk
  }

  const initialScroll = () => {
    const a = document.querySelector('.product-slider-item.active')
    const c = carouselRef.current
    const left = a?.offsetLeft - (c.clientWidth - a?.clientWidth) / 2 || 0
    c.scrollTo({ left })
  }

  useEffect(() => {
    initialScroll()
  }, [])

  return (
    // additional parent div prevents from container scrolling
    <div>
      <div
        className={wrapperClasses}
        ref={carouselRef}
        onMouseDown={startDragging}
        onMouseUp={stopDragging}
        onMouseMove={onMove}
      >
        <div className="product-slider-items">{children}</div>
      </div>
    </div>
  )
}

const ProductSliderItem = ({
  isSelected,
  isDisabled,
  value,
  onClick,
  label,
}) => {
  const containerRef = useRef(null)
  const slideClasses = classNames('product-slider-item', {
    active: isSelected,
    disabled: isDisabled,
  })
  const onClickEvent = (e) => {
    onClick(e)
    containerRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    })
  }

  return (
    <div
      data-value={value}
      className={slideClasses}
      onClick={onClickEvent}
      ref={containerRef}
    >
      <span className="variant d-block text-center">{label}</span>
    </div>
  )
}

export default ProductSlider
export { ProductSliderItem }
