import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { fetchRatings, useFeatureFlags } from '../../../utils'
import ProductPlacementExtendedContainer from './ProductPlacementExtendedContainer'

const ProductPlacementExtended = ({ products, ...props }) => {
  const [productRatings, setProductRatings] = useState({})
  const { isFeatureEnabled } = useFeatureFlags()

  useEffect(() => {
    let isMounted = true

    if (isFeatureEnabled('product.ratings')) {
      fetchRatings(products)
        .then((items) => {
          if (isMounted) setProductRatings(items)
        })
        .catch(console.error)
    }

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <ProductPlacementExtendedContainer
      products={products}
      productRatings={productRatings}
      {...props}
    />
  )
}

ProductPlacementExtended.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
}

ProductPlacementExtended.defaultProps = {
  products: [],
}

export default ProductPlacementExtended
export { default as ProductPlacementExtendedVariants } from './variants.js'
