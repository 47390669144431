import React from 'react'
import { getShopConfig, useTranslation } from '../../../utils'

export default function Copyright() {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()

  return (
    <div className="copyright">
      {t('FOOTER_COPYRIGHT')(currentYear, getShopConfig('SHOP_NAME'))}
    </div>
  )
}
