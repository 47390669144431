import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { RawHtml } from '../../'

const TextWithBg = ({
  isFullWidth,
  text,
  backgroundColor,
  borderWidth,
  textColor,
  isRoundedCorners,
  align,
}) => {
  const containerClasses = classNames(
    'p-4',
    'mb-3',
    'border-primary',
    'border-solid',
    !isFullWidth && 'container',
    `bg-${backgroundColor}`,
    `border-${borderWidth}`,
    isRoundedCorners && 'border-radius-sm',
    align && `text-${align}`
  )

  const textWithBgClassNames = classNames(
    'mb-0',
    isFullWidth && 'container',
    textColor && `text-${textColor}`
  )

  return (
    <section className={containerClasses}>
      <RawHtml element="p" className={textWithBgClassNames} html={text} />
    </section>
  )
}

TextWithBg.propTypes = {
  isFullWidth: PropTypes.bool,
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderWidth: PropTypes.string,
  textColor: PropTypes.string,
  isRoundedCorners: PropTypes.bool,
  align: PropTypes.string,
}

TextWithBg.defaultProps = {
  isFullWidth: false,
  text: '',
  backgroundColor: 'white',
  borderWidth: '0',
  textColor: 'dark',
  isRoundedCorners: false,
  align: 'center',
}

export default TextWithBg
export { default as textWithBgVariants } from './variants.js'
