import { pushDataLayer, clearGA4Data } from './dataLayer'

/**
 * Tracks a banner click event and sends data to the dataLayer.
 *
 * @param {string} banner - The identifier of the clicked banner.
 * @param {string} categoryId - The identifier of the category associated with the banner.
 */
function trackBannerClick(banner, categoryId) {
  trackBanner('promotionClick', banner, categoryId)
}

/**
 * Tracks a banner view event and sends data to the dataLayer.
 *
 * @param {string} banner - The identifier of the viewed banner.
 * @param {string} categoryId - The identifier of the category associated with the banner.
 */
function trackBannerView(banner, categoryId) {
  trackBanner('promotionImpression', banner, categoryId)
}

/**
 * Tracks a banner event and sends data to the dataLayer.
 *
 * @param {string} eventName - The name of the banner event.
 * @param {Object} banner - The banner object containing information about the banner.
 * @param {string} categoryId - The identifier of the category associated with the banner.
 */
function trackBanner(eventName, banner, categoryId) {
  if (banner) {
    const id = `Banner_${banner.id}_Kategorie_${categoryId || 'home'}`
    const tagManagerDataLayerArgs = {
      dataLayer: {
        event: eventName,
        ga4: {
          creative_name: banner.name,
          promotion_id: id,
          promotion_name: banner.title,
          items: [],
        },
        ecommerce: {
          promoView: {
            promotions: [
              {
                creative: banner.name,
                id,
                name: banner.title,
                position: banner.position,
                link: banner.link,
              },
            ],
          },
        },
      },
    }
    clearGA4Data()
    pushDataLayer(tagManagerDataLayerArgs)
  }
}

export { trackBannerClick, trackBannerView }
