import React from 'react'
import { Link } from '../../index'

/**
 * Provides a Link to a Product:
 * @param props
 * @returns {null}
 * @constructor
 */
export default function ProductLink(props) {
  const { href, ...rest } = props
  let hrefNew = href
  if (Array.isArray(hrefNew)) {
    hrefNew = hrefNew[0]
  }

  return <Link {...rest} href={hrefNew} />
}
