import { useEffect, useRef, useState } from 'react'

/**
 * Lazy-load components.
 *
 * This hook returns a visibility flag, and creates a ref that can be attached
 * to an element. The flag is set to 'true', once the element is within the
 * viewport.
 * Note: The element itself shouldn't be prevented rendering via the returned
 * flag, otherwise it will never trigger the visibility change.
 *
 * @param customOptions
 * @returns {[boolean, React.MutableRefObject<null>]}
 */
export default function useLazyLoadingComponent(customOptions = {}) {
  const defaultOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  }
  const options = {
    defaultOptions,
    ...customOptions,
  }

  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const callback = (entries, observer) => {
    const [entry] = entries
    if (entry.isIntersecting) {
      setIsVisible(true)
      // Once the element is visible, there is no need to watch anymore
      observer.unobserve(entry.target)
    }
  }

  useEffect(() => {
    const isSupported =
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    const observer = isSupported
      ? new IntersectionObserver(callback, options)
      : null
    const element = containerRef?.current
    if (element && observer) {
      observer.observe(element)
    } else if (!isSupported) {
      // Force visible for unsupported clients
      setIsVisible(true)
    }
    return () => {
      // Cleanup when unmounting
      if (isSupported && element && observer) {
        observer.unobserve(element)
      }
    }
  }, [containerRef, options])

  return [isVisible, containerRef]
}
