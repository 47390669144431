import React from 'react'
import { PropTypes } from 'prop-types'
import { RawHtml, Text } from '../../index'
import classNames from 'classnames'

function VideoComponent({ heading, text, videoURL, isFullWidth }) {
  const containerClasses = classNames('video-component mb-4', {
    container: !isFullWidth,
  })
  return (
    <section className={containerClasses}>
      {heading && <Text text={heading} element="h1" className="mb-4" />}

      {videoURL && (
        <div className="video-container">
          <iframe src={videoURL} frameBorder="0" />
        </div>
      )}

      {text && (
        <RawHtml element="div" className="text-center mt-3" html={text} />
      )}
    </section>
  )
}

VideoComponent.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  videoURL: PropTypes.string,
  isFullWidth: PropTypes.bool,
}

VideoComponent.defaultProps = {
  heading: null,
  text: null,
  videoURL: null,
  isFullWidth: false,
}

export default VideoComponent
export { default as videoComponentVariants } from './variants.js'
