function dispatchShowAccountMenu() {
  document.dispatchEvent(new CustomEvent('menu:account:show'))
}

function dispatchHideAccountMenu() {
  document.dispatchEvent(new CustomEvent('menu:account:hide'))
}

function dispatchShowNavigationMenu() {
  document.dispatchEvent(new CustomEvent('menu:navigation:show'))
}

function dispatchHideNavigationMenu() {
  document.dispatchEvent(new CustomEvent('menu:navigation:hide'))
}

export {
  dispatchShowAccountMenu,
  dispatchShowNavigationMenu,
  dispatchHideAccountMenu,
  dispatchHideNavigationMenu,
}
export { default as AccountDropdown } from './AccountDropdown'
export { default as AccountFlyout } from './AccountFlyout'
export { default as AccountNavigationFlyout } from './AccountNavigationFlyout'
export { default as NavigationFlyout } from './NavigationFlyout'
export { default as NavigationBar } from './NavigationBar'
