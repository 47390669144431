import React from 'react'
import Card from '../Card'
import classNames from 'classnames'
import ConditionalCarousel from './ConditionalCarousel'

function ColumnBasedCards({
  isFullWidth = false,
  cards = [],
  columnSize,
  btnOrder = '',
  btnType = '',
  isFullWidthBtn = true,
  align = '',
}) {
  const containerClasses = classNames('column-based-cards mb-4', {
    'react-multi-carousel-center': columnSize == '6' && cards.length < 6,
    container: !isFullWidth,
  })

  const columnClasses = classNames('d-flex flex-column', {
    ['mb-3 mb-md-0 col-12 col-md-6']: columnSize == '2',
    ['mb-3 mb-md-0 col-12 col-md-4']: columnSize == '3',
    ['mb-3 mb-lg-0 col-12 col-md-6 col-lg-3']: columnSize == '4',
    ['px-2']: columnSize == '6',
  })

  const btnClass = classNames({
    ['mt-3 order-4']: btnOrder == 'order-4',
    ['align-self-start']: !isFullWidthBtn,
    ['w-100']: isFullWidthBtn,
    [`align-self-${align}`]: align,
  })

  const alignmentClass = classNames({
    [`text-${align}`]: align,
  })

  return (
    <section className={containerClasses}>
      <ConditionalCarousel columnSize={columnSize} isFullWidth={isFullWidth}>
        {cards.map((card, key) => (
          <div key={key} className={columnClasses}>
            <Card
              buttonClass={btnClass}
              buttonVariant={btnType}
              alignmentClass={alignmentClass}
              {...card}
            />
          </div>
        ))}
      </ConditionalCarousel>
    </section>
  )
}

export default ColumnBasedCards
export { default as columnBasedCardsVariants } from './variants.js'
