import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Row from './Row'
import { useMediaResize } from '../../../utils'

const ImageGrid = ({ rows, isFullWidth, hasRoundCorners }) => {
  const { breakpoints } = useMediaResize()
  const [isTablet, setTablet] = useState(false)

  const renderCards = useMemo(() => {
    const mobileCards = []

    if (isTablet && breakpoints.upMD) {
      for (const row of rows) {
        mobileCards.push({
          ...row,
          cards: row.cards.slice(
            0,
            row.cards.length > 3 ? 3 : row.cards.length
          ),
        })
      }
    } else {
      mobileCards[0] = { cards: [] }
      for (const row of rows) {
        if (mobileCards[0].cards.length > 2) break

        for (const card of row.cards) {
          if (mobileCards[0].cards.length > 2) break
          if (card.isMobile) mobileCards[0].cards.push(card)
        }
      }
    }
    return mobileCards
  }, [breakpoints.upMD, isTablet, rows])

  useEffect(() => {
    setTablet(breakpoints.downLG)
  }, [breakpoints.downLG])

  const containerClasses = classNames('mb-4', !isFullWidth && 'container')

  if (isTablet && renderCards[0].cards.length > 0) {
    return (
      <div className={containerClasses}>
        {renderCards.map((row, i) => (
          <Row
            key={`image-grid-mobile_${i}`}
            isFullWidth={isFullWidth}
            isTablet={breakpoints.downMD}
            hasRoundCorners={hasRoundCorners}
            {...row}
          />
        ))}
      </div>
    )
  } else if (!isTablet && rows.length > 0) {
    return (
      <div className={containerClasses}>
        {rows.map((row, i) => (
          <Row
            key={`image-grid_${i}`}
            isFullWidth={isFullWidth}
            hasRoundCorners={hasRoundCorners}
            {...row}
          />
        ))}
      </div>
    )
  } else return null
}

ImageGrid.propTypes = {
  isFullWidth: PropTypes.bool,
  rows: PropTypes.array,
  hasRoundCorners: PropTypes.bool,
}

ImageGrid.defaultProps = {
  isFullWidth: false,
  rows: [],
  hasRoundCorners: false,
}

export default ImageGrid
export { default as imageGridVariants } from './variants'
