import React from 'react'
import classNames from 'classnames'
import { useTranslation } from '../../../utils'

export default function MobileView({
  subcategories = [],
  isFullWidth = false,
}) {
  const { t } = useTranslation()

  const loadSubcategory = (event) => {
    let selectedOption = event.target.value
    event.preventDefault()
    window.location = selectedOption
  }

  const containerClasses = classNames('mb-4', {
    container: !isFullWidth,
  })

  return (
    <div className={containerClasses}>
      <div className="subcategories-dropdown">
        <select
          id="track-subcat-mobile-view"
          onChange={loadSubcategory}
          suppressHydrationWarning
        >
          <option selected disabled>
            {t('SUB_CATEGORY_ALL_PRODUCTS')}
          </option>
          {subcategories.map((subcategory, key) => (
            <option key={key} value={subcategory.catLink}>
              {subcategory.catName}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
