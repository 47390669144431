import React from 'react'
import PropTypes from 'prop-types'
import Card from './Card'
import classNames from 'classnames'

const Rows = ({
  isSmallRow,
  cards,
  isFullWidth,
  isTablet,
  hasRoundCorners,
}) => {
  const rowClassName = classNames(
    'image-grid__row',
    isSmallRow ? 'image-grid__row--small' : 'image-grid__row--large',
    cards.length === 2 && 'image-grid__row--two',
    cards.length === 3 && 'image-grid__row--three'
  )

  return (
    <div className={rowClassName}>
      {cards.slice(0, Math.min(cards.length, 8)).map((item, i, arr) => (
        <Card
          key={`gird_card_${item.headline}_${i}`}
          cardArrLength={arr.length}
          isSmallRow={isSmallRow}
          isFullWidth={isFullWidth}
          index={i}
          isTablet={isTablet}
          hasRoundCorners={hasRoundCorners}
          {...item}
        />
      ))}
    </div>
  )
}

Rows.propTypes = {
  isSmallRow: PropTypes.bool,
  cards: PropTypes.array,
  isFullWidth: PropTypes.bool,
  isTablet: PropTypes.bool,
  hasRoundCorners: PropTypes.bool,
}

Rows.defaultProps = {
  isSmallRow: false,
  cards: [],
  isFullWidth: false,
  isTablet: false,
  hasRoundCorners: false,
}

export default Rows
