import React from 'react'
import ProductLink from './ProductLink'

export default function ConditionalProductLink(props) {
  const { href = '', fallbackElement = 'div', children, ...rest } = props

  if (href) {
    return (
      <ProductLink href={href} {...rest}>
        {children}
      </ProductLink>
    )
  }

  if (fallbackElement.toLowerCase() == 'fragment') {
    return <>{children}</>
  }

  const Element = fallbackElement

  return <Element {...rest}>{children}</Element>
}
