export default {
  /**
   * Form is not yet submitted.
   */
  NOT_SUBMITTED: 'not_submitted',
  /**
   * Form is being submitted.
   */
  PENDING: 'pending',
  /**
   * Form submission failed.
   */
  ERROR: 'error',
  /**
   * Form submission succeeded.
   */
  SUCCESS: 'success',
}
