import React from 'react'
import { ConditionalLink } from '../../index'
import Image from '../Image'
import Title from './Title'
import Overlay from './Overlay'
import classNames from 'classnames'

function TeaserHero(props) {
  const {
    link = '',
    isFullWidth = false,
    image = {},
    theme = '',
    heading = {},
    overlay = {},
  } = props

  const imageSource = image.src ? image.src : ''
  const containerClasses = classNames(`hero-teaser hero-teaser--${theme}`, {
    container: !isFullWidth,
  })
  return (
    <section className={containerClasses}>
      <ConditionalLink href={link} className="hero-teaser__container">
        <div className="hero-teaser__image-wrapper">
          <Image
            srcDesktop={imageSource}
            alt={image.alt}
            className="hero-teaser__image"
          />
          <Title {...heading} />
        </div>

        <Overlay {...overlay} />
      </ConditionalLink>
    </section>
  )
}

export default TeaserHero
export { default as teaserHeroVariants } from './variants.js'
