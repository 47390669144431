import React, { forwardRef, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router'

import { getFullUrl, getNextLink, sanitizeHtml } from '../../../utils'
import { useTranslation } from '../../../utils/providers'

const RawHtml = forwardRef(
  ({ element, forceStrictMode, overrideOptions, html, ...rest }, ref) => {
    const { language } = useTranslation() || {}
    const elementRef = useRef(null)
    const anyRef = ref || elementRef // Prefer given ref, fallback to own ref

    const elementProps = {
      ...rest,
      ref: ref || elementRef,
      // Override potential 'dangerouslySetInnerHTML' passed in props
      dangerouslySetInnerHTML: {
        __html: sanitizeHtml(html, forceStrictMode, overrideOptions),
      },
    }
    const Element = element

    // Replace anchor tag's default behavior to provide smooth page transitions for internal links
    useEffect(() => {
      const handleClick = async (evt) => {
        let href = evt.target?.getAttribute?.('href')
        if (href) {
          if (href.startsWith('#')) {
            href = `${window.location}${href}`
          }
          const { isExternalLink, fullUrl } = getFullUrl(href)
          if (!isExternalLink) {
            evt.preventDefault()
            const { internalUrl, externalUrl } = getNextLink(fullUrl, language)
            await Router.push(internalUrl, externalUrl)
          }
        }
      }
      const container = anyRef.current
      container?.querySelectorAll('a').forEach((element) => {
        element.addEventListener('click', handleClick)
      })
      return () => {
        container?.querySelectorAll('a').forEach((element) => {
          element.removeEventListener('click', handleClick)
        })
      }
    }, [])

    return <Element ref={anyRef} {...elementProps} />
  }
)

RawHtml.displayName = 'RawHtml'

RawHtml.propTypes = {
  element: PropTypes.string,
  forceStrictMode: PropTypes.bool,
  overrideOptions: PropTypes.object,
  html: PropTypes.string,
}

RawHtml.defaultProps = {
  element: 'span',
  forceStrictMode: false,
  overrideOptions: null,
  html: '',
}

export default RawHtml
