import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from '../../../../utils'

import { useIcons } from '../IconProvider'
import HeaderAction from './HeaderAction'

function StoreButton({ href, showLabel }) {
  const { t } = useTranslation()
  const getIcon = useIcons()

  return (
    <HeaderAction
      symbol={getIcon('actionStorefinder')}
      label={t('HEADER_STOREFINDER')}
      showLabel={showLabel}
      href={href}
    />
  )
}

StoreButton.propTypes = {
  href: PropTypes.string,
  showLabel: PropTypes.bool,
}

StoreButton.defaultProps = {
  href: null,
  showLabel: true,
}

export default StoreButton
