import React from 'react'

import { useTranslation } from '../../../../utils'

import { FlyoutItem, FlyoutMenu, FlyoutSection } from './FlyoutMenu'
import PortalWrapper from './PortalWrapper'
import { normalizeMenuData, useMenuListener } from './utils'

function NavigationFlyout({ id, menuData }) {
  const { language, t } = useTranslation()
  const [isVisible, setVisible] = useMenuListener('navigation')

  const primary =
    normalizeMenuData(
      menuData.find((m) => m.text[language] === 'PRIMARY_NAVIGATION') || {},
      language
    ) || []
  const secondary =
    normalizeMenuData(
      menuData.find((m) => m.text[language] === 'SECONDARY_NAVIGATION') || {},
      language
    ) || []

  return (
    <PortalWrapper query="#modal-root">
      <FlyoutMenu id={id} isVisible={isVisible} setVisible={setVisible}>
        <FlyoutSection label={t('HEADER_NAVIGATION')}>
          {primary?.map((item, index) => (
            <FlyoutItem key={`primary-${index}`} {...item} />
          ))}
          {secondary?.map((item, index) => (
            <FlyoutItem key={`secondary-${index}`} {...item} />
          ))}
        </FlyoutSection>
      </FlyoutMenu>
    </PortalWrapper>
  )
}

export default NavigationFlyout
