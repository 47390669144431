import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { ConditionalLink, Image, Carousel } from '../../index'
import { useCarouselSettings, useLazyLoadingComponent } from '../../../utils'
import {
  trackBannerView,
  trackBannerClick,
} from '../../../utils/Alphapet/tracking'

export default function ProductListBanner({
  banners,
  categoryId,
  isFullWidth = true,
}) {
  const currentDate = new Date()

  const getDate = (obj = {}) => {
    const { year, month, day } = obj
    // Only allow full-dates
    if (year && month && day) {
      return new Date(year, month - 1, day, 0, 0, 0)
    }
    return null
  }

  // Filter out time-managed banners that don't fit the time-frame
  banners = banners.filter((banner) => {
    const { enableTimeManagement, activeStartDate, activeEndDate } = banner
    if (enableTimeManagement === true) {
      const dateStart = getDate(activeStartDate)
      const dateEnd = getDate(activeEndDate)
      const isAfterStart =
        dateEnd === null && dateStart !== null && dateStart <= currentDate
      const isBeforeEnd =
        dateStart === null && dateEnd !== null && dateEnd >= currentDate
      const isWithin =
        dateStart !== null &&
        dateEnd !== null &&
        dateStart <= currentDate &&
        dateEnd >= currentDate
      if (isWithin || isAfterStart || isBeforeEnd) {
        return banner
      }
    } else {
      return banner
    }
  })

  if (!banners?.length) {
    return null
  }

  return (
    <BannerCarousel
      key={categoryId}
      isFullWidth={isFullWidth}
      banners={banners}
      categoryId={categoryId}
    />
  )
}

function BannerCarousel({ isFullWidth, banners, categoryId }) {
  const carouselSettings = useCarouselSettings('product-list-banner')
  const [isVisible, containerRef] = useLazyLoadingComponent({
    threshold: 0.8,
  })
  const [trackedBanners, setTrackedBanners] = useState([])

  useEffect(() => {
    if (isVisible && !trackedBanners.includes(0)) {
      trackBannerView(banners[0], categoryId)
      trackedBanners.push(0)
      setTrackedBanners(trackedBanners)
    }
  }, [isVisible])

  const responsive = {
    all: {
      breakpoint: { max: Number.MAX_VALUE, min: 160 },
      items: 1,
    },
  }

  const afterChange = (prevSlide, { currentSlide }) => {
    if (isVisible) {
      // In react-multi-carousel's "infinite" mode the first two items are cloned to the end
      // and the last two items are cloned to the beginning of the slide list:
      // -> banners       [0, 1, 2, 3, 4, 5]
      // -> slides  [4, 5, 0, 1, 2, 3, 4, 5, 0, 1]
      // To get the original banner index we have to take that offset into account.
      const bannerIndex =
        currentSlide < 2 ? banners.length - currentSlide : currentSlide - 2

      if (!trackedBanners.includes(bannerIndex)) {
        trackBannerView(banners[bannerIndex], categoryId)
        trackedBanners.push(bannerIndex)
        setTrackedBanners(trackedBanners)
      }
    }
  }

  const containerClasses = classNames('product-list-banner', {
    container: !isFullWidth,
  })

  return (
    <div className={containerClasses} ref={containerRef}>
      <Carousel
        responsive={responsive}
        draggable={false}
        renderDotsOutside={true}
        infinite={banners.length > 1}
        afterChange={afterChange}
        isFullWidth={isFullWidth}
        {...carouselSettings}
      >
        {banners.map((banner, index) => {
          const {
            imageDesktop,
            imageMobile,
            title,
            description = 'Banner image',
            link,
          } = banner
          return (
            <ConditionalLink
              key={index}
              title={title}
              href={link}
              onClick={() => trackBannerClick(banner, categoryId)}
              className="banner-click-track"
            >
              <Image
                title={title}
                alt={description}
                srcDesktop={imageDesktop}
                srcMobile={imageMobile}
                disableLazyLoading={true}
              />
            </ConditionalLink>
          )
        })}
      </Carousel>
    </div>
  )
}

export { default as productListBannerVariants } from './variants.js'
