import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import FirstLevelItem from './FirstLevelItem'

function NavBar({ className, label, primaryItems, secondaryItems }) {
  const containerClasses = classNames('header__navbar', className)

  return (
    <nav className={containerClasses} aria-label={label}>
      <div className="container d-flex px-0 position-relative">
        <ul className="justify-self-start d-flex">
          {primaryItems?.map((item, index) => (
            <FirstLevelItem key={'primary-' + index} {...item} />
          ))}
        </ul>
        <ul className="justify-self-end ml-auto d-flex flex-row-reverse">
          {secondaryItems?.map((item, index) => (
            <FirstLevelItem key={'secondary-' + index} {...item} />
          ))}
        </ul>
      </div>
    </nav>
  )
}

NavBar.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  primaryItems: PropTypes.array,
  secondaryItems: PropTypes.array,
}

NavBar.defaultProps = {
  className: null,
  label: null,
  primaryItems: [],
  secondaryItems: [],
}

export default NavBar
